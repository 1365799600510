import React, { useEffect, useState } from "react";
import DBtn from "../components/DBtn";
import UploadBtn from "../svg/UploadBtn";
import { uploadSingleImage } from "../services/commonService";
import { verificationImages } from "../services/authService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ShowCookies from "../components/ShowCookies";
import Loader from "../components/Loader";

const Upload = () => {
  const navigate = useNavigate();
  const lang = localStorage.getItem("i18nextLng");

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    userId: localStorage.getItem("userIdVerify"),
    frontSideImage: "",
    backSideImage: "",
  });
  const [errors, setErrors] = useState({});
  const [errorsEs, setErrorsEs] = useState({});
  useEffect(() => {
    const userIdVerify = localStorage.getItem("userIdVerify");
    if (!userIdVerify) {
      navigate("/");
    }
  }, [navigate]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const newErrorsEs = {};

    if (!userData?.frontSideImage) {
      newErrors.frontSideImage = "FrontSide Image is required";
      newErrorsEs.frontSideImage = "Se requiere imagen frontal";
      isValid = false;
    }
    if (!userData?.backSideImage) {
      newErrors.backSideImage = "BackSide Image is required";
      newErrorsEs.backSideImage = "Se requiere imagen trasera";
      isValid = false;
    }
    setErrorsEs(newErrorsEs);
    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = async (e) => {
    const { name, value, files } = e.target;
    if (files?.length > 0) {
      const image = files[files.length - 1];

      const imageData = new FormData();
      imageData.append("image", image);
      try {
        const resp = await uploadSingleImage(imageData);

        if (resp?.success) {
          setUserData({
            ...userData,
            [name]: resp?.data,
          });
        } else {
          setUserData({
            ...userData,
            [name]: "",
          });
        }
      } catch (err) {
        console.log("err", err);
      } finally {
      }
    } else if (value) {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem("userIdVerify");
    if (validateForm()) {
      setLoading(true);
      let payload = {
        userId: userData?.userId,
        frontSideImage: userData?.frontSideImage,
        backSideImage: userData?.backSideImage,
      };
      try {
        const resp = await verificationImages(payload);

        if (resp?.success) {
          toast.success(resp?.message, { autoClose: 4000 });
          console.log(resp?.message);
          navigate("/");
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container mx-auto p-4 flex justify-center items-center h-screen ">
      <ShowCookies />
      <div className="border-[1px] border-primary w-[520px] text-center pt-[40px] rounded-md">
        <div className="font-Comfortaa font-bold text-2xl">
          {lang === "en" || lang === "en-US" ? (
            <span>ID verification</span>
          ) : (
            <span>Verificación de identidad</span>
          )}
        </div>
        <div className="pt-[40px] font-Glacial text-lg text-[#474747]">
          {lang === "en" || lang === "en-US" ? (
            <span>
              Upload frontside <sup className="text-red-300 text-xl">*</sup>
            </span>
          ) : (
            <span>
              Subir anverso<sup className="text-red-300 text-xl">*</sup>
            </span>
          )}
        </div>
        {userData?.frontSideImage ? (
          <div className="border-[1px] border-dashed border-primary w-[396px] h-[200px] mx-auto mt-[24px] cursor-pointer">
            <div className="flex items-center justify-center ">
              <div className="text-center">
                <div className="flex justify-start items-center gap-2 mt-2">
                  <img
                    className="rounded-lg w-[300px] border border-gray-300 p-4 h-[150px] object-contain"
                    src={userData?.frontSideImage}
                    alt="profile"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="border-[1px] border-dashed border-primary w-[396px] h-[200px] mx-auto mt-[24px] cursor-pointer"
            onClick={() => document.getElementById("files").click()}
          >
            <div className="flex items-center justify-center ">
              <div className="text-center">
                <div className="my-16  flex-col leading-normal text-[#ABABAB] font-Glacial flex items-center justify-center  sm:text-[20px] text-[12px] ">
                  <UploadBtn />
                  {lang === "en" || lang === "en-US" ? (
                    <label for="files">Upload frontside image</label>
                  ) : (
                    <label for="files">Subir anverso</label>
                  )}
                  <input
                    name="frontSideImage"
                    type="file"
                    id="files"
                    className="hidden border-0 focus:ring-0 "
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {lang === "en" || lang === "en-US" ? (
          <>
            {errors.frontSideImage && userData?.frontSideImage === "" && (
              <div className="text-red-300 ml-10 font-Glacial text-lg">
                {errors.frontSideImage}
              </div>
            )}
          </>
        ) : (
          <>
            {errorsEs.frontSideImage && userData?.frontSideImage === "" && (
              <div className="text-red-300 ml-10 font-Glacial text-lg">
                {errorsEs.frontSideImage}
              </div>
            )}
          </>
        )}

        <div className="pt-[40px] font-Glacial text-lg text-[#474747]">
          {lang === "en" || lang === "en-US" ? (
            <span>
              Upload Backside <sup className="text-red-300 text-xl">*</sup>
            </span>
          ) : (
            <span>
              Subir parte trasera <sup className="text-red-300 text-xl">*</sup>
            </span>
          )}
        </div>
        {userData?.backSideImage ? (
          <div className="border-[1px] border-dashed border-primary w-[396px] h-[200px] mx-auto mt-[24px] ">
            <div className="flex items-center justify-center ">
              <div className="text-center">
                <div className="flex justify-start items-center gap-2 mt-2">
                  <img
                    className="rounded-lg w-[300px] border border-gray-300 p-4 h-[150px] object-contain"
                    src={userData?.backSideImage}
                    alt="profile"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="border-[1px] border-dashed border-primary w-[396px] h-[200px] mx-auto mt-[24px] cursor-pointer "
            onClick={() => document.getElementById("filesBack").click()}
          >
            <div className="flex items-center justify-center ">
              <div className="text-center">
                <div className="my-16 flex flex-col items-center justify-center leading-normal text-[#ABABAB] font-Glacial  sm:text-[20px] text-[12px] ">
                  <UploadBtn />
                  {lang === "en" || lang === "en-US" ? (
                    <label htmlFor="filesBack">Upload backside image</label>
                  ) : (
                    <label htmlFor="filesBack">Subir parte trasera</label>
                  )}

                  <input
                    name="backSideImage"
                    type="file"
                    id="filesBack"
                    className="hidden border-0 focus:ring-0 "
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {lang === "en" || lang === "en-US" ? (
          <>
            {errors.backSideImage && userData?.backSideImage === "" && (
              <div className="text-red-300 ml-10 font-Glacial text-lg">
                {errors.backSideImage}
              </div>
            )}
          </>
        ) : (
          <>
            {errorsEs.backSideImage && userData?.backSideImage === "" && (
              <div className="text-red-300 ml-10 font-Glacial text-lg">
                {errorsEs.backSideImage}
              </div>
            )}
          </>
        )}

        <div className="pt-[30px] pb-[40px]">
          {loading ? (
            <>
              <div className=" border-primary bg-white text-[#FFF]  border-[3px] text-xs rounded-full w-[150px] py-2  flex mx-auto items-center justify-center ">
                <Loader size={38} />
              </div>
            </>
          ) : (
            <DBtn
              variant="contain"
              size="medium"
              onClick={handleSubmit}
              startIcon=""
            >
              {lang === "en" || lang === "en-US" ? <>Verify</> : <>Verificar</>}
            </DBtn>
          )}
        </div>
      </div>
    </div>
  );
};

export default Upload;
