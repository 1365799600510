import React from "react";

const DBtn = (props) => {
  const { children, variant, size, onClick, startIcon, endIcon } = props;
  const getVariantClasses = () => {
    switch (variant) {
      case "contain":
        return "bg-[#FF914D] text-[#FFF] border-[3px] border-[#FF914D]";
      case "outline":
        return "bg-[#fff] text-[#FF914D] border-[1px] border-[#FF914D]";
      case "icon":
        return "bg-[#FFF] text-[#FF914D] shadow-md shadow-[#E6E6E6E5]";
      default:
        return "bg-[#FFF] text-[#FF914D] shadow-md shadow-[#E6E6E6E5]";
    }
  };

  const getSizeClasses = () => {
    switch (size) {
      case "small":
        return "px-[19px] py-[10px] text-xs rounded-full";
      case "medium":
        return "px-[26px] py-[10px] text-lg rounded-full";
      case "large":
        return "px-[24px] py-[18px] text-md rounded-full";
      case "icon":
        return "px-[14px] py-[14px] rounded-full";
      case "xlarge":
        return "px-[139px] py-[18px] rounded-full";
      case "xxlarge":
        return "px-[238px] py-[18px] rounded-full";
      case "paybtn":
        return "px-[250px] py-[10px] rounded-full";
      default:
        return "px-[40px] py-[18px] text-base rounded-full";
    }
  };

  const variantClasses = getVariantClasses();
  const sizeClasses = getSizeClasses();

  return (
    <button
      onClick={onClick}
      className={`font-Comfortaa rounded ${sizeClasses} ${variantClasses}`}
      type="button"
    >
      <div className="flex justify-between gap-2 items-center">
        {startIcon && <span>{startIcon}</span>}
        {children}
        {endIcon && <span>{endIcon}</span>}
      </div>
    </button>
  );
};

export default DBtn;
