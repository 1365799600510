import React from "react";

const FourSqure = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        className="mt-[0]"
      >
        <path
          d="M3.4 7.4H5C6.6 7.4 7.4 6.6 7.4 5V3.4C7.4 1.8 6.6 1 5 1H3.4C1.8 1 1 1.8 1 3.4V5C1 6.6 1.8 7.4 3.4 7.4Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.0001 7.4H14.6001C16.2001 7.4 17.0001 6.6 17.0001 5V3.4C17.0001 1.8 16.2001 1 14.6001 1H13.0001C11.4001 1 10.6001 1.8 10.6001 3.4V5C10.6001 6.6 11.4001 7.4 13.0001 7.4Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.0001 16.9996H14.6001C16.2001 16.9996 17.0001 16.1996 17.0001 14.5996V12.9996C17.0001 11.3996 16.2001 10.5996 14.6001 10.5996H13.0001C11.4001 10.5996 10.6001 11.3996 10.6001 12.9996V14.5996C10.6001 16.1996 11.4001 16.9996 13.0001 16.9996Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.4 16.9996H5C6.6 16.9996 7.4 16.1996 7.4 14.5996V12.9996C7.4 11.3996 6.6 10.5996 5 10.5996H3.4C1.8 10.5996 1 11.3996 1 12.9996V14.5996C1 16.1996 1.8 16.9996 3.4 16.9996Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default FourSqure;
