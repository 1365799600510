import "./App.css";
import "./datePicker.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import ProfilePage from "./pages/ProfilePage";
import ShowReviews from "./pages/ShowReviews";
import ChatBox from "./pages/ChatBox";
import ConfirmPayPage from "./pages/ConfirmPayPage";
import FavoriteList from "./pages/FavoriteList";
import MyBookings from "./pages/MyBookings";
import BankDetails from "./pages/BankDetails";
import AvailabilityList from "./pages/AvailabilityList";
import Listing from "./pages/Listing";
import AvailableCalendar from "./pages/AvailableCalendar";
import HostProfilePage from "./pages/HostProfilePage";
import DishFormUpdated from "./pages/DishFormUpdated";
import Otp from "./pages/Otp";
import Verification from "./pages/Verification";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import VerifyForgotOtp from "./pages/VerifyForgotOtp";
import { useEffect, useState } from "react";
import Upload from "./pages/Upload";
import DishdetailPage from "./pages/DishdetailPage";
import AvailableList from "./pages/AvailableList";
import ProfileEditPage from "./pages/ProfileEditPage";
import ContactPage from "./pages/ContactPage";
import DishFormEdit from "./pages/DishFormEdit";
import CookiesPolicy from "./pages/CookiesPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LegalNotice from "./pages/LegalNotice";
import TermsConditionGuest from "./pages/TermsConditionGuest";
import TermsConditionHost from "./pages/TermsConditionHost";
import ChatBoxUpdated from "./pages/ChatBoxUpdated";
import Affidavit from "./components/Affidavit";
import ChatBoxUpdates from "./pages/ChatBoxUpdates";
// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken } from "firebase/messaging";

// import CookieModal from "./components/CookieModal";
// import ProfileEdit from "./pages/ProfileEditPage";
// import EditDish from "./pages/EditDish";
// import ContactUs from "./pages/ContactUs";
// import DishForm from "./pages/DishForm";
// import ShowCookies from "./components/ShowCookies";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// };

// const app = initializeApp(firebaseConfig);
function App() {
  // const [deviceToken, setDeviceToken] = useState();

  // const applicationServerKey = process.env.REACT_APP_VAPID_KEY;
  // // console.log("applicationServerKey", applicationServerKey);

  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     console.log("Notification permission granted.");
  //     navigator.serviceWorker
  //       .register("/firebase-messaging-sw.js")
  //       .then(async (registration) => {
  //         const existingSubscription =
  //           await registration.pushManager.getSubscription();
  //         if (existingSubscription) {
  //           // console.log("Existing subscription found, unsubscribing...", existingSubscription);
  //           await existingSubscription.unsubscribe();
  //         }
  //         return registration.pushManager.subscribe({
  //           userVisibleOnly: true,
  //           applicationServerKey: applicationServerKey,
  //         });
  //       })
  //       .then((subscription) => {
  //         console.log(
  //           "Ready to push notifications:",
  //           subscription
  //         );
  //         // setDeviceToken(subscription.endpoint);
  //       })
  //       .catch((error) => {
  //         console.log("Error subscribing to push notification:", error);
  //       });

  //     const messaging = await getMessaging(app);
  //     const token = await getToken(messaging, {
  //       vapidKey: applicationServerKey,
  //     });
  //     // console.log("token", token);
  //     setDeviceToken(token);
  //     localStorage.setItem("deviceToken", token);
  //   } else {
  //     console.log("Unable to get permission to notify.");
  //   }
  // }

  // useEffect(() => {
  //   requestPermission();
  // }, []);
  // {
  //   deviceToken && console.log("Device Token is set succesfully:", deviceToken);
  // }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
    // eslint-disable-next-line
  }, []);

  function success(position) {
    const lati = position.coords.latitude;
    const long = position.coords.longitude;

    localStorage.setItem(
      "current_location",
      JSON.stringify({ lati: lati, long: long })
    );
  }

  const PrivateRoutes = () => {
    const user = localStorage.getItem("root");
    const authData = JSON.parse(user).auth;
    const token = JSON.parse(authData).token;

    let auth = { token: token };
    return auth.token ? <Outlet /> : <Navigate to="/" />;
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/edit-profile" element={<ProfileEditPage />} />
              <Route path="/chat" element={<ChatBoxUpdates />} />
              <Route path="/favorites" element={<FavoriteList />} />
              <Route path="/my-bookings" element={<MyBookings />} />
              <Route path="/bank-details" element={<BankDetails />} />
              <Route path="/host-profile" element={<HostProfilePage />} />
              <Route path="/mybookings" element={<MyBookings />} />
              <Route path="/listing" element={<Listing />} />
              <Route path="/edit-dish/:id" element={<DishFormEdit />} />
              {/* <Route path="/add-dish1" element={<DishFormUpdated />} /> */}
              <Route path="/add-dish" element={<DishFormUpdated />} />
              <Route path="/confirm-pay/:id" element={<ConfirmPayPage />} />
              <Route path="/availabel-list" element={<AvailableList />} />
            </Route>
            <Route path="/reviews" element={<ShowReviews />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
            <Route
              path="/terms-condition-guest"
              element={<TermsConditionGuest />}
            />
            <Route path="/affidavit" element={<Affidavit />} />
            <Route
              path="/terms-condition-host"
              element={<TermsConditionHost />}
            />
            <Route path="/legal-notice" element={<LegalNotice />} />
            <Route path="/available-calendar" element={<AvailableCalendar />} />
            <Route
              path="/availability-list/:id"
              element={<AvailabilityList />}
            />
            <Route exact path="/" element={<Home />} />
            <Route path="/dish-details/:id" element={<DishdetailPage />} />
            <Route path="/verify-otp" element={<Otp />} />
            <Route path="/docs-verification" element={<Verification />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-forgot-otp" element={<VerifyForgotOtp />} />
            {/* <Route exact path="/contact-us" element={<ContactUs />} /> */}
            <Route exact path="/contact-us" element={<ContactPage />} />
            <Route path="/upload" element={<Upload />} />
          </Routes>
          <ToastContainer
            className="font-slussen uppercase text-[16px] font-bold "
            toastStyle={{
              backgroundColor: "#FFF",
              color: "#FF914D",
              borderRadius: "10px",
            }}
            hideProgressBar={true}
            style={{
              width: "auto",
              marginTop: "80px",
              transition: "background-color 0.5s ease",
            }}
            autoClose={3000}
          />
          {/* <ChatSticky /> */}
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
