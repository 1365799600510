import React from "react";

const NearMe = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
        <path
          d="M17 10H19"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 10H3"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 17V19"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 1V3"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13 10C13 11.6569 11.6569 13 10 13C8.3431 13 7 11.6569 7 10C7 8.3431 8.3431 7 10 7C11.6569 7 13 8.3431 13 10Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
      </svg>
    </>
  );
};

export default NearMe;
