import React from "react";

const CardNumber = () => {
  return (
    <div>
      <svg
        width="30"
        height="24"
        viewBox="0 0 32 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 13C1 7.34314 1 4.51472 2.75736 2.75735C4.51473 1 7.34314 1 13 1H19C24.6568 1 27.4853 1 29.2426 2.75735C31 4.51472 31 7.34314 31 13C31 18.6568 31 21.4853 29.2426 23.2426C27.4853 25 24.6568 25 19 25H13C7.34314 25 4.51473 25 2.75736 23.2426C1 21.4853 1 18.6568 1 13Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
        <path
          d="M13 19H7"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M19 19H16.75"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M1 10H31"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};

export default CardNumber;
