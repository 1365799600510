import React, { useState, useEffect } from "react";
import { FAQ } from "../services/profileService";
import AccordionItem from "./AccordianItem";

const AccordionContainer = () => {
  const [faqDetails, setFaqDetails] = useState(null);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    setFlag(true);
  }, []);
  const fetchData = async () => {
    try {
      const resp = await FAQ();
      if (resp?.success) {
        setFaqDetails(resp?.data);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setFlag(false);
    }
  };

  useEffect(() => {
    if (flag) {
      setFlag(false);
      fetchData();
    }
  }, [flag]);

  return (
    <div className=" container mx-auto mt-8 font-Glacial">
      {faqDetails?.map((item, index) => (
        <AccordionItem
          key={index}
          titleEn={item?.translations?.en?.question}
          title={item?.question}
          content={item?.answer}
          contentEn={item?.translations?.en?.answer}
        />
      ))}
    </div>
  );
};

export default AccordionContainer;
