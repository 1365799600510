import React from "react";

const CardBecomeHost = ({ svgIcon, title, detail }) => {
  return (
    <div>
      <div className="h-[187px] border border-primary rounded-[20px]">
        <div className="p-6">
          <div className="w-[60px] h-[60px] rounded-full shadow-md flex items-center justify-center">
            {svgIcon}
          </div>
          <div className="font-Comfortaa text-[18px] font-semibold mt-4">
            {title}
          </div>
          <div className="font-Glacial text-[16px] font-normal mt-3">
            {detail}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBecomeHost;
