import React from "react";

const CancelledBooking = () => {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.4751 10.4749L5.52539 5.52515M10.4751 5.52515L5.52539 10.4749"
          stroke="#FF6767"
          stroke-width="1.5"
          stroke-linecap="square"
        />
        <path
          d="M3.05025 12.9497C0.316583 10.2161 0.316583 5.78392 3.05025 3.05025C5.78392 0.316583 10.2161 0.316583 12.9497 3.05025C15.6834 5.78392 15.6834 10.2161 12.9497 12.9497C10.2161 15.6834 5.78392 15.6834 3.05025 12.9497Z"
          stroke="#FF6767"
          stroke-width="1.5"
          stroke-linecap="square"
        />
      </svg>
    </div>
  );
};

export default CancelledBooking;
