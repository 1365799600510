import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cancelledId: null,
};

const cancelledIdSlice = createSlice({
  name: "myBooking",
  initialState,
  reducers: {
    getId: (state, action) => {
      state.cancelledId = action.payload;
    },
  },
});

export const { getId } = cancelledIdSlice.actions;
export default cancelledIdSlice.reducer;
