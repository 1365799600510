import React from "react";

const Cake = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
      >
        <path
          d="M7.19449 6.35107C3.64162 6.7146 1 8.12993 1 9.82638C1 11.7943 4.57225 13.3889 8.97334 13.3889C13.3744 13.3889 16.9467 11.7943 16.9467 9.82638C16.9467 8.12993 14.3002 6.7146 10.7522 6.35107"
          stroke="#FF914D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 11.804C1 12.5019 1.18419 13.5246 1.9694 14.0772C2.52196 14.4698 3.64647 13.9948 4.48985 14.2614C5.17813 14.4844 5.74523 15.5507 6.57407 15.667C7.31566 15.7688 8.16874 14.906 8.99758 14.906C9.82642 14.906 10.6601 15.7688 11.4211 15.667C12.2499 15.5507 12.8219 14.4844 13.5101 14.2662C14.3535 13.9948 15.478 14.4698 16.0306 14.0772C16.8061 13.5246 17 12.5019 17 11.804"
          stroke="#FF914D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 9.82642V16.3408C1 18.3087 4.57225 19.9034 8.97334 19.9034C13.3744 19.9034 16.9467 18.3087 16.9467 16.3408V9.82642"
          stroke="#FF914D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.19531 5.86157V9.82643C7.19531 10.2627 8.00476 10.6359 8.97417 10.6359C9.94357 10.6359 10.753 10.2772 10.753 9.84097V5.86157"
          stroke="#FF914D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.97417 6.65647C9.9566 6.65647 10.753 6.30058 10.753 5.86156C10.753 5.42254 9.9566 5.06665 8.97417 5.06665C7.99173 5.06665 7.19531 5.42254 7.19531 5.86156C7.19531 6.30058 7.99173 6.65647 8.97417 6.65647Z"
          stroke="#FF914D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.97378 5.86159C8.97378 5.42536 8.46484 4.84371 8.46484 4.47534"
          stroke="#FF914D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.48885 4.47531C8.36283 4.10209 7.64062 4.13602 7.64062 3.13269C7.64062 2.12936 8.32405 1.82399 8.32405 1C8.65247 1.16753 8.92631 1.42523 9.11346 1.74287C9.30061 2.06052 9.39332 2.42493 9.3807 2.7934C9.39412 3.12758 9.31864 3.45934 9.16196 3.75482C9.00528 4.0503 8.77301 4.29892 8.48885 4.47531Z"
          stroke="#FF914D"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default Cake;
