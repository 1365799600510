import React, { useEffect, useRef, useState } from "react";
import { BiUser } from "react-icons/bi";
import { HiOutlineMenu } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import DBtn from "./DBtn";
import NearMe from "../svg/NearMe";
import { AiOutlineClose } from "react-icons/ai";
import SignUpForm from "./SignUpForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../utils/hooks/useAuth";
import LogIn from "./LogIn";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { CiSearch } from "react-icons/ci";

const Header = ({
  onNearMeClick,
  searchQuery,
  onInputChange,
  onKeyPress,
  onSearch,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const navigate = useNavigate();
  const location = useLocation();
  const { isUnread } = useSelector((state) => state?.isUnread);
  const { isAuthenticated } = useAuth();
  const isMountLocation = useRef(null);
  const [selectedLanguageOption, setSelectedLanguageOption] = useState(lang);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [userData, setUserData] = useState({
    long: "-0.9212430",
    lati: "41.638228",
  });

  useEffect(() => {
    if (isAuthenticated) {
      setLoginOpen(false);
      setSignUpOpen(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isMountLocation.current) {
      navigator.geolocation.getCurrentPosition(success);
      isMountLocation.current = true;
    }
    // eslint-disable-next-line
  }, []);

  function success(distance) {
    const lati = distance.coords.latitude;
    const long = distance.coords.longitude;

    setUserData({
      ...userData,
      lati: lati,
      long: long,
    });
  }

  const handleNearMe = () => {
    onNearMeClick();
  };

  const handelMobleMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const changePlatFormLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleLanguageOptionChange = (event) => {
    const lang = event.target.value;
    setSelectedLanguageOption(lang);
    changePlatFormLanguage(lang);
  };

  return (
    <>
      <header className=" container mx-auto flex  justify-between  items-start  py-[29px]">
        <Link to="/">
          <div>
            <img
              className="md:w-[140px] w-[100px]"
              src="../images/header/LOGO.svg"
              alt="logo"
            />
          </div>
        </Link>
        <nav className="">
          <ul className="hidden md:flex justify-center gap-x-8 font-Glacial text-primary cursor-pointer">
            <li className="group relative">
              <Link to="/">
                <span>{t("header.menuHome")}</span>
              </Link>
            </li>
            {user?.role === "guest" && (
              <li className="group relative">
                <Link to="/favorites">
                  <span>{t("header.menuFavorites")}</span>
                </Link>
              </li>
            )}
            {user?.role === "host" && (
              <li className="group relative">
                <Link to="/add-dish">
                  <span>{t("header.menuAddNewDish")}</span>
                </Link>
              </li>
            )}

            {isAuthenticated && (
              <li className="group relative">
                <Link to="/mybookings">
                  <span>{t("header.menuMyBooking")}</span>
                </Link>
              </li>
            )}
            {/* {user?.role === "host" && ( */}
            {isAuthenticated && (
              <li className="group relative">
                <Link to="/chat">
                  {isUnread && (
                    <div className="absolute rounded-full right-0 w-2 h-2 bg-primary"></div>
                  )}
                  <span>{t("header.chat")}</span>
                </Link>
              </li>
            )}
            {/* )} */}
            <li className="group relative">
              <Link to="/contact-us">
                <span>{t("header.menuContactUs")}</span>
              </Link>
            </li>
          </ul>
          {location.pathname === "/" && user?.role !== "host" && (
            <div className="md:flex hidden items-center  gap-x-6 py-6">
              <div className="relative">
                <div className="absolute text-[40px] text-primary p-3">
                  <CiSearch />
                </div>
                <input
                  className="xl:w-[396px] w-[300px] h-[60px] focus:ring-0 outline-none border-0 rounded-full ps-[72px] pe-[72px] font-Glacial  text-[18px] shadow-md shadow-[#E6E6E6E5] placeholder:text-[#ABABAB]"
                  type="text"
                  placeholder={t("header.searchHere")}
                  value={searchQuery}
                  onChange={onInputChange}
                  onKeyPress={onKeyPress}
                />
                <div className="absolute end-0 bottom-0">
                  <DBtn variant="default" size="large" onClick={onSearch}>
                    {t("header.search")}
                  </DBtn>
                </div>
              </div>

              <div className="">
                <DBtn
                  variant="default"
                  size="large"
                  startIcon={<NearMe />}
                  onClick={handleNearMe}
                >
                  {t("header.buttonNearMe")}
                </DBtn>
              </div>
            </div>
          )}
        </nav>
        <div className="flex justify-center items-center gap-x-2">
          <div className="flex ">
            <div className="flex items-center">
              <label
                htmlFor="english"
                className="text-primary font-Glacial cursor-pointer "
              >
                {t("english")}
              </label>

              <input
                type="radio"
                id="english"
                name="options"
                value="en"
                checked={
                  selectedLanguageOption === "en" ||
                  selectedLanguageOption === "en-US"
                }
                onChange={handleLanguageOptionChange}
                style={{ borderRadius: "10px" }}
                className="mx-2 cursor-pointer checked:bg-primary checked:hover:bg-primary checked:active:bg-primary checked:focus:bg-primary focus:bg-primary focus:outline-none focus:ring-1 focus:ring-primary"
              />
            </div>
            <div className="flex items-center">
              <label
                htmlFor="spanish"
                className="text-primary font-Glacial cursor-pointer"
              >
                {t("spanish")}
              </label>

              <input
                type="radio"
                id="spanish"
                name="options"
                value="es"
                checked={selectedLanguageOption === "es"}
                onChange={handleLanguageOptionChange}
                className="mx-2 cursor-pointer checked:bg-primary checked:hover:bg-primary checked:active:bg-primary checked:focus:bg-primary focus:bg-primary focus:outline-none focus:ring-1 focus:ring-primary "
              />
            </div>
          </div>
          {isAuthenticated ? (
            <div
              onClick={() => navigate("/profile")}
              className="w-[50px] h-[50px] bg-[#FF914D1A] p-[18px] text-primary rounded-full hidden md:block cursor-pointer"
            >
              <BiUser />
            </div>
          ) : (
            <div className="flex justify-start items-center gap-4">
              <DBtn
                variant="contain"
                size="small"
                onClick={() => setLoginOpen(true)}
              >
                {t("login")}
              </DBtn>
              <DBtn
                variant="contain"
                size="small"
                onClick={() => setSignUpOpen(true)}
              >
                {t("signUp")}
              </DBtn>
            </div>
          )}
        </div>

        <div className="visible md:hidden">
          <span className="text-primary ">
            <HiOutlineMenu size={40} onClick={handelMobleMenuToggle} />
          </span>
        </div>
      </header>
      {/* mobile menu */}
      <div
        className={`${
          isMobileMenuOpen
            ? "top-0 left-0 w-full h-screen"
            : "hidden top-2.5 left-2.5"
        } w-3/4 fixed p-8 transition-all duration-4000 bg-white z-50 shadow-xl`}
      >
        <ul className="md:hidden visible flex justify-end mt-5">
          <li className="cursor-pointer">
            <RxCross2 onClick={handelMobleMenuToggle} className="" size={40} />
          </li>
        </ul>
        <ul className=" gap-x-8 font-Glacial text-primary cursor-pointer">
          <li onClick={() => navigate("/")}>
            Home
            <p className="flex">
              <span
                className={`inline-block h-[2px] w-6 hover:bg-[#FF914D] `}
              ></span>
            </p>
          </li>
          {user?.role === "host" && (
            <li className="group relative">
              <Link to="/add-dish">
                <span>{t("header.menuAddNewDish")}</span>
              </Link>
            </li>
          )}
          {user?.role === "guest" && (
            <li className="group relative">
              <Link to="/favorites">
                <span>{t("header.menuFavorites")}</span>
              </Link>
            </li>
          )}

          {isAuthenticated && (
            <li onClick={() => navigate("/my-bookings")}>
              My booking
              <p className="flex">
                <span
                  className={`inline-block h-[2px] w-6 hover:bg-[#FF914D] `}
                ></span>
              </p>
            </li>
          )}
          {user?.role === "host" && (
            <li className="group relative">
              <Link to="/chat">
                <span>{t("header.chat")}</span>
              </Link>
            </li>
          )}

          <li onClick={() => navigate("/contact-us")}>
            Contact us
            <p className="flex">
              <span
                className={`inline-block h-[2px] w-6 hover:bg-[#FF914D] `}
              ></span>
            </p>
          </li>
        </ul>
        {isAuthenticated && (
          <div
            className="w-[50px] h-[50px] bg-[#FF914D1A] p-[18px] text-primary rounded-full"
            onClick={() => navigate("/profile")}
          >
            <BiUser />
          </div>
        )}
      </div>

      {/* signup form modal */}
      {isSignUpOpen && (
        <div className="z-50 fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white  rounded-[20px] relative">
            <button
              className="absolute right-6 top-1 z-50"
              onClick={() => setSignUpOpen(false)}
            >
              <div className="w-[40px] h-[40px] bg-[#FF914D1A] p-[13px] text-primary rounded-full">
                <AiOutlineClose />
              </div>
            </button>
            <p>
              <SignUpForm
                handleSignupModalOpen={() => {
                  setSignUpOpen(false);
                  setLoginOpen(true);
                }}
              />
            </p>
          </div>
        </div>
      )}

      {/* login form modal */}
      {isLoginOpen && (
        <div className="z-50 fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white  rounded-[20px] relative">
            <button
              className="absolute  top-[10px] right-[12px] z-50"
              onClick={() => setLoginOpen(false)}
            >
              <div className="w-[40px] h-[40px] bg-[#FF914D1A] p-[13px] text-primary rounded-full">
                <AiOutlineClose />
              </div>
            </button>
            <p>
              <LogIn
                handleSignupModalOpen={() => {
                  setLoginOpen(false);
                  setSignUpOpen(true);
                }}
              />
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
