import React from "react";

const IdVarification = () => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log("Selected file:", file);
  };

  return (
    <div>
      <div className="flex items-center justify-center py-12">
        <div className="text-center">
          <label
            htmlFor="photo-upload"
            className="cursor-pointer inline-block "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
            >
              <path
                d="M18.9998 9.40234C21.6098 9.41688 23.0233 9.53261 23.9453 10.4547C24.9998 11.5091 24.9998 13.2061 24.9998 16.6002V17.8002C24.9998 21.1942 24.9998 22.8913 23.9453 23.9457C22.8909 25.0001 21.1939 25.0001 17.7998 25.0001H8.19993C4.80585 25.0001 3.10881 25.0001 2.05441 23.9457C1 22.8913 1 21.1942 1 17.8002V16.6002C1 13.2061 1 11.5091 2.05441 10.4547C2.97645 9.53261 4.38993 9.41688 6.99994 9.40234"
                fill="white"
              />
              <path
                d="M18.9998 9.40234C21.6098 9.41688 23.0233 9.53261 23.9453 10.4547C24.9998 11.5091 24.9998 13.2061 24.9998 16.6002V17.8002C24.9998 21.1942 24.9998 22.8913 23.9453 23.9457C22.8909 25.0001 21.1939 25.0001 17.7998 25.0001H8.19993C4.80585 25.0001 3.10881 25.0001 2.05441 23.9457C1 22.8913 1 21.1942 1 17.8002V16.6002C1 13.2061 1 11.5091 2.05441 10.4547C2.97645 9.53261 4.38993 9.41688 6.99994 9.40234"
                stroke="#FF914D"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M12.9999 16.5998V1V16.5998ZM12.9999 1L16.5998 5.19996L12.9999 1ZM12.9999 1L9.3999 5.19996L12.9999 1Z"
                fill="white"
              />
              <path
                d="M12.9999 16.5998V1M12.9999 1L16.5998 5.19996M12.9999 1L9.3999 5.19996"
                stroke="#FF914D"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <input
              type="file"
              id="photo-upload"
              accept="image/*"
              className="sr-only"
              onChange={handleFileChange}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default IdVarification;
