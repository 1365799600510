import React, { useEffect, useState, useRef } from "react";
import SendBtn from "../svg/SendBtn";
import { db } from "../firebase";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import Loader from "./Loader";

const Chat = ({
  currentUser,
  selectedUser,
  message,
  setMessage,
  fetchMessages,
  messages,
  setMessages,
  // chatLoading,
}) => {
  const lang = localStorage.getItem("i18nextLng");
  const [chatLoading, setIsLoading] = useState(false);
  const messageContainerRef = useRef(null);

  useEffect(() => {
    fetchMessages();
  }, [messages?.length]);
  // console.log("messages", messages);

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      if (currentUser && selectedUser) {
        let chatDocID = [currentUser.id, selectedUser.uid].join("_");
        let chatDocRef = doc(db, "chats", chatDocID);

        let chatDoc = await getDoc(chatDocRef);

        if (!chatDoc.exists()) {
          chatDocID = [selectedUser.uid, currentUser.id].join("_");
          chatDocRef = doc(db, "chats", chatDocID);
          chatDoc = await getDoc(chatDocRef);
          console.log("chatDocID sender", chatDocID);
          if (!chatDoc.exists()) {
            await setDoc(chatDocRef, {
              id: chatDocID,
              lastMessage: message,
              lastMessageSender: currentUser?.id,
              lastMessageRead: false,
              lastMessageTime: Timestamp.fromDate(new Date()),
              uidList: [currentUser.id, selectedUser.uid],
              users: [
                {
                  uid: currentUser.id,
                  image: currentUser.profileImage,
                  name: currentUser.firstName + " " + currentUser.lastName,
                },
                selectedUser,
              ],
            });
          }
        } else {
          await setDoc(
            chatDocRef,
            {
              lastMessage: message,
              lastMessageRead: false,
              lastMessageSender: currentUser?.id,
              lastMessageTime: Timestamp.fromDate(new Date()),
            },
            { merge: true }
          );
        }

        const subCollectionRef = collection(
          db,
          `chats/${chatDocID}/${chatDocID}`
        );
        await addDoc(subCollectionRef, {
          content: message,
          senderUid: currentUser?.id,
          time: Timestamp.fromDate(new Date()),
          type: "text",
          read: false,
        })
          .then(() => {
            console.log(
              "Message sent and document updated",
              subCollectionRef?.id
            );
          })
          .catch((err) => {
            console.log("Error in sending message", err);
          });

        console.log("Message sent and document updated");
        setMessage("");
        fetchMessages();
      }
    } catch (error) {
      console.log("Error in sendMessage", error);
    }
  };

  const sortedData = messages?.sort((a, b) => {
    const timestampA = a?.time?.seconds * 1000 + a?.time?.nanoseconds / 1000000;
    const timestampB = b?.time?.seconds * 1000 + b?.time?.nanoseconds / 1000000;
    return timestampA - timestampB;
  });

  const senderMessage = sortedData?.filter(
    (item) => item?.senderUid !== currentUser?.id
  );

  const OppositeMessge = sortedData?.filter(
    (item) => item?.senderUid === currentUser?.id
  );

  function getTimeFromSecondsAndNanoseconds(seconds, nanoseconds) {
    const milliseconds = nanoseconds / 1000000;
    const date = new Date(seconds * 1000 + milliseconds);

    const dateform = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${dateform}/${month}/${year}  ${formattedHours}:${formattedMinutes}`;
  }

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [sortedData]);

  // console.log("sortedData", sortedData);
  return (
    <>
      <div className="w-full lg:max-w-[792px]  mx-auto bg-white rounded-xl shadow-sm">
        <div className="flex flex-col h-full md:h-[600px]">
          {chatLoading ? (
            <div className="flex justify-center items-center m-auto h-[50vh]">
              <Loader />
            </div>
          ) : (
            <div className="relative h-full md:h-[600px]">
              <div
                ref={messageContainerRef}
                className={`relative overflow-y-auto mt-2 px-4 py-6 max-h-[530px] flex flex-col`}
              >
                {sortedData?.map((item, i) => {
                  const isCurrentUser = item?.senderUid !== currentUser?.id;
                  // console.log("isCurrentUser", isCurrentUser);
                  return (
                    <div
                      key={i}
                      className={` mt-2 ${
                        isCurrentUser ? "self-start" : "self-end "
                      } `}
                    >
                      <div
                        className={`p-2 mb-1 rounded-lg font-Comfortaa ${
                          isCurrentUser
                            ? "bg-[#F9F9F9] text-black  "
                            : " bg-primary text-white"
                        }`}
                      >
                        {item?.content}
                      </div>
                      {item?.time && (
                        <div
                          className={`text-xs text-gray-600 ${
                            isCurrentUser ? "self-start" : "self-end"
                          }`}
                        >
                          {getTimeFromSecondsAndNanoseconds(
                            item?.time?.seconds,
                            item?.time?.nanoseconds
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendMessage(message);
                }}
                className="flex w-full items-center py-2 px-4 absolute bottom-0 "
              >
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={
                    lang === "en" || lang === "en-US"
                      ? "Type your message..."
                      : "Escribe tu mensaje..."
                  }
                  className="relative w-full font-Comfortaa flex-1 py-2 px-4 border border-primary rounded-full focus:outline-none focus:border-0  focus:ring-2 focus:ring-primary "
                />
                <button
                  onClick={sendMessage}
                  disabled={message.trim() === ""}
                  type="submit"
                  className={`ml-2 py-2 px-4 text-white rounded-full ${
                    message.trim() === "" ? "cursor-not-allowed" : ""
                  }`}
                >
                  <SendBtn />
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Chat;
