import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import Title from "../components/Title";
import { Link, useNavigate } from "react-router-dom";
import ShowCookies from "../components/ShowCookies";
import { IoArrowBackOutline } from "react-icons/io5";
import Loader from "../components/Loader";
import { getLegalNotice } from "../services/policyServices";

const LegalNotice = () => {
  const lang = localStorage.getItem("i18nextLng");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBack = () => {
    setLoading(true);
    navigate(-1);

    setTimeout(() => {
      window.scrollTo(0, 0);
      setLoading(false);
    }, 100);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await getLegalNotice();
      if (resp?.success) {
        setData(resp?.data[0]);
      }
    } catch (error) {
      console.log("Error fetching data: ", error);
    }
    finally{
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [lang, navigate]);
  return (
    <>
      <div className="container mx-auto">
        <ShowCookies />
        <Header />
        <div
          onClick={goBack}
          className="flex gap-2 items-center font-Glacial font-bold mb-4 cursor-pointer"
        >
          <IoArrowBackOutline size={20} />
          <p>{t("termsCondition.back")}</p>
        </div>
        <div className="font-bold">
          <Title title={t("legalNotice.title")} />
        </div>
        {loading ? (
          <>
            <div className="flex justify-center items-center m-auto h-[50vh]">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {lang === "en" || lang === "en-US" ? (
              <div className="font-Glacial text-xl">
                <div className="exception-div1 exception-div2 ">
                  {data?.translations?.en?.policyDetails?.text && (
                    <div>
                      {" "}
                      {parse(data?.translations?.en?.policyDetails?.text)}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="font-Glacial text-xl">
                <div className="exception-div1 exception-div2">
                  {data?.policyDetails?.text && (
                    // <div dangerouslySetInnerHTML={createMarkup(data?.termsAndConditionHost)} />
                    <div> {parse(data?.policyDetails?.text)}</div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default LegalNotice;
