import axios from "axios";

import { SHA1 } from "crypto-js";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
// day suffix
const getDaySuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  const lastDigit = day % 10;
  const suffixes = ["th", "st", "nd", "rd"];
  return suffixes[lastDigit] || "th";
};

// format date - DD, MMM YYYY
export const formatDateToDDMMMYYYY = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const suffix = getDaySuffix(day);
  const formattedDate = `${day}${suffix} ${month}, ${year}`;
  return formattedDate;
};

//get  month and year
export const formatDateToMMMYYYY = (dateStr) => {
  const date = new Date(dateStr);
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const formattedDate = `${month}, ${year}`;
  return formattedDate;
};

// format date - MM-DD-YY
export const formatDateToMMDDYY = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${day < 10 ? `0${day}` : day}-${
    month < 10 ? `0${month}` : month
  }-${year.toString().substr(-2)}`;
  return formattedDate;
};

// format date - YYYY-MM-DD
export const formatDateInYYYYMMDD = (givenDate) => {
  const inputDate = new Date(givenDate);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const getNumberOfDays = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
};

//SHA1 algorithm
export const getSHA1 = (str) => {
  let res;
  res = SHA1(str).toString();
  return res;
};

//Get IPV4 address
export const getIPV4Address = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    const { ip } = response.data;
    return ip;
  } catch (error) {
    console.log("Eroor getting IPV4 : ", error);
  }
};


export function timeAgo(timestamp) {
  const now = new Date();
  const time = new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000
  );
  const diff = now - time;

  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(diff / 3600000);
  const days = Math.floor(diff / 86400000);

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return "just now";
  }
}