import React from "react";
import Header from "../components/Header";
import Title from "../components/Title";
import InputField from "../components/InputField";
import Dropdown from "../components/Dropdown";
import Footer from "../components/Footer";
import DBtn from "../components/DBtn";
import Dish from "../svg/Dish";
import Image from "../svg/Image";
import UploadBtn from "../svg/UploadBtn";
import FoodCategory from "../svg/FoodCategory";
import FourSqure from "../svg/FourSqure";
import Serve from "../svg/Serve";
import DishPrice from "../svg/DishPrice";
import GuestCapacity from "../svg/GuestCapacity";
import Cancelation from "../svg/Cancelation";

const Listing = () => {
  return (
    <div>
      <div className="border-b-[1px] border-[#FF914D]">
        <div className="container mx-auto w-full">
          <div className="">
            <Header />
          </div>
        </div>
      </div>
      <div className="m-auto md:container pt-[40px] font-bold">
        <Title title="Add new listing" />
      </div>
      <div className="container mx-auto pt-[40px]">
        <InputField startIcon={<Dish />} placeholder="Enter dish title" />

        <div className="pt-[16px] pl-[15px]">
          <span className="text-[#474747]">English</span>
        </div>
        <div className="pt-[10px] pb-[29px]">
          <input
            className="w-full flex-start h-[50px] outline-none rounded-full ps-[72px] pe-[72px] font-Glacial  text-[18px] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5] placeholder:text-[#ABABAB]"
            type="text"
            placeholder=""
          />
        </div>

        <div className="pt-[24px]">
          <div className="container mx-auto border-[1px] border-[#FF914D] rounded-2xl px-[16px] py-[24px]">
            <p className="text-lg font-Glacial text-[#111] pt-[16px]">
              Accessibility
            </p>
            <p className="flex">
              <span
                className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
              ></span>
            </p>
            <div className="text-[#ABABAB] pt-[21px]">
              <span>
                Mention Accessibilities for guests to access your place.
              </span>
            </div>
            <div className="xl:flex">
              <div>
                <button className="mt-5 text-[#111] sm:w-[326px] outline-none py-[11px] px-[24px] rounded-full flex items-center gap-x-2 text-[16px] font-Glacial shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5]">
                  <p>Wheelchair access</p>
                </button>
              </div>
              <div className="xl:pl-[24px]">
                <button className="mt-5 text-[#111] sm:w-[326px] outline-none py-[11px] px-[24px] rounded-full flex items-center gap-x-2 text-[16px] font-Glacial shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5]">
                  <p>Wheelchair access</p>
                </button>
              </div>
              <div className="xl:pl-[24px]">
                <button className="mt-5 text-[#111] sm:w-[326px] outline-none py-[11px] px-[24px] rounded-full flex items-center gap-x-2 text-[16px] font-Glacial shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5]">
                  <p>Wheelchair access</p>
                </button>
              </div>
              <div className="xl:pl-[24px]">
                <button className="mt-5 text-[#111]  outline-none py-[14px] px-[14px] rounded-full flex items-center gap-x-2 text-[16px] font-Glacial shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M6.89152 14C6.56677 13.8872 6.44712 13.6582 6.45054 13.3197C6.45738 11.443 6.45396 9.56632 6.45738 7.68961C6.45738 7.56997 6.43003 7.54262 6.31039 7.54262C4.43368 7.54604 2.55697 7.54262 0.680264 7.54946C0.341841 7.54946 0.112808 7.43323 0 7.10848C0 7.0367 0 6.96149 0 6.8897C0.112808 6.56496 0.341841 6.44531 0.680264 6.44873C2.55355 6.45557 4.42684 6.45215 6.30013 6.45557C6.43687 6.45557 6.45738 6.41796 6.45738 6.29148C6.45396 4.39426 6.45396 2.50046 6.45396 0.603247C6.45396 0.312682 6.5907 0.104159 6.82315 0.0289535C7.16499 -0.0838541 7.51367 0.145179 7.54443 0.504113C7.54785 0.555389 7.54785 0.603247 7.54785 0.654523C7.54785 2.54149 7.54785 4.42845 7.54443 6.31541C7.54443 6.42822 7.57178 6.45557 7.68459 6.45557C9.58864 6.45215 11.4927 6.45215 13.4002 6.45215C13.6805 6.45215 13.889 6.58547 13.971 6.8145C14.0907 7.15292 13.8651 7.50844 13.5061 7.54262C13.4514 7.54946 13.3968 7.54604 13.3421 7.54604C11.4654 7.54604 9.58864 7.54604 7.70852 7.54262C7.58204 7.54262 7.54443 7.56313 7.54443 7.69987C7.55127 9.57316 7.54443 11.4464 7.55127 13.3197C7.55127 13.6582 7.43504 13.8872 7.11029 14C7.03851 14 6.9633 14 6.89152 14Z"
                      fill="#FF914D"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[29px]">
          <InputField
            startIcon={<Image />}
            placeholder="Display image"
            endIcon={<UploadBtn />}
          />
        </div>

        <div className="mt-[29px]">
          <InputField
            startIcon={<Image />}
            placeholder="Upload food images"
            endIcon={<UploadBtn />}
          />
        </div>

        <div className="pb-[34px] mt-[29px]">
          <InputField
            startIcon={<Image />}
            placeholder="Upload place images"
            endIcon={<UploadBtn />}
          />
        </div>

        <div>
          <Dropdown
            name=""
            labelName=""
            placeholder="Select dish category"
            startIcon={<FoodCategory />}
            options={[
              { value: 1, label: "Spanish" },
              { value: 2, label: "French" },
              { value: 3, label: "Japanese" },
              { value: 4, label: "Italian" },
            ]}
            onChange={(val) => console.log("val", val)}
            size="small"
          />
        </div>

        <div className="pt-[34px]">
          <Dropdown
            name=""
            labelName=""
            placeholder="Select food type"
            startIcon={<FourSqure />}
            options={[
              { value: 1, label: "Veg" },
              { value: 2, label: "Non-Veg" },
              { value: 3, label: "Vegan" },
            ]}
            onChange={(val) => console.log("val", val)}
            size="small"
          />
        </div>

        <div className="pt-[34px]">
          <Dropdown
            name=""
            labelName=""
            placeholder="Select time"
            startIcon={<FourSqure />}
            options={[
              { value: 1, label: "Breakfast" },
              { value: 2, label: "Lunch" },
              { value: 3, label: "Branch" },
              { value: 4, label: "Dinner" },
            ]}
            onChange={(val) => console.log("val", val)}
            size="small"
          />
        </div>
        <div className="pt-[34px]">
          <InputField
            startIcon={<Serve />}
            placeholder="No. of servings per dish"
          />
        </div>
        <div className="pt-[34px]">
          <InputField
            startIcon={<FourSqure />}
            placeholder="Enter dish description"
          />

          <div className="pt-[16px] pl-[15px]">
            <span className="text-[#474747]">English</span>
          </div>
          <div className="pt-[10px] pb-[29px]">
            <input
              className="w-full flex-start h-[50px] outline-none rounded-full ps-[25px] pe-[25px] font-Glacial  text-[18px] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5] placeholder:text-[#ABABAB]"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div className="pb-[34px]">
          <InputField
            startIcon={<DishPrice />}
            placeholder="Enter dish price "
          />
        </div>
        <div>
          <InputField
            startIcon={<GuestCapacity />}
            placeholder="Enter your guest capacity at a time "
          />
        </div>
        <div className="pt-[34px]">
          <Dropdown
            name=""
            labelName=""
            placeholder="Select Cancelation policy"
            startIcon={<Cancelation />}
            options={[
              { value: 1, label: "No Cancelation" },
              {
                value: 2,
                label: "Cancel according to MD4U Cancelation policy",
              },
            ]}
            onChange={(val) => console.log("val", val)}
            size="small"
          />
        </div>
        <div className=" sm:container mx-auto mb-[40px] pt-[168px] flex justify-end ">
          <DBtn
            variant="contain"
            size="Medium"
            onClick={(e) => console.log("button click")}
            startIcon=""
          >
            Save
          </DBtn>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Listing;
