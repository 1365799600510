import api from "./Api";

// get home banner
export const getBanners = () => {
  return api.get("/banners");
};

// get all food categories
export const getFoodCategories = () => {
  return api.get("/food-categories");
};

// get all dish list
export const getDishList = (data) => {
  return api.post("/guest/get-listings", data);
};

// get dish details
export const getDishDetails = (id) => {
  return api.get(`/guest/listing?id=${id}`);
};

//upcoming dish details
export const getUpcomingDish = (data) => {
  return api.post("/guest/get-upcoming-bookings", data);
};

// check availability
export const checkAvailability = (data) => {
  return api.post("/guest/check-availability", data);
};

// get host dish
export const getHostDishList = (data) => {
  return api.post("/host/hosted-listings", data);
};

export const updateHostDishById = (id,data) => {
  return api.put(`/host/listing?id=${id}`,data);
};

export const addBooking = (data) => {
  return api.post("guest/booking", data);
};
