import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ShowCookies from "../components/ShowCookies";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { getAffidavit } from "../services/policyServices";
import Title from "./Title";
import { useTranslation } from "react-i18next";
const Affidavit = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const lang = localStorage.getItem("i18nextLng");

  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await getAffidavit();
      if (resp?.success) {
        setData(resp?.data[0]);
      }
    } catch (err) {
      console.log("Error fetching data: ", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [lang, navigate]);

  console.log("fetching data guest:", data);
  return (
    <>
      <div className="container mx-auto">
        <ShowCookies />
        <Header />
        <div className="font-bold">
          <Title title={t("legalNotice.affidavite")} />
        </div>
        {loading ? (
          <>
            <div className="flex justify-center items-center m-auto h-[50vh]">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {lang === "en" || lang === "en-US" ? (
              <>
                <div className="font-Glacial text-xl">
                  <div className="exception-div1 exception-div2 ">
                    {data?.translations?.en?.policyDetails?.text && (
                      <div>
                        {parse(data?.translations?.en?.policyDetails?.text)}
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="font-Glacial text-xl">
                  <div className="exception-div1 exception-div2">
                    {data?.policyDetails?.text && (
                      <div> {parse(data?.policyDetails?.text)}</div>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Affidavit;
