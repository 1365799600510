import React, { useEffect, useState } from "react";
import Edit from "../svg/Edit";
import Modal from "./Modal";
import Title from "./Title";
import PlusIcon from "../svg/PlusIcon";
import DeleteIcon from "../svg/DeleteIcon";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import {
  getAvailability,
  updateAvailability,
} from "../services/profileService";
import { useTranslation } from "react-i18next";

const CardAvailable = ({ startDate, endDate, timeSlots }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [dataAvailable, setDataAvailable] = useState([]);
  const [timeRanges, setTimeRanges] = useState(timeSlots);
  const [state1, setState1] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
    },
  ]);

  const getHostAvaliability = async () => {
    try {
      const resp = await getAvailability();
      // console.log("resp", resp.data);
      const outData = resp?.data?.map((item) => {
        return {
          userId: item?.userId,
          startDate: item?.startDate,
          endDate: item?.endDate,
          timeSlots: item?.timeSlots.map((timeRange) => ({
            startTime: timeRange.startTime,
            endTime: timeRange.endTime,
          })),
        };
      });

      setDataAvailable(outData);
      // setDataAvailable(resp?.data);
    } catch (error) {
      console.log("getHostAvaliability: ", error);
    } finally {
    }
  };
  // console.log(dataAvailable);

  const updateHostAvailability = async () => {
    const payload = {
      dates: dataAvailable,
    };
    try {
      if (timeRanges.length > 0) {
        const resp = await updateAvailability(payload);
        // console.log("update resp", resp);
        if (resp?.success) {
          // setDataAvailable((prevData) => [...prevData, resp?.data]);
          setFlag(true);
          setIsModalOpen(false);
        }
      }
    } catch (error) {
      console.log("update error", error);
    }
  };

  const handleUpdate = () => {
    updateHostAvailability();
    setFlag(true);
  };

  useEffect(() => {
    if (flag) {
      setFlag(false);
      getHostAvaliability();
    }
  }, [flag]);

  const handleAddTime = () => {
    setTimeRanges([
      ...timeRanges,
      { startTime: "9:00 am", endTime: "10:00 am" },
    ]);
  };

  const handleDeleteTime = (index) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges.splice(index, 1);
    setTimeRanges(updatedTimeRanges);
  };

  const handleStartTimeChange = (value, index) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges[index].startTime = value;
    setTimeRanges(updatedTimeRanges);
  };

  const handleEndTimeChange = (value, index) => {
    const updatedTimeRanges = [...timeRanges];
    updatedTimeRanges[index].endTime = value;
    setTimeRanges(updatedTimeRanges);
  };

  const timeOptions = [];
  for (let hour = 9; hour <= 11; hour++) {
    timeOptions.push(`${hour}:00 am`);
    timeOptions.push(`${hour}:30 am`);
  }
  timeOptions.push("12:00 pm");
  timeOptions.push("12:30 pm");

  for (let hour = 1; hour <= 9; hour++) {
    timeOptions.push(`${hour}:00 pm`);
    timeOptions.push(`${hour}:30 pm`);
  }

  return (
    <div className="relative w-[320px] md:w-[450px] h-[170px] border border-primary rounded-lg md:pr-16 p-4 font-Glacial">
      <div className="flex justify-between">
        <h1 className="text-[16px] md:text-[18px] text-[#474747] flex">
          <span className="text-primary">{t("avalability.from")}&nbsp;</span>
          <span className="text-primary">:</span>&nbsp;
          {format(state1[0]?.startDate, "dd-MM-yyyy")}
        </h1>
        <h1 className="text-[16px] md:text-[18px] text-[#474747]">
          <span className="text-primary">{t("avalability.to")}&nbsp;</span>
          <span className="text-primary">:</span>&nbsp;
          {format(state1[0]?.endDate, "dd-MM-yyyy")}
        </h1>
      </div>
      <div className="max-h-[400px] h-[120px] overflow-y-auto ">
        {timeRanges.map((slot, index) => (
          <div key={index} className="flex items-center gap-4 mt-3">
            <div className="focus:outline-none w-[100px] border px-4 py-2 border-primary rounded-full">
              {slot.startTime}
            </div>
            -
            <div className="focus:outline-none w-[100px] border px-4 py-2 border-primary rounded-full">
              {slot.endTime}
            </div>
          </div>
        ))}
      </div>
      <div
        className="absolute right-5 bottom-5"
        onClick={() => setIsModalOpen(true)}
      >
        <Edit />
      </div>
      {isModalOpen && (
        <Modal
          onClose={() => setIsModalOpen(false)}
          width={1050}
          height={600}
          maxHeight={800}
        >
          <div className="ml-10">
            <Title title={t("avalability.updateAvailable")} />
          </div>
          <div className="flex mx-10 justify-between">
            <div className="mt-10  text-[18px] font-Glacial">
              <h1>{t("avalability.chooseDate")}</h1>
              <div className="flex gap-10 mt-5">
                <div className="flex border border-primary rounded-full">
                  <div className=" py-2 px-5">{t("avalability.from")}</div>
                  <div className="border-l border-primary rounded-full py-2 px-8">
                    {format(state1[0]?.startDate, "dd-MM-yyyy")}
                  </div>
                </div>
                <div className="flex border border-primary rounded-full">
                  <div className=" py-2 px-5">{t("avalability.to")}</div>
                  <div className="border-l border-primary rounded-full py-2 px-8">
                    {format(state1[0]?.endDate, "dd-MM-yyyy")}
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <h1 className="flex items-center justify-between">
                  {t("avalability.chooseTime")}
                  <div className="cursor-pointer" onClick={handleAddTime}>
                    <PlusIcon />
                  </div>
                </h1>
                {timeRanges.length === 0 ? (
                  <div className="text-red-300">
                    {t("avalability.pleaseAdd")}
                  </div>
                ) : (
                  <div className="h-[300px] max-h-[500px] overflow-y-auto">
                    {timeRanges.map((slot, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between gap-4 mt-3"
                      >
                        <div className="flex items-center gap-5">
                          <select
                            placeholder="Start Time"
                            value={slot?.startTime}
                            onChange={(e) =>
                              handleStartTimeChange(e.target.value, index)
                            }
                            className="focus:outline-none w-[140px] border px-4 py-2 border-primary rounded-full"
                          >
                            {timeOptions.map((time, optionIndex) => (
                              <option key={optionIndex} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                          -
                          <select
                            placeholder="End Time"
                            value={slot?.endTime}
                            onChange={(e) =>
                              handleEndTimeChange(e.target.value, index)
                            }
                            className="focus:outline-none w-[140px] border px-4 py-2 border-primary rounded-full"
                          >
                            {timeOptions.map((time, optionIndex) => (
                              <option key={optionIndex} value={time}>
                                {time}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex items-center gap-8">
                          <div
                            className="cursor-pointer"
                            onClick={() => handleDeleteTime(index)}
                          >
                            <DeleteIcon />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="mt-10 flex gap-5 h-[380px]">
              <DateRange
                className="border p-4 rounded-md shadow-md"
                editableDateInputs={true}
                onChange={(item) => setState1([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state1}
                rangeColors={["#FF914D"]}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={handleUpdate}
            className="bg-primary hover:bg-white text-white hover:text-primary hover:border hover:border-primary w-[130px] h-[54px] px-4 rounded-full mx-auto  font-Comfortaa-Medium text-[16px] absolute bottom-10 right-14"
          >
            {t("addNewDish.update")}
          </button>
        </Modal>
      )}
    </div>
  );
};

export default CardAvailable;
