import React from "react";

const FilterSVG = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="15"
        viewBox="0 0 22 15"
        fill="none"
      >
        <path
          d="M1 1H21"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.75 7.25H17.25"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.75 13.5H12.25"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default FilterSVG;
