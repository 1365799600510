import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

const DishPhotos = ({ placeImage }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className=" mt-[38px]">
        <div className="gap-x-4 items-center flex justify-center sm:justify-start">
          <img src="../images/Dishphotos/image.svg" alt="" className="w-6 h-6"/>
          <p className="font-Glacial text-lg ">
            {t("dishDetail.whereYouWillStay")}
          </p>
        </div>
        <div className="pt-[24px] cursor-pointer">
          {placeImage && (
            <Swiper
              slidesPerView={2}
              loop={true}
              spaceBetween={10}
              pagination={{ clickable: true }}
              className="mySwiper"
              
            >
              {placeImage?.map((item) => {
                return (
                  <SwiperSlide>
                    <img
                      className="w-[400px] h-[200px] sm:h-[405px] object-cover"
                      src={item}
                      alt=""
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
      </div>
    </>
  );
};

export default DishPhotos;
