import api from "./Api";

// get user profile
export const getUserProfile = () => {
  return api.get("/profile");
};

// update user profile
export const updateUserProfile = (data) => {
  return api.put("/user-profile", data);
};

// update password
export const updatePassword = (data) => {
  return api.put("/reset-password", data);
};

// switch role
export const switchRole = () => {
  return api.put("/switch-role");
};

export const FAQ = () => {
  return api.get("/faqs");
};

export const profileCompletion = () => {
  return api.get("/profile-completion");
};

export const addBankDetails = (data) => {
  return api.patch("/bank-details", data);
};

export const getHostReviews = (data) => {
  return api.post("/host/get-host-reviews", data);
};

export const getGuestReviews = (data) => {
  return api.post("/host/get-guest-reviews", data);
};

export const postAvailability = (data) => {
  return api.post("/host/availability", data);
};

export const getAvailability = () => {
  return api.get("/host/availability");
};

export const updateAvailability = (data) => {
  return api.put("/host/availability", data);
};
