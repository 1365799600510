import { useTranslation } from "react-i18next";

export function SuperHostCheckbox({ checked, onChange }) {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto  placeholder:text-[#ABABAB] border border-gray-300 p-3 my-[15px] rounded-md w-full">
      <label className="flex justify-between space-x-2 items-center">
        <div className="flex align-middle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="24"
            viewBox="0 0 19 24"
            fill="none"
            className=" "
          >
            <path
              d="M9.12423 13.0447C9.84357 13.0447 10.5559 13.1864 11.2204 13.4616C11.885 13.7369 12.4889 14.1404 12.9975 14.649C13.5062 15.1577 13.9097 15.7615 14.1849 16.4261C14.4602 17.0907 14.6019 17.803 14.6019 18.5223C14.6019 19.2417 14.4602 19.954 14.1849 20.6185C13.9097 21.2831 13.5062 21.887 12.9975 22.3956C12.4889 22.9043 11.885 23.3078 11.2204 23.583C10.5559 23.8583 9.84357 24 9.12423 24C7.67146 24 6.2782 23.4229 5.25094 22.3956C4.22368 21.3684 3.64657 19.9751 3.64657 18.5223C3.64657 17.0696 4.22368 15.6763 5.25094 14.649C6.2782 13.6218 7.67146 13.0447 9.12423 13.0447ZM9.12423 14.6097C8.08654 14.6097 7.09135 15.0219 6.35759 15.7557C5.62384 16.4895 5.21161 17.4846 5.21161 18.5223C5.21161 19.56 5.62384 20.5552 6.35759 21.289C7.09135 22.0227 8.08654 22.435 9.12423 22.435C10.1619 22.435 11.1571 22.0227 11.8909 21.289C12.6246 20.5552 13.0368 19.56 13.0368 18.5223C13.0368 17.4846 12.6246 16.4895 11.8909 15.7557C11.1571 15.0219 10.1619 14.6097 9.12423 14.6097ZM16.6912 6.21336e-06C17.0868 -0.00110764 17.468 0.147573 17.7584 0.416147C18.0487 0.684721 18.2266 1.05327 18.2563 1.44767V6.56538C18.2543 6.83357 18.1835 7.09677 18.0506 7.32972C17.9177 7.56267 17.7271 7.75755 17.4972 7.89567L17.3877 7.95827L9.82068 11.7457C9.62596 11.8417 9.4134 11.8961 9.19651 11.9056C8.97961 11.915 8.76313 11.8792 8.56081 11.8005L8.42779 11.7457L0.860784 7.95827C0.620665 7.83848 0.415379 7.65897 0.26464 7.43698C0.113901 7.21498 0.0227728 6.95795 8.04606e-06 6.69058V1.56505C-0.00125548 1.17079 0.146338 0.790568 0.41328 0.500412C0.680223 0.210257 1.04684 0.0315458 1.43985 6.21336e-06H1.55723H16.6912ZM16.6912 1.56505H1.55723V6.56538L9.12423 10.345L16.6912 6.56538V1.56505Z"
              fill="#FF914D"
            />
          </svg>
          <span className="text-gray-600 text-lg pl-[20px]  font-Glacial">
            {t("homePage.superHost")}
          </span>
        </div>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="form-checkbox bg-white border-gray-400 text-[#FF914D] focus:ring-[#FF914D]  w-4 h-4 mt-[5px] "
        />
      </label>
    </div>
  );
}

export default SuperHostCheckbox;
