import React from "react";

const DownArrow = () => {
  return (
    <>
      <a href="javascript:void(0)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="10"
          viewBox="0 0 17 10"
          fill="none"
        >
          <path
            d="M1 1L6.00002 5.98581L11 1"
            stroke="#FF914D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
    </>
  );
};

export default DownArrow;
