import React from "react";

const ChatIcon = () => {
  return (
    <div>
      <svg
        width="67"
        height="67"
        viewBox="0 0 67 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_dddd_365_3266)">
          <rect x="3" y="3" width="60" height="60" rx="30" fill="white" />
        </g>
        <path
          d="M33 43C38.5228 43 43 38.5228 43 33C43 27.4771 38.5228 23 33 23C27.4771 23 23 27.4771 23 33C23 34.5997 23.3756 36.1116 24.0435 37.4525C24.2209 37.8088 24.28 38.2161 24.1771 38.6006L23.5815 40.8267C23.323 41.793 24.207 42.677 25.1733 42.4185L27.3994 41.8229C27.7839 41.72 28.1912 41.7791 28.5475 41.9565C29.8884 42.6244 31.4003 43 33 43Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
        <path
          d="M29 33H29.009M32.991 33H33M36.991 33H37"
          stroke="#FF914D"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <filter
            id="filter0_dddd_365_3266"
            x="0"
            y="0"
            width="67"
            height="67"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="1" />
            <feGaussianBlur stdDeviation="1.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.901961 0 0 0 0 0.901961 0 0 0 0 0.901961 0 0 0 0.9 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_365_3266"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-1" dy="-1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.9 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_365_3266"
              result="effect2_dropShadow_365_3266"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="-1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.901961 0 0 0 0 0.901961 0 0 0 0 0.901961 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_365_3266"
              result="effect3_dropShadow_365_3266"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-1" dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.901961 0 0 0 0 0.901961 0 0 0 0 0.901961 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="effect3_dropShadow_365_3266"
              result="effect4_dropShadow_365_3266"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect4_dropShadow_365_3266"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default ChatIcon;
