import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";

const ForgotInput = ({ type, placeholder, name, icon, ...rest }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const inputType = type === "password" && passwordVisible ? "text" : type;

  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(true);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
    // onChange(newPassword);
  };

  const validatePassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    const isValid = regex.test(password);
    setValid(isValid);
  };

  return (
    <div className={`form-control`}>
      <div className="relative flex items-center">
        <input
          type={inputType}
          placeholder={placeholder}
          name={name}
         
          className="w-full mx-auto h-[70px]  outline-none rounded-full ps-[104px] pe-20 font-Glacial sm:text-[20px] text-[12px] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5] placeholder:text-[#ABABAB] "
          {...rest}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute right-[24px] text-2xl top-[25px] text-primary cursor-pointer"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? <AiOutlineEye /> : <AiOutlineEye />}
          </button>
        )}
      </div>
      {!valid && (
        <p className="text-red-500 text-xs ml-[80px]">
          Password must contain at least [A-Z,a-z,!@#$%^&*,0-9]
        </p>
      )}
    </div>
  );
};

export default ForgotInput;
