import React from "react";

const Loader = ({ size = 48, children, color = "#FF914D", border = 5 }) => {
  return (
    <div className="flex justify-center items-center gap-2">
      <div
        style={{
          width: size,
          height: size,
          border: `${border}px solid`,
          borderColor: `${color} #0000`,
        }}
        className="spinner-loader "
      ></div>
      {children}
    </div>
  );
};

export default Loader;
