import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../utils/hooks/useAuth";
import { getSocialLinks } from "../services/commonService";

const Footer = () => {
  const lang = localStorage.getItem("i18nextLng");
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const home = () => navigate("/");
  const favorites = () => navigate("/favorites");
  const mybooking = () => navigate("/mybookings");
  const [data, setData] = useState([]);
  const getSocialMediaIcon = (name) => {
    const socialMediaIcons = {
      instagram: "../images/footer/instagram.png",
      facebook: "../images/footer/facebook.png",
      linkedin: "../images/footer/linkedin.png",
      twitter: "../images/footer/twitter.png",
      x: "../images/footer/xlogo.png",
    };

    return socialMediaIcons[name] || "";
  };

  const getSocialLink = async () => {
    try {
      const resp = await getSocialLinks();
      if (resp?.success) {
        setData(resp?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSocialLink();
  }, []);
  // console.log("data", data);
  return (
    <footer className="bg-primary w-full text-white  pt-10 px-3 font-Glacial mt-[220px]">
      <div className="container mx-auto lg:flex ">
        <div className="">
          <img src="../images/footer/footer-logo.png" alt="logo"></img>
          <p className="lg:w-[373px] font-Glacial mt-6 text-[18px]">
            {lang === "en" || lang === "en-US" ? (
              <>
                Connecting local chefs and food lovers with travellers from all
                over the world!
              </>
            ) : (
              <>
                Conectando chefs locales y amantes de la cocina con viajeros de
                todo el mundo
              </>
            )}
          </p>

          <div className="flex gap-x-4 mt-6">
            {data?.map((item) => (
              <Link to={item?.socialLink} target="_blank">
                <img
                  src={getSocialMediaIcon(item.name)}
                  alt={item?.name}
                  className="cursor-pointer"
                />
              </Link>
            ))}
          </div>
          {/* <div className="flex gap-x-4 mt-6">
            {data?.instagram?.length > 0 && (
              <Link to={data?.instagram} target="_blank">
                <img
                  src="../images/footer/instagram.png"
                  alt="icon"
                  className="cursor-pointer"
                />
              </Link>
            )}

            {data?.facebook?.length > 0 && (
              <Link to={data?.facebook} target="_blank">
                <img
                  src="../images/footer/facebook.png"
                  alt="icon"
                  className="cursor-pointer"
                />
              </Link>
            )}

            {data?.linkedIN?.length > 0 && (
              <Link to={data?.linkedIN} target="_blank">
                <img
                  src="../images/footer/linkedin.png"
                  alt="icon"
                  className="cursor-pointer"
                />
              </Link>
            )}
            {data?.twitter?.length > 0 && (
              <Link to={data?.twitter} target="_blank">
                <img
                  src="../images/footer/twitter.png"
                  alt="icon"
                  className="cursor-pointer"
                />
              </Link>
            )}
          </div> */}
        </div>
        <div className="mt-12 lg:ms-[60px]">
          <ul className="mt-6">
            <p className="text-[20px]">
              {t("footer.explore")}
              <p className="flex">
                <span className={`inline-block h-[2px] w-6 bg-[#FFF]`}></span>
              </p>
            </p>
            <li className="font-normal mt-6 text-[18px]">
              <Link to="/">{t("header.menuHome")}</Link>
              <button onClick={home}></button>
            </li>
            {isAuthenticated && (
              <li className="font-normal mt-4 text-[18px">
                <Link to="/favorites">{t("header.menuFavorites")}</Link>
                <button onClick={favorites}></button>
              </li>
            )}
            {isAuthenticated && (
              <li className="font-normal mt-4 text-[18px]">
                <Link to="/mybookings">{t("header.menuMyBooking")}</Link>
                <button onClick={mybooking}></button>
              </li>
            )}

            <li className="font-normal mt-4 text-[18px]">
              <Link to="/contact-us">{t("header.menuContactUs")}</Link>
            </li>
          </ul>
        </div>
        <div className="mt-12 lg:ms-[60px]">
          <ul className="mt-6">
            <p className="text-[20px]">
              {t("footer.aboutUs")}
              <p className="flex">
                <span className={`inline-block h-[2px] w-6 bg-[#FFF]`}></span>
              </p>
            </p>
            <li className="font-normal mt-6 text-[18px]">
              <Link to="/cookies-policy">{t("cookiesPolicy.titleSmall")}</Link>
            </li>
            <li className="font-normal mt-4 text-[18px">
              <Link to="/legal-notice">{t("legalNotice.titleSmall")}</Link>
            </li>{" "}
            <li className="font-normal mt-4 text-[18px">
              <Link to="/terms-condition-guest">{t("footer.termsGuest")}</Link>
            </li>{" "}
            <li className="font-normal mt-4 text-[18px">
              <Link to="/terms-condition-host">{t("footer.termsHost")}</Link>
            </li>
          </ul>
        </div>
        <div className="mt-12 lg:ms-[60px]">
          <ul className="mt-6">
            <p className="text-[20px]">
              {t("footer.contactUS")}
              <p className="flex">
                <span className={`inline-block h-[2px] w-6 bg-[#FFF]`}></span>
              </p>
            </p>
            <li className="font-normal mt-6 text-[18px] flex gap-2">
              <img src="../images/Loginform/mail1.svg" alt="" />:{" "}
              <a href="mailto:contact@mydish4u.com">contact@mydish4u.com</a>
            </li>
            <li className="font-normal mt-4 text-[18px flex gap-2">
              <img src="../images/Loginform/Vector1.svg" alt="" />:{" "}
              <a href="tel:+911234567890">(+34) 635 66 13 82</a>
            </li>{" "}
            <li className="font-normal mt-4 text-[18px flex gap-2">
              <img src="../images/Loginform/location1.svg" alt="" />:{" "}
              <a>
                Silvestre Bello 7 2a, Santa Lucía de Tirajana 35110 Las Palmas
              </a>
            </li>{" "}
            <li className="font-normal mt-4 text-[18px flex gap-2">
              <img src="../images/footer/card_visa.png" alt="card_visa"></img>
              <img
                src="../images/footer/card_master.png"
                alt="card_master"
              ></img>
              <img
                src="../images/footer/card_paypal.png"
                alt="card_paypal"
              ></img>
              <img src="../images/footer/card_amex.png" alt="card_amex"></img>
            </li>{" "}
          </ul>
        </div>
      </div>
      <div>
        <span className="flex justify-center pb-6 pt-4 text-[18px]">
          ©{new Date().getFullYear()} {t("footer.footerText")}{" "}
          {lang === "en" || lang === "en-US" ? (
            <>
              <p>
                <Link to="/privacy-policy"> Privacy Policy</Link>.
              </p>
            </>
          ) : (
            <>
              <p>
                <Link to="/privacy-policy"> Política de privacidad</Link>.
              </p>
            </>
          )}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
