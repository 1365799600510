import React from "react";

const HowIt2 = () => {
  return (
    <div>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29 16C29 18.1379 28.4728 20.2427 27.465 22.1282C26.4572 24.0136 25 25.6214 23.2224 26.8091C21.4448 27.9968 19.4018 28.7279 17.2742 28.9374C15.1467 29.1469 13.0002 28.8286 11.0251 28.0104C9.04999 27.1923 7.30711 25.8997 5.95086 24.2471C4.59462 22.5945 3.66687 20.633 3.24979 18.5362C2.83272 16.4394 2.93919 14.2721 3.55978 12.2263C4.18036 10.1805 5.29591 8.31931 6.80761 6.80761L16 16H29Z"
          fill="#FF914D"
        />
        <circle cx="16" cy="16" r="15.5" stroke="#FF914D" />
      </svg>
    </div>
  );
};

export default HowIt2;
