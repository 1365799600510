import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useDebounce } from "@uidotdev/usehooks";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
const Dropdown = (props) => {
  let {
    placeholderBlack = false,
    isDynamic = false,
    hiddenDropdownArrow = false,
    options,
    labelName,
    startIcon,
    value,
    size,
    className,
    disabled,
    placeholder,
    onChange,
    error,
    errorMessage,
  } = props;
  const defaultClass = ``;
  const optionRef = useRef();
  const outSideRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value ? value : "");
  const [searchTerm, setSearchTerm] = useState("");
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  useEffect(() => {
    value = selectedOption;
  }, [selectedOption]);

  useEffect(() => {
    if (searchTerm?.length > 1) {
      setDynamicOptions([]);
    } else {
      setDynamicOptions([]);
    }
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const filteredOptions = options?.filter((option) =>
    option?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setSearchTerm("");
    onChange(option);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setSelectedOption({
      ...selectedOption,
      label: e.target.value,
    });
  };
  const getButtonSize = () => {
    let sizeClass = "";
    switch (size) {
      case "large":
        sizeClass = classNames(`w-full h-[56px] text-base rounded-lg `);
        break;
      case "medium":
        sizeClass = classNames(`w-full h-[48px]  text-[14px] rounded-lg `);
        break;
      case "small":
        sizeClass = classNames(`w-full h-[40px] text-[14px] rounded-[5px] `);
        break;
      default:
        sizeClass = classNames(`w-full h-[48px]  text-[14px] rounded-lg`);
        break;
    }
    return sizeClass;
  };

  const classes = classNames(
    defaultClass,
    getButtonSize(),
    className,
    disabled ? "opacity-50 cursor-not-allowed border border-lightGray" : ""
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        outSideRef.current &&
        !outSideRef.current.contains(event.target) &&
        optionRef.current &&
        !optionRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div className="pt-[5px]">
        <div>
          {labelName && (
            <label
              className={`text-[12px]  ${
                error ? "text-accentRed" : "text-[#474747]"
              }`}
            >
              {labelName}
            </label>
          )}
          {isDynamic ? (
            <div className=" font-slussen w-full mt-2">
              <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className={`${classes}  w-full outline-none px-5  ${
                  error ? "border border-accentRed" : "border border-dark"
                }  active:border-accessiblePurple focus:border-accessiblePurple bg-white`}
                aria-haspopup="listbox"
                aria-expanded={isOpen}
                aria-labelledby="listbox-label"
              >
                <input
                  type="text"
                  placeholder={placeholder}
                  value={selectedOption?.label}
                  onChange={handleSearchChange}
                  className="w-full cursor-pointer relative h-[50px] outline-none focus:ring-primary focus:border-primary   ps-[72px] pe-[72px] font-Glacial  text-[18px]  placeholder:text-[#ABABAB]  block truncate text-[#474747] font-normal"
                />

                <span className="absolute inset-y-0 right-0 flex items-center pr-[22px]">
                  {isOpen && dynamicOptions?.length > 0 ? (
                    <AiOutlineUp />
                  ) : (
                    <AiOutlineDown />
                  )}
                </span>
              </button>
              {isOpen && dynamicOptions?.length > 0 && (
                <div
                  ref={outSideRef}
                  className="absolute mt-[12px] z-10 bg-white w-full  focus:outline-none"
                >
                  <ul
                    ref={optionRef}
                    className="absolute textarea mt-[6px] z-10 border border-dark w-full rounded-md bg-white py-3 text-base shadow-lg focus:outline-none sm:text-sm"
                  >
                    {dynamicOptions.map((option) => {
                      if (option?.label === selectedOption?.label) {
                        return (
                          <li
                            key={option.value}
                            className="cursor-pointer text-[#474747] text-left font-normal text-[16px] mx-3 h-[48px]  bg-[#F5F6FE] select-none  p-[12px]"
                            onClick={() => handleOptionClick(option)}
                          >
                            {option.label}
                          </li>
                        );
                      } else {
                        return (
                          <li
                            key={option.value}
                            className=" cursor-pointer text-[#474747] text-left font-normal text-[16px] mx-3 h-[48px] rounded-md hover:bg-[#F5F6FE] select-none  p-[12px]"
                            onClick={() => handleOptionClick(option)}
                          >
                            {option.label}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="relative font-Glacial w-full mt-2">
              <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className={`${classes} w-full h-[50px] outline-none    ${
                  error ? " border-accentRed" : " border-dark"
                }  active:border-accessiblePurple focus:border-accessiblePurple  bg-white rounded-xl relative ps-[20px]  placeholder:text-[#ABABAB]`}
                aria-haspopup="listbox"
                aria-expanded={isOpen}
                aria-labelledby="listbox-label"
              >
                <div className="relative flex">
                  <input
                    type="text"
                    placeholder={placeholder}
                    value={selectedOption?.label}
                    onChange={handleSearchChange}
                    className={` ${
                      placeholderBlack ? "placeholder:text-black" : ""
                    }  rounded-xl pl-14 w-full cursor-pointer -ml-[2px] h-[50px] focus:ring-primary focus:border-primary border border-gray-300  focus:border block truncate text-[18px] font-Glacial text-[#474747] relative font-normal outline-none`}
                  />
                  {!hiddenDropdownArrow && (
                    <span className="absolute right-9 top-5 flex items-center ">
                      {isOpen ? (
                        <AiOutlineUp style={{ color: "#FF914D" }} />
                      ) : (
                        <AiOutlineDown style={{ color: "#FF914D" }} />
                      )}
                    </span>
                  )}
                  <div className="pt-[0px] absolute   mt-1  text-[18px] text-primary  only: w-[44px] h-[44px] bg-white border-r flex items-center justify-center rounded-s-xl">
                    {startIcon && <span>{startIcon}</span>}
                  </div>
                </div>
              </button>

              {isOpen && (
                <div
                  ref={outSideRef}
                  className="absolute mt-[12px] z-10 bg-white w-full focus:outline-none"
                >
                  <ul
                    ref={optionRef}
                    className="absolute textarea mt-[0px] text-[#474747] z-10 border  border-dark w-full overflow-auto rounded-md bg-white py-3 text-base shadow-lg  focus:outline-none sm:text-sm"
                    role="listbox"
                    aria-labelledby="listbox-label"
                    tabIndex={-1}
                  >
                    {filteredOptions?.length > 0 ? (
                      filteredOptions.map((option) => {
                        if (option?.label === selectedOption?.label) {
                          return (
                            <li
                              key={option.value}
                              // style={{ backgroundColor: option.label === placeholder ? '#f7efea' : '#fff' }}
                              className={`cursor-pointer text-[#474747] text-left font-normal text-[16px] mx-3 h-[48px] rounded-md ${
                                option.label === placeholder
                                  ? "bg-[#f7efea]"
                                  : ""
                              } bg-[#f7efea] select-none  p-[12px]`}
                              onClick={() => handleOptionClick(option)}
                            >
                              {option.label}
                            </li>
                          );
                        } else {
                          return (
                            <li
                              key={option.value}
                              // style={{ backgroundColor: option.label === placeholder ? '#f7efea' : '#fff' }}
                              className={` cursor-pointer text-[#474747] text-left font-normal text-[16px] mx-3 h-[48px] ${
                                option.label === placeholder
                                  ? "bg-[#f7efea]"
                                  : ""
                              } rounded-md hover:bg-[#f7efea] select-none  p-[12px]`}
                              onClick={() => handleOptionClick(option)}
                            >
                              {option.label}
                            </li>
                          );
                        }
                      })
                    ) : (
                      <li className=" cursor-pointer text-[#474747] text-left font-normal text-[16px] mx-3 h-[48px] rounded-md hover:bg-[#F5F6FE] select-none  p-[12px]">
                        No Options Found
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          )}
          {error && (
            <span className="text-accentRed mt-8 text-[12px]">
              {errorMessage}
            </span>
          )}
        </div>
      </div>
    </>
  );
};
export default Dropdown;
