import React from "react";

const MinusSVG = () => {
  return (
    <div>
      <svg
        width="18"
        height="2"
        viewBox="0 0 18 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1H17" stroke="#FF914D" stroke-linecap="round" />
      </svg>
    </div>
  );
};

export default MinusSVG;
