import React from "react";

const UploadBtn = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M14.4999 7.30176C16.4573 7.31266 17.5174 7.39946 18.209 8.091C18.9998 8.88183 18.9998 10.1546 18.9998 12.7001V13.6001C18.9998 16.1457 18.9998 17.4185 18.209 18.2092C17.4182 19.0001 16.1454 19.0001 13.5999 19.0001H6.39995C3.85438 19.0001 2.58161 19.0001 1.7908 18.2092C1 17.4185 1 16.1457 1 13.6001V12.7001C1 10.1546 1 8.88183 1.7908 8.091C2.48234 7.39946 3.54245 7.31266 5.49995 7.30176"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M9.99978 12.6999V1M9.99978 1L12.6998 4.14997M9.99978 1L7.2998 4.14997"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default UploadBtn;
