import React from "react";

const Password = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          d="M1 17.8001C1 14.406 1 12.709 2.05442 11.6545C3.10883 10.6001 4.80588 10.6001 8.2 10.6001H17.8C21.1941 10.6001 22.8911 10.6001 23.9456 11.6545C25 12.709 25 14.406 25 17.8001C25 21.1942 25 22.8912 23.9456 23.9457C22.8911 25.0001 21.1941 25.0001 17.8 25.0001H8.2C4.80588 25.0001 3.10883 25.0001 2.05442 23.9457C1 22.8912 1 21.1942 1 17.8001Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
        <path
          d="M5.7998 10.6V8.2C5.7998 4.22355 9.02335 1 12.9998 1C16.3548 1 19.1737 3.29459 19.973 6.4"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M13 15.3999V20.1999"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};

export default Password;
