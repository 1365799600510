import React from "react";

const DishPrice = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M3.45563 13.7232C2.06466 12.3323 1.36916 11.6367 1.11038 10.7344C0.851583 9.83206 1.07275 8.87365 1.51508 6.95688L1.77017 5.85151C2.1423 4.23893 2.32837 3.43263 2.8805 2.8805C3.43263 2.32837 4.23892 2.1423 5.85151 1.77017L6.95688 1.51508C8.87365 1.07275 9.83206 0.851583 10.7344 1.11038C11.6367 1.36916 12.3323 2.06466 13.7232 3.45563L15.3699 5.10231C17.79 7.5224 19 8.73244 19 10.2361C19 11.7397 17.79 12.9498 15.3699 15.3699C12.9498 17.79 11.7397 19 10.2361 19C8.73244 19 7.5224 17.79 5.10231 15.3699L3.45563 13.7232Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
        <path
          d="M13.0505 13.05C13.5777 12.5228 13.6489 11.7393 13.2096 11.2999C12.7702 10.8606 11.9867 10.9318 11.4595 11.459C10.9324 11.9862 10.1488 12.0575 9.70944 11.6181C9.27006 11.1787 9.34134 10.3952 9.86856 9.86807M13.0505 13.05L13.3687 13.3682M13.0505 13.05C12.6896 13.4109 12.2086 13.5582 11.8001 13.4742M9.86856 9.86807L9.55032 9.54983M9.86856 9.86807C10.1666 9.5699 10.5467 9.41762 10.9001 9.42095"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M8.21926 8.46322C8.9222 7.76028 8.9222 6.62058 8.21926 5.91764C7.51631 5.21469 6.37661 5.21469 5.67367 5.91764C4.97073 6.62058 4.97073 7.76028 5.67367 8.46322C6.37661 9.16617 7.51631 9.16617 8.21926 8.46322Z"
          stroke="#FF914D"
          stroke-width="1.5"
        />
      </svg>
    </>
  );
};

export default DishPrice;
