import React from "react";
import { useTranslation } from "react-i18next";
import { CiSearch } from "react-icons/ci";

const Searchbar = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <div className="relative">
      <div className="absolute text-[40px] text-primary p-3">
        <CiSearch />
      </div>
      <input
        className="xl:w-[396px] focus:ring-2 focus:ring-primary border-0 w-[300px] h-[60px] outline-none rounded-full ps-[72px] pe-[72px] font-Glacial  text-[18px] shadow-md shadow-[#E6E6E6E5] placeholder:text-[#ABABAB]"
        type="text"
        value={value}
        onChange={onChange}
        placeholder={t("header.searchHere")}
      />
    </div>
  );
};
export default Searchbar;
