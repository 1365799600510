import React from "react";

const Edit = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        className="cursor-pointer"
      >
        <path
          d="M8.11068 1.19849H6.53053C2.58015 1.19849 1 2.77864 1 6.72902V11.4695C1 15.4199 2.58015 17 6.53053 17H11.271C15.2214 17 16.8015 15.4199 16.8015 11.4695V9.88932"
          stroke="#FF914D"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0919 2.00437L5.8661 8.23016C5.62907 8.46718 5.39205 8.93333 5.34465 9.27306L5.00491 11.6512C4.8785 12.5124 5.48686 13.1128 6.34804 12.9943L8.72617 12.6546C9.058 12.6072 9.52415 12.3702 9.76907 12.1331L15.9949 5.90734C17.0694 4.83284 17.575 3.58452 15.9949 2.00437C14.4147 0.424216 13.1664 0.929864 12.0919 2.00437Z"
          stroke="#FF914D"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.1992 2.89697C11.7286 4.78525 13.206 6.2627 15.1022 6.79995"
          stroke="#FF914D"
          stroke-width="1.2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Edit;
