import React from "react";
import DBtn from "./DBtn";

const ChooseTimeCard = () => {
  return (
    <div>
      <div className="text-lg font-Glacial">
        <div className="pt-[40px]">
          <span>Choose Time</span>
        </div>
        <div className="flex justify-between w-1/3 pt-[24px]">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div>
                <DBtn
                  variant="outline"
                  size="small"
                  onClick={(e) => console.log("button click")}
                  startIcon=""
                >
                  9:00am
                </DBtn>
              </div>
              <span className="px-[12px]">-</span>
              <div>
                <DBtn
                  variant="outline"
                  size="small"
                  onClick={(e) => console.log("button click")}
                  startIcon=""
                >
                  9:00am
                </DBtn>
              </div>
            </div>
            <div>
              <div className="pl-[46px]">
                <a href="javascript:void(0)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M6.89152 14C6.56677 13.8872 6.44712 13.6582 6.45054 13.3197C6.45738 11.443 6.45396 9.56632 6.45738 7.68961C6.45738 7.56997 6.43003 7.54262 6.31039 7.54262C4.43368 7.54604 2.55697 7.54262 0.680264 7.54946C0.341841 7.54946 0.112808 7.43323 0 7.10848C0 7.0367 0 6.96149 0 6.8897C0.112808 6.56496 0.341841 6.44531 0.680264 6.44873C2.55355 6.45557 4.42684 6.45215 6.30013 6.45557C6.43687 6.45557 6.45738 6.41796 6.45738 6.29148C6.45396 4.39426 6.45396 2.50046 6.45396 0.603247C6.45396 0.312682 6.5907 0.104159 6.82315 0.0289535C7.16499 -0.0838541 7.51367 0.145179 7.54443 0.504113C7.54785 0.555389 7.54785 0.603247 7.54785 0.654523C7.54785 2.54149 7.54785 4.42845 7.54443 6.31541C7.54443 6.42822 7.57178 6.45557 7.68459 6.45557C9.58864 6.45215 11.4927 6.45215 13.4002 6.45215C13.6805 6.45215 13.889 6.58547 13.971 6.8145C14.0907 7.15292 13.8651 7.50844 13.5061 7.54262C13.4514 7.54946 13.3968 7.54604 13.3421 7.54604C11.4654 7.54604 9.58864 7.54604 7.70852 7.54262C7.58204 7.54262 7.54443 7.56313 7.54443 7.69987C7.55127 9.57316 7.54443 11.4464 7.55127 13.3197C7.55127 13.6582 7.43504 13.8872 7.11029 14C7.03851 14 6.9633 14 6.89152 14Z"
                      fill="#FF914D"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="pl-[30px]">
              <a href="javascript:void(0)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                >
                  <path
                    d="M1.94118 4.76471V15.1176C1.94118 16.1573 2.78393 17 3.82353 17H11.3529C12.3926 17 13.2353 16.1573 13.2353 15.1176V4.76471M1.94118 4.76471H1M1.94118 4.76471H3.82353M13.2353 4.76471H14.1765M13.2353 4.76471H11.3529M3.82353 4.76471V2.88235C3.82353 1.84276 4.66629 1 5.70588 1H9.47059C10.5102 1 11.3529 1.84276 11.3529 2.88235V4.76471M3.82353 4.76471H11.3529M5.70588 8.52941V13.2353M9.47059 8.52941V13.2353"
                    stroke="#FF914D"
                    stroke-width="0.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="flex justify-between w-1/3 pt-[24px]">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div>
                <DBtn
                  variant="outline"
                  size="small"
                  onClick={(e) => console.log("button click")}
                  startIcon=""
                >
                  9:00am
                </DBtn>
              </div>
              <span className="px-[12px]">-</span>
              <div>
                <DBtn
                  variant="outline"
                  size="small"
                  onClick={(e) => console.log("button click")}
                  startIcon=""
                >
                  9:00am
                </DBtn>
              </div>
            </div>
            <div>
              <div className="pl-[46px]">
                <a href="javascript:void(0)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M6.89152 14C6.56677 13.8872 6.44712 13.6582 6.45054 13.3197C6.45738 11.443 6.45396 9.56632 6.45738 7.68961C6.45738 7.56997 6.43003 7.54262 6.31039 7.54262C4.43368 7.54604 2.55697 7.54262 0.680264 7.54946C0.341841 7.54946 0.112808 7.43323 0 7.10848C0 7.0367 0 6.96149 0 6.8897C0.112808 6.56496 0.341841 6.44531 0.680264 6.44873C2.55355 6.45557 4.42684 6.45215 6.30013 6.45557C6.43687 6.45557 6.45738 6.41796 6.45738 6.29148C6.45396 4.39426 6.45396 2.50046 6.45396 0.603247C6.45396 0.312682 6.5907 0.104159 6.82315 0.0289535C7.16499 -0.0838541 7.51367 0.145179 7.54443 0.504113C7.54785 0.555389 7.54785 0.603247 7.54785 0.654523C7.54785 2.54149 7.54785 4.42845 7.54443 6.31541C7.54443 6.42822 7.57178 6.45557 7.68459 6.45557C9.58864 6.45215 11.4927 6.45215 13.4002 6.45215C13.6805 6.45215 13.889 6.58547 13.971 6.8145C14.0907 7.15292 13.8651 7.50844 13.5061 7.54262C13.4514 7.54946 13.3968 7.54604 13.3421 7.54604C11.4654 7.54604 9.58864 7.54604 7.70852 7.54262C7.58204 7.54262 7.54443 7.56313 7.54443 7.69987C7.55127 9.57316 7.54443 11.4464 7.55127 13.3197C7.55127 13.6582 7.43504 13.8872 7.11029 14C7.03851 14 6.9633 14 6.89152 14Z"
                      fill="#FF914D"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="pl-[30px]">
              <a href="javascript:void(0)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                >
                  <path
                    d="M1.94118 4.76471V15.1176C1.94118 16.1573 2.78393 17 3.82353 17H11.3529C12.3926 17 13.2353 16.1573 13.2353 15.1176V4.76471M1.94118 4.76471H1M1.94118 4.76471H3.82353M13.2353 4.76471H14.1765M13.2353 4.76471H11.3529M3.82353 4.76471V2.88235C3.82353 1.84276 4.66629 1 5.70588 1H9.47059C10.5102 1 11.3529 1.84276 11.3529 2.88235V4.76471M3.82353 4.76471H11.3529M5.70588 8.52941V13.2353M9.47059 8.52941V13.2353"
                    stroke="#FF914D"
                    stroke-width="0.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseTimeCard;
