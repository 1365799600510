import { combineReducers } from "redux";
import auth from "./slices/authSlice";
import noOfDays from "./slices/noOfDaysSlice";
import chat from "./slices/chatSlice";
import search from "./slices/searchSlice";
import myBooking from "./slices/myBookingSlice";
import isUnread from "./slices/unreadMessagesSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    noOfDays,
    chat,
    search,
    myBooking,
    isUnread,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
