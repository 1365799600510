import React, { useEffect, useRef, useState } from "react";
import { getUserProfile, updateUserProfile } from "../services/profileService";
import { uploadSingleImage } from "../services/commonService";
import Title from "../components/Title";
import { format } from "date-fns";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { MdOutlineFileUpload } from "react-icons/md";
import { useTranslation } from "react-i18next";

const ProfileEditPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMountLocation = useRef(null);
  // console.log("isMountLocation", isMountLocation);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [errors, setErrors] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [profileDetails, setProfileDetails] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    dob: "",
    userBio: "",
    profileImage: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    },
    translations: {
      en: {
        firstName: "",
        lastName: "",
        userBio: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          country: "",
          postalCode: "",
        },
      },
    },
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await getUserProfile();

      // console.log("resp.data", resp.data);
      if (resp.success) {
        setProfileDetails(resp?.data);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!isMountLocation.current) {
      navigator.geolocation.getCurrentPosition(success);
      isMountLocation.current = true;
      // console.log("location fetching...");
    }
    // eslint-disable-next-line
  }, []);

  function success(position) {
    const lati = position.coords.latitude;
    const long = position.coords.longitude;

    // console.log("Latitude long:", lati, long);
    // setProfileDetails({
    //   ...profileDetails,
    //   lati: lati,
    //   long: long,
    // });

    localStorage.setItem("lati", lati);
    localStorage.setItem("long", long);
  }

  const lati = localStorage.getItem("lati");
  const long = localStorage.getItem("long");
  // console.log("Latitude:", lati, long);

  // const handleInputChange = async (e) => {
  //   const { name, value, files } = e.target;
  //   if (files?.length > 0) {
  //     const image = files[files.length - 1];
  //     const imageData = new FormData();
  //     imageData.append("image", image);
  //     try {
  //       const resp = await uploadSingleImage(imageData);
  //       if (resp?.success) {
  //         setProfileDetails({
  //           ...profileDetails,
  //           [name]: resp?.data,
  //         });
  //       } else {
  //         setProfileDetails({
  //           ...profileDetails,
  //           [name]: "",
  //         });
  //       }
  //     } catch (err) {
  //       console.log("err", err);
  //     } finally {
  //       console.log("sign up done");
  //     }
  //   } else if (value) {
  //     setProfileDetails({
  //       ...profileDetails,
  //       [name]: value,
  //     });
  //   }
  //   console.log("profileDetails::", profileDetails);
  // };

  const dobDate = profileDetails?.dob ? new Date(profileDetails.dob) : null;
  const formattedDate = dobDate ? format(dobDate, "yyyy-MM-dd") : "";

  const handleImageChange = async (event) => {
    const newImage = event.target.files[0];
    console.log("newImage", newImage);

    if (newImage) {
      try {
        const imageData = new FormData();
        imageData.append("image", newImage);
        const uploadImage = await uploadSingleImage(imageData);

        setProfileDetails({
          ...profileDetails,
          profileImage: uploadImage?.data,
        });

        console.log("uploadImage", uploadImage);
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    let uploadImage;
    try {
      let payload = {
        firstName: profileDetails?.firstName,
        lastName: profileDetails?.lastName,
        mobile: profileDetails?.mobile,
        dob: profileDetails?.dob,
        userBio: profileDetails?.userBio,
        profileImage: profileDetails?.profileImage,
        location: {
          type: "Point",
          coordinates: [parseFloat(long), parseFloat(lati)],
        },
        address: {
          addressLine1: profileDetails?.address?.addressLine1,
          addressLine2: profileDetails?.address?.addressLine2,
          city: profileDetails?.address?.city,
          state: profileDetails?.address?.state,
          country: profileDetails?.address?.country,
          postalCode: profileDetails?.address?.postalCode,
        },
        translations: {
          en: {
            firstName: profileDetails?.translations?.en?.firstName,
            lastName: profileDetails?.translations?.en?.lastName,
            userBio: profileDetails?.translations?.en?.userBio,
            address: {
              addressLine1:
                profileDetails?.translations?.en?.address?.addressLine1,
              addressLine2:
                profileDetails?.translations?.en?.address?.addressLine2,
              city: profileDetails?.translations?.en?.address?.city,
              state: profileDetails?.translations?.en?.address?.state,
              country: profileDetails?.translations?.en?.address?.country,
            },
          },
        },
      };

      if (typeof profileDetails?.profileImage !== "string") {
        try {
          const imageData = new FormData();
          imageData.append("image", profileDetails?.profileImage);
          uploadImage = await uploadSingleImage(imageData);
          // profileDetails?.profileImage = uploadImage?.data
          console.log("uploadImage", uploadImage);
        } catch (err) {
          console.log("err", err);
        }
      } else {
        payload.profileImage = profileDetails?.profileImage;
      }
      console.log("payload", payload);
      const resp = await updateUserProfile(payload);
      console.log("resp", resp);
      if (resp?.success) {
        navigate("/profile");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoadingBtn(false);
    }
  };

  return (
    <div>
      <Header />
      <Title title={t("profilePage.editProfile")} />
      {loading ? (
        <div className="flex justify-center items-center m-auto h-[50vh]">
          <Loader />
        </div>
      ) : (
        <>
          <form className="container mx-auto mt-10">
            <div className="container mx-auto w-full rounded-[20px] px-6  overflow-y-auto h-[500px] md:h-auto overflow-x-hidden">
              <div className="border border-gray-300 p-[15px] rounded-2xl">
                <div className="items-center">
                  <p className="text-[18px] font-Glacial Indifference text-[#111] text-center">
                    {t("profilePage.profileImg")}
                    <p className="-mt-5 -ml-20">
                      <span
                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                      ></span>
                    </p>
                  </p>
                </div>
                <div className="flex  justify-center items-center p-4">
                  <div
                    className="relative"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <img
                      src={profileDetails?.profileImage}
                      className={`w-52 h-52 rounded-full hover:opacity-80 ${
                        isHovered ? "opacity-80" : ""
                      }`}
                      alt="Profile"
                    />
                    <div
                      className={`absolute top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 transition-opacity duration-300 ${
                        isHovered ? "block" : "hidden"
                      } `}
                    >
                      <label htmlFor="fileInput" className="cursor-pointer">
                        <MdOutlineFileUpload size={40} />
                      </label>
                      <input
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="lg:flex items-center justify-center gap-2">
                  <div>
                    <div>
                      <div className="relative flex items-center py-2 ">
                        <div className="absolute text-2xl text-primary only: w-[50px] h-[50px] bg-transparent    border-r ">
                          <img
                            src="../images/Signupform/cake.svg"
                            alt=""
                            className="p-2"
                          />
                        </div>
                        <input
                          name="dob"
                          className=" w-full lg:w-[300px] mx-auto h-[50px] border-0 outline-none transition duration-200 rounded-xl ps-[60px] font-Glacial sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                          type="date"
                          value={formattedDate}
                          placeholder="Enter your DOB"
                          onChange={(e) => {
                            setProfileDetails({
                              ...profileDetails,
                              dob: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {errors.dob && profileDetails?.dob === "" && (
                        <div className="text-red-300 font-Glacial ml-6 text-lg">
                          {errors.dob}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {/* email */}
                    <div>
                      <div className="relative flex items-center py-2 ">
                        <div className="absolute text-2xl text-primary  only: w-[50px] h-[50px] bg-transparent  border-r">
                          <img
                            src="../images/Loginform/maile.svg"
                            alt=""
                            className="p-3"
                          />
                        </div>
                        <input
                          name="email"
                          className="w-full lg:w-[300px] mx-auto h-[50px] border-0 outline-none transition duration-200 rounded-xl ps-[60px] pe-4 font-Glacial bg-[#faf9f8] sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                          type="email"
                          disabled
                          value={profileDetails?.email}
                          // placeholder="Enter your email *"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="relative flex justify-center items-center py-2 ">
                      <div className="left-0 absolute text-2xl text-primary  only: w-[50px] h-[50px] bg-transparent  border-r">
                        <img
                          src="../images/Signupform/call.svg"
                          alt=""
                          className="p-3 px-3"
                        />
                      </div>
                      <input
                        name="mobile"
                        className="w-full lg:w-[300px]  h-[50px] border-0 outline-none rounded-xl transition duration-200 ps-[60px] pe-4 font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="mobile"
                        maxlength="13"
                        placeholder="Enter your Mobile Number *"
                        value={profileDetails?.mobile}
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            mobile: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {errors.mobile && profileDetails?.mobile === "" && (
                      <div className="text-red-300 font-Glacial ml-64 text-lg">
                        {errors.mobile}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className="lg:flex gap-x-5  ">
                    {/* First name */}
                    <div className="lg:w-1/3">
                      <div className="border-[1px] p-[15px] rounded-[16px]  border-gray-300">
                        <div>
                          <p className="text-[18px] font-Glacial Indifference text-[#111]">
                            {t("profilePage.firstName")}
                          </p>
                          <p className="flex">
                            <span
                              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                            ></span>
                          </p>
                        </div>
                        <div className="pt-[10px] pl-[19px]">
                          <span className="text-[18px] font-Glacial Indifference text-[#474747]">
                            {t("spanish")}
                          </span>
                        </div>
                        <div className="relative flex items-center py-2">
                          <input
                            name="firstName"
                            className="w-full border-0 mx-auto h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20 font-Glacial sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                            type="text"
                            placeholder={t("homePage.enterFirstname")}
                            value={profileDetails?.firstName}
                            onChange={(e) => {
                              setProfileDetails({
                                ...profileDetails,
                                firstName: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="pt-[10px] pl-[19px]">
                          <span className="text-[18px] font-Glacial Indifference text-[#474747]">
                            {t("english")}
                          </span>
                        </div>
                        <div className="relative flex items-center py-2">
                          <input
                            name="translations.en.firstName"
                            className="w-full border-0 mx-auto h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20 font-Glacial sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                            type="text"
                            placeholder={t("homePage.enterFirstname")}
                            value={profileDetails?.translations?.en?.firstName}
                            onChange={(e) =>
                              setProfileDetails({
                                ...profileDetails,
                                translations: {
                                  ...profileDetails?.translations,
                                  en: {
                                    ...profileDetails?.translations?.en,
                                    firstName: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                        </div>
                      </div>
                      {errors.firstName && profileDetails?.firstName === "" && (
                        <div className="text-red-300 font-Glacial ml-6 text-lg">
                          {errors.firstName}
                        </div>
                      )}
                    </div>

                    <div className="lg:w-1/3">
                      <div className="gap-x-5 ">
                        {/* last name */}
                        <div>
                          <div className="border-[1px] p-[15px] rounded-[16px]  border-gray-300">
                            <div>
                              <p
                                className="text-[18px] font-Glacial Indifference
                               text-[#111]"
                              >
                                {t("profilePage.lastName")}
                              </p>
                              <p className="flex">
                                <span
                                  className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                ></span>
                              </p>
                            </div>
                            <div className="pt-[10px] pl-[19px]">
                              <span className="text-[18px] font-Glacial Indifference text-[#474747]">
                                {t("spanish")}
                              </span>
                            </div>
                            <div className="relative flex items-center py-2">
                              <input
                                name="lastName"
                                className="w-full mx-auto border-0 h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20    font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                type="text"
                                placeholder={t("homePage.enterLastname")}
                                value={profileDetails?.lastName}
                                onChange={(e) => {
                                  setProfileDetails({
                                    ...profileDetails,
                                    lastName: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="pt-[10px] pl-[19px]">
                              <span
                                className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                              >
                                {t("english")}
                              </span>
                            </div>
                            <div className="relative flex items-center py-2">
                              <input
                                name="translations.en.lastName"
                                className="w-full mx-auto border-0 h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20    font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                type="text"
                                placeholder={t("homePage.enterLastname")}
                                value={
                                  profileDetails?.translations?.en?.lastName
                                }
                                onChange={(e) => {
                                  setProfileDetails({
                                    ...profileDetails,
                                    translations: {
                                      ...profileDetails?.translations,
                                      en: {
                                        ...profileDetails?.translations?.en,
                                        lastName: e.target.value,
                                      },
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {errors.lastName &&
                            profileDetails?.lastName === "" && (
                              <div className="text-red-300 font-Glacial ml-6 text-lg">
                                {errors.lastName}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-1/3">
                      {/* userBio */}
                      <div className="border-[1px] p-[15px] rounded-[16px] border-gray-300 ">
                        <div>
                          <p
                            className="text-[18px] font-Glacial Indifference
                 text-[#111]"
                          >
                            {t("profilePage.bioDetail")}
                          </p>
                          <p className="flex">
                            <span
                              className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                            ></span>
                          </p>
                        </div>
                        <div className="pt-[10px] pl-[19px]">
                          <span
                            className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                          >
                            {t("spanish")}
                          </span>
                        </div>
                        <div className="relative flex items-center py-2">
                          <input
                            name="userBio"
                            className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                            type="text"
                            placeholder={t("profilePage.enterBioDetail")}
                            value={profileDetails?.userBio}
                            onChange={(e) => {
                              setProfileDetails({
                                ...profileDetails,
                                userBio: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="pt-[10px] pl-[19px]">
                          <span
                            className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                          >
                            {t("english")}
                          </span>
                        </div>
                        <div className="relative flex items-center py-2">
                          <input
                            name="translations.en.userBio"
                            className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                            type="text"
                            placeholder={t("profilePage.enterBioDetail")}
                            value={profileDetails?.translations?.en?.userBio}
                            onChange={(e) => {
                              setProfileDetails({
                                ...profileDetails,
                                translations: {
                                  ...profileDetails?.translations,
                                  en: {
                                    ...profileDetails?.translations?.en,
                                    userBio: e.target.value,
                                  },
                                },
                              });
                            }}
                          />
                        </div>
                        {errors.userBio && profileDetails?.userBio === "" && (
                          <div className="text-red-300 font-Glacial ml-6 text-lg">
                            {errors.userBio}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-[1px] p-[15px] rounded-[16px] border-gray-300 mt-[15px]  ">
                <div className="lg:flex gap-x-5">
                  <div className="lg:w-1/2 border border-gray-300 p-3 rounded-2xl">
                    <div>
                      <p className="text-[18px] font-Glacial Indifference   text-[#111]">
                        {t("profilePage.addressLine1")}
                      </p>
                      <p className="flex">
                        <span
                          className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                        ></span>
                      </p>
                    </div>
                    <div className="pt-[10px] pl-[19px]">
                      <span className="text-[18px] font-Glacial Indifference  text-[#474747]">
                        {t("spanish")}
                      </span>
                    </div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="addressLine1"
                        className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        placeholder={t("profilePage.addressLine1")}
                        value={profileDetails?.address?.addressLine1}
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            address: {
                              ...profileDetails?.address,
                              addressLine1: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="pt-[10px] pl-[19px]">
                      <span
                        className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                      >
                        {t("english")}
                      </span>
                    </div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="addressLine1"
                        className="w-full mx-auto h-[50px]  border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        placeholder={t("profilePage.addressLine1")}
                        value={
                          profileDetails?.translations?.en?.address
                            ?.addressLine1
                        }
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            translations: {
                              ...profileDetails?.translations,
                              en: {
                                ...profileDetails?.translations?.en,
                                address: {
                                  ...profileDetails?.translations?.en?.address,
                                  addressLine1: e.target.value,
                                },
                              },
                            },
                          });
                        }}
                      />
                    </div>
                    {errors?.addressLine1 &&
                      profileDetails?.addressLine1 === "" && (
                        <div className="text-red-300 font-Glacial ml-6 text-lg">
                          {errors?.addressLine1}
                        </div>
                      )}
                  </div>

                  {/* Address-Line-2 */}
                  <div className="lg:w-1/2 border border-gray-300 p-3 rounded-2xl">
                    <div>
                      <p
                        className="text-[18px] font-Glacial Indifference
                 text-[#111]"
                      >
                        {t("profilePage.addressLine2")}
                      </p>
                      <p className="flex">
                        <span
                          className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                        ></span>
                      </p>
                    </div>
                    <div className="pt-[10px] pl-[19px]">
                      <span
                        className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                      >
                        {t("spanish")}
                      </span>
                    </div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="addressLine2"
                        className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20 transition duration-200   font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        placeholder={t("profilePage.addressLine2")}
                        value={profileDetails?.address?.addressLine2}
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            address: {
                              ...profileDetails?.address,
                              addressLine2: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="pt-[10px] pl-[19px]">
                      <span
                        className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                      >
                        {t("english")}
                      </span>
                    </div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="addressLine2"
                        className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        placeholder={t("profilePage.addressLine2")}
                        value={
                          profileDetails?.translations?.en?.address
                            ?.addressLine2
                        }
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            translations: {
                              ...profileDetails?.translations,
                              en: {
                                ...profileDetails?.translations?.en,
                                address: {
                                  ...profileDetails?.translations?.en?.address,
                                  addressLine2: e.target.value,
                                },
                              },
                            },
                          });
                        }}
                      />
                    </div>
                    {errors.addressLine2 &&
                      profileDetails?.addressLine2 === "" && (
                        <div className="text-red-300 font-Glacial ml-6 text-lg">
                          {errors.addressLine2}
                        </div>
                      )}
                  </div>
                </div>

                <div className="lg:flex justify-between gap-2 pt-5">
                  <div className="border-[1px] border-gray-300 rounded-lg px-[15px] py-[15px]">
                    <div className="">
                      <p
                        className="text-[18px] font-Glacial Indifference
                 text-[#111]"
                      >
                        {t("profilePage.city")}
                      </p>
                      <p className="flex">
                        <span
                          className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                        ></span>
                      </p>
                    </div>
                    <div className="pt-[10px] pl-[19px]">
                      <span
                        className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                      >
                        {t("spanish")}
                      </span>
                    </div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="city"
                        className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20 transition duration-200   font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        placeholder={t("profilePage.city")}
                        value={profileDetails?.address?.city}
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            address: {
                              ...profileDetails?.address,
                              city: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="pt-[10px] pl-[19px]">
                      <span
                        className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                      >
                        {t("english")}
                      </span>
                    </div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="city"
                        className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20 transition duration-200   font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        placeholder={t("profilePage.city")}
                        value={profileDetails?.translations?.en?.address?.city}
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            translations: {
                              ...profileDetails?.translations,
                              en: {
                                ...profileDetails?.translations?.en,
                                address: {
                                  ...profileDetails?.translations?.en?.address,
                                  city: e.target.value,
                                },
                              },
                            },
                          });
                        }}
                      />
                    </div>
                    {errors.city && profileDetails?.city === "" && (
                      <div className="text-red-300 font-Glacial ml-6 text-lg">
                        {errors.city}
                      </div>
                    )}
                  </div>

                  {/* Address-State */}
                  <div className="border-[1px] border-gray-300 rounded-lg px-[15px] py-[15px]">
                    <div>
                      <p
                        className="text-[18px] font-Glacial Indifference
                 text-[#111]"
                      >
                        {t("profilePage.state")}
                      </p>
                      <p className="flex">
                        <span
                          className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                        ></span>
                      </p>
                    </div>
                    <div className="pt-[10px] pl-[19px]">
                      <span
                        className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                      >
                        {t("spanish")}
                      </span>
                    </div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="state"
                        className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20   transition duration-200 font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        placeholder={t("profilePage.state")}
                        value={profileDetails?.address?.state}
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            address: {
                              ...profileDetails?.address,
                              state: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="pt-[10px] pl-[19px]">
                      <span
                        className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                      >
                        {t("english")}
                      </span>
                    </div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="state"
                        className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        placeholder={t("profilePage.state")}
                        value={profileDetails?.translations?.en?.address?.state}
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            translations: {
                              ...profileDetails?.translations,
                              en: {
                                ...profileDetails?.translations?.en,
                                address: {
                                  ...profileDetails?.translations?.en?.address,
                                  state: e.target.value,
                                },
                              },
                            },
                          });
                        }}
                      />
                    </div>
                    {errors.state && profileDetails?.state === "" && (
                      <div className="text-red-300 font-Glacial ml-6 text-lg">
                        {errors.state}
                      </div>
                    )}
                  </div>

                  <div className="border-[1px] border-gray-300 rounded-lg px-[15px] py-[15px] ">
                    <div>
                      <p
                        className="text-[18px] font-Glacial Indifference
                 text-[#111]"
                      >
                        {t("profilePage.country")}
                      </p>
                      <p className="flex">
                        <span
                          className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                        ></span>
                      </p>
                    </div>
                    <div className="pt-[10px] pl-[19px]">
                      <span
                        className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                      >
                        {t("spanish")}
                      </span>
                    </div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="country"
                        className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        placeholder={t("profilePage.country")}
                        value={profileDetails?.address?.country}
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            address: {
                              ...profileDetails?.address,
                              country: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="pt-[10px] pl-[19px]">
                      <span
                        className="text-[18px] font-Glacial Indifference
                 text-[#474747]"
                      >
                        {t("english")}
                      </span>
                    </div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="country"
                        className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20 transition duration-200   font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        placeholder={t("profilePage.country")}
                        value={
                          profileDetails?.translations?.en?.address?.country
                        }
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            translations: {
                              ...profileDetails?.translations,
                              en: {
                                ...profileDetails?.translations?.en,
                                address: {
                                  ...profileDetails?.translations?.en?.address,
                                  country: e.target.value,
                                },
                              },
                            },
                          });
                        }}
                      />
                    </div>
                    {errors.country && profileDetails?.country === "" && (
                      <div className="text-red-300 font-Glacial ml-6 text-lg">
                        {errors.country}
                      </div>
                    )}
                  </div>

                  {/* Address-postalCode */}
                  <div className="border-[1px] border-gray-300 rounded-lg px-[15px] py-[15px] ">
                    <div>
                      <p
                        className="text-[18px] font-Glacial Indifference
                 text-[#111]"
                      >
                        {t("profilePage.postalCode")}
                      </p>
                      <p className="flex">
                        <span
                          className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                        ></span>
                      </p>
                    </div>
                    <div className="pt-[10px] pl-[19px]"></div>
                    <div className="relative flex items-center py-2">
                      <input
                        name="postalCode"
                        className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                        type="text"
                        maxLength="6"
                        placeholder="Enter your postalCode"
                        value={profileDetails?.address?.postalCode}
                        onChange={(e) => {
                          setProfileDetails({
                            ...profileDetails,
                            address: {
                              ...profileDetails.address,
                              postalCode: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>

                    {errors.postalCode && profileDetails?.postalCode === "" && (
                      <div className="text-red-300 font-Glacial ml-6 text-lg">
                        {errors.postalCode}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="sticky bottom-0 z-10 bg-white px-[20px] pb-[20px]">
                <div className="flex justify-center pt-[10px] mt-[50px] ">
                  {loadingBtn ? (
                    <>
                      <div className=" border-primary bg-white text-[#FFF]  border-[3px] text-xs rounded-full w-[110px] h-[60px] px-4 py-2 flex mx-auto items-center justify-center mt-5">
                        <Loader size={20} />
                      </div>
                    </>
                  ) : (
                    <button
                      type="submit"
                      onClick={handleUpdateProfile}
                      className="bg-primary mt-5 flex items-center justify-center text-white py-[18px] w-[120px] rounded-full mx-auto  font-Comfortaa-Medium text-[16px] capitalize"
                    >
                      {t("addNewDish.update")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default ProfileEditPage;
