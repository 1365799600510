import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import DBtn from "./DBtn";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import CardNumber from "../svg/CardNumber";
import CardHolder from "../svg/CardHolder";
import Visa from "../svg/Visa";
import Amex from "../svg/Amex";
import Paypal from "../svg/Paypal";
import MMPaymentDetails from "../svg/MMPaymentDetails";
import { getUserProfile } from "../services/profileService";
import { addBooking, getDishDetails } from "../services/dishService";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getIPV4Address, getSHA1 } from "../utils/CommonFunctions";
import Loader from "./Loader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
var qs = require("qs");

const PayWithCardUpdated = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useSelector((state) => state?.auth);
  const { noOfDays } = useSelector((state) => state);
  const lang = localStorage.getItem("i18nextLng");
  // console.log("SetnoOfDays: ", noOfDays);
  const noOfPeople = useSelector((state) => state.noOfDays?.noOfPeople);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dishDetail, setDishDetail] = useState({});
  const [isLoadingPay, setIsLoadingPay] = useState(false);
  const [errors, setErrors] = useState({
    credit_card_number: "",
    expire_month: "",
    expire_year: "",
    cvv2: "",
    card_printed_name: "",
  });
  const [errorsEs, setErrorsEs] = useState({
    credit_card_number: "",
    expire_month: "",
    expire_year: "",
    cvv2: "",
    card_printed_name: "",
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const endPointid = 2011;
  const merchant_control = "a28dfe2d-85b6-42a9-bfbd-e185ba8a5f2c";
  const loginTL = "MyDish4You";

  const [cardData, setCardData] = useState({
    client_orderid: "0001",
    amount: "",
    currency: "GBP",
    first_name: "",
    last_name: "",
    country: "IL",
    email: "",
    ipaddress: "",
    credit_card_number: "",
    expire_month: "",
    expire_year: "",
    cvv2: "",
    card_printed_name: "",
    control: "",
    server_callback_url:
      "https://mydish.sapientcodelabs.xyz/user/payment-callback",
  });

  const [cardDataTL, setCardDataTL] = useState({
    client_orderid: "0001",
    orderid: "",
    by_request_sn: "",
    control: "",
  });

  const getUserDetails = async () => {
    try {
      setIsLoading(true);
      let userDetails = await getUserProfile();
      setCardData((prevData) => ({
        ...prevData,
        first_name: userDetails?.data?.firstName,
        last_name: userDetails?.data?.lastName,
        email: userDetails?.data?.email,
      }));
    } catch (error) {
      console.log("Error on Get User Details : ", error);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log("dishDetail", dishDetail);
  const getDishDetailsData = async () => {
    try {
      setIsLoading(true);
      const dishDetails = await getDishDetails(id);
      setDishDetail(dishDetails);
      // console.log(dishDetails);
      setCardData((prevData) => ({
        ...prevData,
        amount: dishDetails?.data?.dishPrice,
      }));
    } catch (error) {
      console.log("GetDish Details Error : ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getIpAddress = async () => {
      try {
        const ip = await getIPV4Address();
        setCardData((prevData) => ({
          ...prevData,
          ipaddress: ip,
        }));
      } catch (error) {
        console.log("ip error", error);
      }
    };

    getIpAddress();
  }, []);

  // console.log(cardData?.ipaddress);

  const setControl = () => {
    try {
      let combinedString;
      combinedString = `${endPointid}${cardData?.client_orderid}${
        cardData?.amount * 100
      }${cardData?.email}${merchant_control}`;
      // console.log(combinedString);
      const sha1combinedString = getSHA1(combinedString);
      setCardData((prevData) => ({
        ...prevData,
        control: sha1combinedString,
      }));
    } catch (error) {
      console.log("Set Control : ", error);
    }
  };

  useEffect(() => {
    getUserDetails();
    getDishDetailsData();
  }, []);

  useEffect(() => {
    setControl();
  }, [cardData?.client_orderid && cardData?.amount && cardData?.email]);

  const setControlTL = () => {
    try {
      let combinedStringTL;
      combinedStringTL = `${loginTL}${cardDataTL?.client_orderid}${cardDataTL?.orderid}${merchant_control}`;
      // console.log(combinedStringTL);
      const sha1combinedStringTL = getSHA1(combinedStringTL);
      // console.log(sha1combinedStringTL);
      setCardDataTL((prevData) => ({
        ...prevData,
        control: sha1combinedStringTL,
      }));
    } catch (error) {
      console.log("Error in setControlTL :- ", error);
    }
  };

  useEffect(() => {
    setControlTL();
  }, [cardDataTL?.client_orderid && cardDataTL?.orderid]);

  const validateForm = () => {
    const newErrors = {
      credit_card_number: "",
      expire_month: "",
      expire_year: "",
      cvv2: "",
      card_printed_name: "",
    };
    const newErrorsEs = {
      credit_card_number: "",
      expire_month: "",
      expire_year: "",
      cvv2: "",
      card_printed_name: "",
    };

    if (!cardData.credit_card_number.trim()) {
      newErrors.credit_card_number = "Credit card number is required";
      newErrorsEs.credit_card_number =
        "Se requiere número de tarjeta de crédito";
    }

    if (!cardData.expire_month.trim()) {
      newErrors.expire_month = "Expiry month is required";
      newErrorsEs.expire_month = "Se requiere mes de vencimiento";
    }

    if (!cardData.expire_year.trim()) {
      newErrors.expire_year = "Expiry year is required";
      newErrorsEs.expire_year = "Se requiere año de caducidad";
    }

    if (!cardData.cvv2.trim()) {
      newErrors.cvv2 = "CVV is required";
      newErrorsEs.cvv2 = "Se requiere CVV";
    }

    if (!cardData.card_printed_name.trim()) {
      newErrors.card_printed_name = "Cardholder name is required";
      newErrorsEs.card_printed_name =
        "El nombre del titular de la tarjeta es obligatorio";
    }
    setErrorsEs(newErrorsEs);
    setErrors(newErrors);

    // Check if there are any errors
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload = {
      client_orderid: "0001",
      amount: cardData?.amount,
      currency: cardData?.currency,
      first_name: cardData?.first_name,
      last_name: cardData?.last_name,
      country: cardData?.country,
      email: cardData?.email,
      ipaddress: cardData?.ipaddress,
      credit_card_number: cardData?.credit_card_number,
      expire_month: cardData?.expire_month,
      expire_year: cardData?.expire_year,
      cvv2: cardData?.cvv2,
      card_printed_name: cardData?.card_printed_name,
      control: cardData?.control,
      server_callback_url: cardData?.server_callback_url,
    };
    // console.log("cardData", cardData);
    // console.log("Payload", payload);
    // console.log("cardDataTL", cardDataTL);

    if (!validateForm()) {
      return;
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://channel-sandbox.paragon.online/v1/payment/2011",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(payload),
    };
    try {
      setIsLoadingPay(true);
      let keyValues;

      await axios
        .request(config)
        .then((response) => {
          const responseData = response.data;
          keyValues = responseData.split("\n").reduce((acc, pair) => {
            const [key, value] = pair.split("=");
            acc[key] = value;
            return acc;
          }, {});

          setCardDataTL((prevState) => ({
            ...prevState,
            orderid: keyValues["&paynet-order-id"],
            by_request_sn: keyValues["&serial-number"],
          }));
          // console.log("Key values ", keyValues);
          // console.log("cardDataTL", cardDataTL);
        })
        .catch((error) => {
          console.log(error);
        });

      let attempts = 0;
      let redirectReceived = false;
      let redirectUrl3d;

      let keyValuesTL;
      while (attempts <= 3 && !redirectReceived) {
        attempts++;
        console.log("attempts", attempts);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        let payloadTL = {
          client_orderid: "0001",
          orderid: keyValues["&paynet-order-id"],
          "by-request-sn": keyValues["&serial-number"],
          control: getSHA1(
            `MyDish4You0001${keyValues["&paynet-order-id"]}${merchant_control}`
          ),
        };
        // console.log("payloadTL:", payloadTL);

        let configTL = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://channel-sandbox.paragon.online/v1/status/2011",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: qs.stringify(payloadTL),
        };

        axios
          .request(configTL)
          .then((response) => {
            const responseData = response.data;
            keyValuesTL = responseData.split("\n").reduce((acc, pair) => {
              const [key, value] = pair.split("=");
              acc[key] = value;
              return acc;
            }, {});
            // console.log(keyValuesTL);
            const paymentStatus = `Payment status ${keyValuesTL["&status"]}`;
            // console.log(paymentStatus);
            // toast.success(paymentStatus);
            setModalOpen(false);
            redirectUrl3d = keyValuesTL["&redirect-url-3d"];
            // console.log("redirectUrl3d", redirectUrl3d);
            if (redirectUrl3d) {
              // console.log("Redirect URL", redirectUrl3d);
              redirectReceived = true;
              const decodedUrl = decodeURIComponent(redirectUrl3d);
              console.log("decoded", decodedUrl);
              window.open(decodedUrl);
              navigate(`/dish-details/${id}`);
            }
            // console.log(JSON.stringify(response.data));
            // console.log("Response configTL", response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (redirectReceived) {
        let payloadBooking = {
          bookingStartDate: noOfDays?.startDate,
          bookingEndDate: noOfDays?.endDate,
          noOfDays: noOfDays?.noOfDays,
          hostId: dishDetail?.data?.hostId?._id,
          guestId: user?.id,
          listingId: id,
          bookingAmount: dishDetail?.data?.dishPrice,
          bookingQuantity: dishDetail?.data?.maxDiningCapacity,
          noOfGuests: noOfPeople,
        };

        try {
          const resp = await addBooking(payloadBooking);
          console.log(resp);
          toast.success(
            lang === "en" || lang === "en-US"
              ? resp?.message?.en
              : resp?.message?.es
          );
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingPay(false);
    }
  };
  console.log("dishDetail?.dishPrice", dishDetail?.data?.dishPrice);

  return (
    <div className="container mx-auto items-start font-Glacial">
      <div className="md:flex justify-between items-end">
        <div>
          <div className="text-md text-[#ABABAB] pt-[8px]">
            <h2>{t("confirmPay.payWith")}</h2>
          </div>
          <div className="pt-[16px] text-lg">
            {lang === "en" || lang === "en-US" ? (
              <span>Payment method</span>
            ) : (
              <span>Método de pago</span>
            )}
          </div>
          <div className="flex pt-[18px]">
            <div>
              <Visa />
            </div>
            <div className="pl-[10px]">
              <Amex />
            </div>
            <div className="pl-[10px]">
              <Paypal />
            </div>
          </div>
        </div>
        <div>
          <div className=" sm:container mx-auto mb-[70px] pt-[32px] flex justify-end">
            <DBtn
              variant="contain"
              size="small"
              onClick={() => setModalOpen(true)}
              startIcon=""
            >
              {t("confirmPay.payNow")}
            </DBtn>
            {isModalOpen && (
              <Modal
                width={1020}
                height={820}
                onClose={() => setModalOpen(false)}
              >
                <h1 className="text-[40px] font-bold text-center text-Comforta">
                  {/* Payment details */}
                  {t("confirmPay.paymentDetails")}
                </h1>

                <div className="mx-12 pt-[24px]">
                  <div class="bg-primary bg-opacity-10 rounded-md p-[10px]  ">
                    {lang === "en" || lang === "en-US" ? (
                      <p class="text-base font-semibold ">
                        Your credit card will be charged by: Mydish4U -{" "}
                        <a
                          href="mailto:contact@mydish4u.com"
                          className="underline"
                        >
                          contact@mydish4u.com
                        </a>
                        .
                      </p>
                    ) : (
                      <p class="text-base font-semibold ">
                        Su tarjeta de crédito será cargada por: Mydish4U -{" "}
                        <a
                          href="mailto:contact@mydish4u.com"
                          className="underline"
                        >
                          contact@mydish4u.com
                        </a>
                        .
                      </p>
                    )}
                    <div className="flex gap-2 items-center pt-[8px]">
                      {lang === "en" || lang === "en-US" ? (
                        <p class="text-base font-semibold ">
                          You will be charged
                        </p>
                      ) : (
                        <p class="text-base font-semibold ">se le cobrará</p>
                      )}
                      <p class="text-lg font-bold text-primary">
                        €{dishDetail?.data?.dishPrice} Euro.
                      </p>
                    </div>
                  </div>
                  {/* First row */}
                  <div className="relative pt-[16px] mb-5 flex-col items-center">
                    {lang === "en" || lang === "en-US" ? (
                      <div className="text-base font-semibold">
                        The 13-19 digits on the front of your card
                      </div>
                    ) : (
                      <div className="text-base font-semibold">
                        Los 13-19 dígitos en el frente de su tarjeta
                      </div>
                    )}
                    <div className="relative flex flex-col">
                      <div className="absolute text-[18px] text-primary p-4 border-r only: w-[60px] h-[60px] bg-transparent  ">
                        <span>
                          <CardNumber />
                        </span>
                      </div>
                      <input
                        className="w-[400px] border border-gray-300 focus:ring-2 transition delay-150 focus:ring-primary focus:border focus:border-primary h-[60px] outline-none rounded-lg ps-[72px] pe-[50px] font-Glacial pl-24 text-[18px]  placeholder:text-[#ABABAB]"
                        name="credit_card_number"
                        type="number"
                        placeholder={
                          lang === "en" || lang === "en-US"
                            ? "Card Number"
                            : "Número de tarjeta"
                        }
                        onChange={(e) =>
                          setCardData((prevData) => ({
                            ...prevData,
                            credit_card_number: e.target.value,
                          }))
                        }
                      />
                      <div className="ml-16">
                        {lang === "en" || lang === "en-US" ? (
                          <>
                            {errors.credit_card_number && (
                              <span className="text-red-500">
                                {errors.credit_card_number}
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            {errorsEs.credit_card_number && (
                              <span className="text-red-500">
                                {errorsEs.credit_card_number}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Second row */}
                  <div className="flex gap-8">
                    <div>
                      <div className="relative pt-[5px] flex-col items-center">
                        {lang === "en" || lang === "en-US" ? (
                          <div className="text-base font-semibold">
                            Expiration Month
                          </div>
                        ) : (
                          <div className="text-base font-semibold">
                            Mes de expiración
                          </div>
                        )}
                        <div className="relative flex flex-col ">
                          <div className="absolute text-[18px] text-primary p-4 only: w-[60px] h-[60px] bg-transparent border-r">
                            <span>
                              <MMPaymentDetails />
                            </span>
                          </div>
                          <InputMask
                            mask="99"
                            maskChar=""
                            className="w-[260px] border border-gray-300  h-[60px] focus:ring-2 focus:border focus:border-primary focus:ring-primary transition delay-150 outline-none rounded-lg ps-[72px] pe-[50px] font-Glacial pl-24  text-[18px]  placeholder:text-[#ABABAB]"
                            name="expire_month"
                            // type="number"
                            placeholder="MM"
                            onChange={(e) =>
                              setCardData((prevData) => ({
                                ...prevData,
                                expire_month: e.target.value,
                              }))
                            }
                          />
                          <div className="ml-16">
                            {lang === "en" || lang === "en-US" ? (
                              <>
                                {errors.expire_month && (
                                  <span className="text-red-500">
                                    {errors.expire_month}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {errorsEs.expire_month && (
                                  <span className="text-red-500">
                                    {errorsEs.expire_month}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="relative pt-[5px] flex items-center">
                        <div>
                          {lang === "en" || lang === "en-US" ? (
                            <div className="text-base font-semibold">
                              Expiration Year
                            </div>
                          ) : (
                            <div className="text-base font-semibold">
                              Año de vencimiento
                            </div>
                          )}
                          <div className="absolute text-[18px] text-primary p-4 only: w-[60px] h-[60px] bg-transparent  border-r">
                            <span>
                              <MMPaymentDetails />
                            </span>
                          </div>
                          <InputMask
                            mask="9999"
                            maskChar=""
                            className="w-[260px] h-[60px] border border-gray-300 focus:ring-2 focus:border-primary focus:border focus:ring-primary outline-none rounded-lg ps-[72px] pe-[50px] font-Glacial pl-24 text-[18px]  placeholder:text-[#ABABAB]"
                            name="expire_year"
                            // type="number"
                            placeholder="YYYY"
                            onChange={(e) =>
                              setCardData((prevData) => ({
                                ...prevData,
                                expire_year: e.target.value,
                              }))
                            }
                          />
                          <div className="ml-16">
                            {lang === "en" || lang === "en-US" ? (
                              <>
                                {errors.expire_year && (
                                  <span className="text-red-500">
                                    {errors.expire_year}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {errorsEs.expire_year && (
                                  <span className="text-red-500">
                                    {errorsEs.expire_year}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="relative  flex items-center">
                        <div>
                          {lang === "en" || lang === "en-US" ? (
                            <div className="text-base  font-semibold absolute -top-5 w-[280px]">
                              The last 3 digits displayed on the back of your
                              card
                            </div>
                          ) : (
                            <div className="text-base  font-semibold absolute -top-5 w-[280px]">
                              Los últimos 3 dígitos que se muestran en el
                              reverso de su tarjeta
                            </div>
                          )}
                          <div className="absolute mt-8 ">
                            <div className="absolute text-[18px]  text-primary p-4 only: w-[60px] h-[60px] bg-transparent border-r">
                              <span>
                                <CardNumber />
                              </span>
                            </div>
                            <InputMask
                              mask="999"
                              maskChar=""
                              className="w-[260px] border border-gray-300 focus:border focus:border-primary h-[60px] focus:ring-2 focus:ring-primary outline-none rounded-lg ps-[72px] pe-[50px] font-Glacial  pl-24 text-[18px]  placeholder:text-[#ABABAB]"
                              name="cvv2"
                              // type="number"
                              placeholder="CVV"
                              onChange={(e) =>
                                setCardData((prevData) => ({
                                  ...prevData,
                                  cvv2: e.target.value,
                                }))
                              }
                            />
                          </div>

                          <div className="ml-16 mt-24">
                            {lang === "en" || lang === "en-US" ? (
                              <>
                                {errors.cvv2 && (
                                  <span className="text-red-500">
                                    {errors.cvv2}
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {errorsEs.cvv2 && (
                                  <span className="text-red-500">
                                    {errorsEs.cvv2}
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Third row */}
                  <div className="relative  my-5 flex-col items-center">
                    {lang === "en" || lang === "en-US" ? (
                      <div className="text-base font-semibold">
                        Full Name on a Card
                      </div>
                    ) : (
                      <div className="text-base font-semibold">
                        Nombre completo en una tarjeta
                      </div>
                    )}
                    <div className="flex flex-col">
                      <div className="absolute text-[18px] text-primary p-4 only: w-[60px] h-[60px] bg-transparent border-r">
                        <span className="mt-10">
                          <CardHolder />
                        </span>
                      </div>
                      <input
                        className="w-[400px] h-[60px] border border-gray-300 focus:border focus:border-primary  focus:ring-2 focus:ring-primary outline-none rounded-lg ps-[72px] pe-[50px] font-Glacial pl-24 text-[18px]  placeholder:text-[#ABABAB]"
                        name="card_printed_name"
                        type="text"
                        placeholder={
                          lang === "en" || lang === "en-US"
                            ? "Card holder name"
                            : "Nombre del titular de la tarjeta"
                        }
                        onChange={(e) =>
                          setCardData((prevData) => ({
                            ...prevData,
                            card_printed_name: e.target.value,
                          }))
                        }
                      />
                      <div className="ml-16">
                        {lang === "en" || lang === "en-US" ? (
                          <>
                            {errors.card_printed_name && (
                              <span className="text-red-500">
                                {errors.card_printed_name}
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            {errorsEs.card_printed_name && (
                              <span className="text-red-500">
                                {errorsEs.card_printed_name}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex mt-[16px] bg-primary bg-opacity-10 rounded-md p-4 justify-between">
                    {lang === "en" || lang === "en-US" ? (
                      <div className="text-base font-semibold ">
                        Customer service number: +34 614 35 17 09 <br />
                        <div className="mt-1">
                          Company name and address: Calle Silvestre Bello 7 2a,
                          35110-Vecindario
                        </div>
                      </div>
                    ) : (
                      <div className="text-base font-semibold ">
                        Número de atención al cliente: +34 614 35 17 09 <br />
                        <div className="mt-1">
                          Nombre y dirección de la empresa: Calle Silvestre
                          Bello 7 2a, 35110-Vecindario
                        </div>
                      </div>
                    )}
                    <div>
                      <li className="font-normal mt-4 text-[18px] flex gap-4">
                        <img
                          src="../images/footer/card_visa.png"
                          alt="card_visa"
                          className="h-[24px]"
                        ></img>
                        <img
                          src="../images/footer/card_master.png"
                          alt="card_master"
                          className="h-[24px]"
                        ></img>
                        <img
                          src="../images/footer/card_paypal.png"
                          alt="card_paypal"
                          className="h-[24px]"
                        ></img>
                        <img
                          src="../images/footer/card_amex.png"
                          alt="card_amex"
                          className="h-[24px]"
                        ></img>
                      </li>
                    </div>
                  </div>
                  {/* Pay Btn */}
                  <div className="flex mt-[24px] py-5 justify-center items-center">
                    {isLoadingPay ? (
                      <div className=" border-primary bg-white text-[#FFF]  border-[3px] text-xs  px-[250px]  rounded-full">
                        <Loader size={40} />
                      </div>
                    ) : (
                      <DBtn
                        variant="contain"
                        size="paybtn"
                        onClick={handleSubmit}
                      >
                        {t("confirmPay.payNow")}
                      </DBtn>
                    )}
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayWithCardUpdated;
