import React from "react";
import HowIt1 from "../svg/HowIt1";
// "../images/Homepage/h1pic.png"
const CardHowItWorks = ({ imageSrc, svgIcon, title, desc }) => {
  return (
    <div className="border w-full rounded-[20px] p-4 mt-[60px]">
      <div className="md:flex justify-between gap-20 w-full">
        <div className="md:w-1/2">
          <img src={imageSrc} alt={title} className="w-full" />
        </div>
        <div className="md:w-1/2 flex gap-5 items-center justify-cente mt-2 lg:mt-0">
          <div>{svgIcon}</div>
          <div>
            <div className="font-Comfortaa text-[18px] font-bold">{title}</div>
            <div className="font-Glacial text-base font-normal leading-[19.18px] mt-3">
              {desc}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardHowItWorks;
