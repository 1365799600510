import React from "react";

const Paypal = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="11"
        viewBox="0 0 40 11"
        fill="none"
        className="border-[0.5px] border-[#ABABAB] w-[60px] h-[24px] px-[6px]"
      >
        <path
          d="M6.77081 0.517416C6.26945 0.172751 5.61522 0 4.80811 0H1.68344C1.43601 0 1.29932 0.123743 1.27335 0.37093L0.00398432 8.33895C-0.00920777 8.41721 0.0103714 8.48878 0.0625428 8.55384C0.114416 8.61903 0.17966 8.65144 0.257798 8.65144H1.742C2.00232 8.65144 2.1454 8.528 2.17166 8.28045L2.52314 6.13235C2.53597 6.02825 2.58175 5.9436 2.65989 5.87842C2.73797 5.81335 2.83563 5.77079 2.9528 5.75127C3.06998 5.73193 3.18047 5.7222 3.28481 5.7222C3.38886 5.7222 3.51248 5.72877 3.65592 5.74172C3.79901 5.75473 3.89022 5.76106 3.92932 5.76106C5.04891 5.76106 5.92771 5.44559 6.56576 4.81398C7.20352 4.18261 7.52276 3.30709 7.52276 2.18726C7.52276 1.41919 7.27193 0.862619 6.77081 0.517416ZM5.1597 2.9099C5.0944 3.36565 4.92535 3.66495 4.65196 3.80821C4.3785 3.95166 3.98793 4.02293 3.48025 4.02293L2.83575 4.04251L3.16782 1.95285C3.19372 1.80976 3.27831 1.73813 3.42163 1.73813H3.7928C4.31332 1.73813 4.69111 1.81317 4.92547 1.96264C5.1597 2.11246 5.23784 2.42836 5.1597 2.9099Z"
          fill="#003087"
        />
        <path
          d="M39.7458 0H38.3007C38.1572 0 38.0727 0.0716312 38.0469 0.214834L36.7774 8.33895L36.7578 8.37799C36.7578 8.44341 36.7839 8.50496 36.8361 8.56357C36.8879 8.62207 36.9532 8.65138 37.0313 8.65138H38.3203C38.5673 8.65138 38.7041 8.52794 38.7305 8.28039L39.9998 0.292793V0.273393C39.9998 0.0911507 39.9149 0 39.7458 0Z"
          fill="#009CDE"
        />
        <path
          d="M22.2872 3.144C22.2872 3.07905 22.261 3.01709 22.2093 2.95859C22.157 2.90003 22.0984 2.87061 22.0335 2.87061H20.5297C20.3862 2.87061 20.2691 2.93603 20.1782 3.06586L18.1081 6.11258L17.2489 3.18316C17.1835 2.97495 17.0404 2.87061 16.8192 2.87061H15.3543C15.2891 2.87061 15.2305 2.89997 15.1787 2.95859C15.1265 3.01709 15.1006 3.07911 15.1006 3.144C15.1006 3.17026 15.2276 3.55415 15.4814 4.29625C15.7352 5.03847 16.0086 5.83918 16.3016 6.69858C16.5946 7.55774 16.7474 8.01367 16.7605 8.06542C15.6929 9.52372 15.1591 10.3049 15.1591 10.4089C15.1591 10.5783 15.2437 10.6628 15.413 10.6628H16.9168C17.06 10.6628 17.1771 10.5979 17.2683 10.4676L22.2483 3.28069C22.2742 3.25479 22.2872 3.20942 22.2872 3.144Z"
          fill="#003087"
        />
        <path
          d="M36.2893 2.87064H34.8049C34.6226 2.87064 34.5122 3.08548 34.4732 3.51514C34.1343 2.99462 33.5162 2.73389 32.6177 2.73389C31.6803 2.73389 30.8827 3.08548 30.2254 3.78854C29.5679 4.49166 29.2393 5.31852 29.2393 6.26883C29.2393 7.03713 29.4638 7.64898 29.9129 8.1045C30.3621 8.56049 30.9642 8.78804 31.7195 8.78804C32.097 8.78804 32.481 8.70978 32.8716 8.55369C33.2622 8.39747 33.5679 8.18926 33.7896 7.92876C33.7896 7.94184 33.7764 8.00033 33.7505 8.10444C33.7243 8.20878 33.7114 8.28704 33.7114 8.33879C33.7114 8.54736 33.7958 8.65122 33.9654 8.65122H35.313C35.56 8.65122 35.7034 8.52778 35.7424 8.28023L36.5432 3.18313C36.556 3.105 36.5365 3.03348 36.4846 2.9683C36.4323 2.90329 36.3674 2.87064 36.2893 2.87064ZM33.7407 6.71796C33.4087 7.04346 33.0084 7.20612 32.5397 7.20612C32.162 7.20612 31.8563 7.10214 31.6218 6.89369C31.3874 6.68572 31.2702 6.39926 31.2702 6.03442C31.2702 5.55299 31.433 5.14589 31.7585 4.81388C32.0837 4.48187 32.4876 4.31586 32.9694 4.31586C33.3336 4.31586 33.6363 4.42331 33.8774 4.63808C34.1181 4.85292 34.2388 5.14923 34.2388 5.52679C34.2388 5.99544 34.0727 6.39263 33.7407 6.71796Z"
          fill="#009CDE"
        />
        <path
          d="M14.1038 2.87064H12.6196C12.4371 2.87064 12.3267 3.08548 12.2876 3.51514C11.936 2.99462 11.3176 2.73389 10.4323 2.73389C9.49484 2.73389 8.69729 3.08548 8.03989 3.78854C7.38232 4.49166 7.05371 5.31852 7.05371 6.26883C7.05371 7.03713 7.27833 7.64898 7.72752 8.1045C8.17671 8.56049 8.77871 8.78804 9.53394 8.78804C9.89831 8.78804 10.2761 8.70978 10.6666 8.55369C11.0572 8.39747 11.3697 8.18926 11.604 7.92876C11.5518 8.08498 11.5259 8.22173 11.5259 8.33879C11.5259 8.54736 11.6105 8.65122 11.7798 8.65122H13.1273C13.3745 8.65122 13.5178 8.52778 13.5569 8.28023L14.3576 3.18313C14.3704 3.105 14.3509 3.03348 14.299 2.9683C14.2469 2.90329 14.1819 2.87064 14.1038 2.87064ZM11.5553 6.72763C11.2232 7.04698 10.8161 7.20612 10.3347 7.20612C9.95692 7.20612 9.65422 7.10214 9.42656 6.89369C9.19859 6.68572 9.08475 6.39926 9.08475 6.03442C9.08475 5.55299 9.24742 5.14589 9.57304 4.81388C9.89831 4.48187 10.3019 4.31586 10.7838 4.31586C11.1482 4.31586 11.4509 4.42331 11.692 4.63808C11.9327 4.85292 12.0533 5.14923 12.0533 5.52679C12.0533 6.00851 11.8873 6.40899 11.5553 6.72763Z"
          fill="#003087"
        />
        <path
          d="M28.9564 0.517416C28.4551 0.172751 27.8009 0 26.9937 0H23.8886C23.628 0 23.4847 0.123743 23.4589 0.37093L22.1895 8.33889C22.1763 8.41715 22.1959 8.48872 22.2481 8.55378C22.2998 8.61897 22.3652 8.65138 22.4434 8.65138H24.0447C24.2009 8.65138 24.305 8.56686 24.3572 8.39757L24.7087 6.13229C24.7216 6.02819 24.7672 5.94354 24.8454 5.87836C24.9236 5.81329 25.0211 5.77073 25.1384 5.75121C25.2555 5.73187 25.366 5.72214 25.4704 5.72214C25.5745 5.72214 25.6981 5.72871 25.8414 5.74166C25.9846 5.75467 26.0759 5.761 26.1147 5.761C27.2345 5.761 28.1132 5.44553 28.7513 4.81392C29.3892 4.18255 29.7082 3.30703 29.7082 2.1872C29.7082 1.41919 29.4575 0.862619 28.9564 0.517416ZM26.9546 3.73008C26.6682 3.92533 26.2385 4.02293 25.6657 4.02293L25.0408 4.04251L25.3728 1.95285C25.3986 1.80976 25.4832 1.73813 25.6266 1.73813H25.9781C26.2644 1.73813 26.4922 1.75114 26.6618 1.77711C26.8308 1.80338 26.9937 1.8845 27.1499 2.02119C27.3063 2.15795 27.3843 2.35655 27.3843 2.61693C27.3843 3.16371 27.2409 3.5347 26.9546 3.73008Z"
          fill="#009CDE"
        />
      </svg>
    </div>
  );
};

export default Paypal;
