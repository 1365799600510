import React from "react";
import { MdStarRate } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiFillHeart } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import useAuth from "../utils/hooks/useAuth";
import { useTranslation } from "react-i18next";
import EmptyHeart from "../svg/EmptyHeart";

const DishDetails = ({
  dish,
  hostRating,
  title,
  titleEn,
  city,
  country,
  price,
  cityEn,
  countryEn,
  isShowed = true,
  mainImage,
  otherImage,
}) => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  // console.log("disjh", dish);
  // console.log("country", countryEn);
  return (
    <>
      <div className="container mx-auto my-auto  mt-[20px]">
        <div>
          <p className="text-lg capitalize font-Comfortaa-SemiBold text-[#111111] flex justify-center sm:justify-start">
            {lang === "en" || lang === "en-US" ? (
              <div className=" capitalize">
                {!titleEn ? <>{title}</> : <>{titleEn}</>}
              </div>
            ) : (
              <div className="capitalize ">{title}</div>
            )}
          </p>
          <div className="flex justify-between items-centerx">
            <div className="flex  gap-x-4 items-center ">
              <div className="flex items-center gap-x-1  sm:text-lg text-xs">
                <MdStarRate className="text-primary" />
                <p className="text-[#111] font-Glacial">{hostRating}</p>
              </div>
              <span className="underline underline-offset-2 font-Glacial sm:text-lg text-xs text-[#111]">
                0 {t("dishDetail.reviews")}
              </span>
              {city && (
                <div className="flex items-center  gap-x-1 sm:text-lg text-xs">
                  <HiOutlineLocationMarker className="text-primary" />
                  {lang === "en" || lang === "en-US" ? (
                    <p className="font-Glacial  text-[#474747] capitalize ">
                      {cityEn}, {countryEn}
                    </p>
                  ) : (
                    <p className="font-Glacial  text-[#474747] capitalize ">
                      {city}, {country}
                    </p>
                  )}
                </div>
              )}

              <p className="text-primary sm:text-2xl text-sm font-Comfortaa-Bold ">
                ${price}
              </p>
            </div>

            {/* <div className="z-10">
              {isShowed && isAuthenticated && (
                <div className="w-[32px] h-[32px] bg-[#FF914D1A] p-[9px] text-primary rounded-full me-[10px]">
                 
                  <EmptyHeart
                    status={dish?.favoriteStatus}
                    favoriteId={dish?._id}
                    className="text-sm cursor-pointer"
                    onClick={() => console.log("clicked liked")}
                  />
                </div>
              )}
            </div> */}
          </div>
        </div>
        <div className="pt-[26px] cursor-pointer">
          <Swiper
            slidesPerView={2.3}
            loop={true}
            spaceBetween={10}
            pagination={{ clickable: true }}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                className=" w-[400px] h-[200px] sm:w-[650px] sm:h-[400px] object-cover"
                src={mainImage}
                alt=""
              />
            </SwiperSlide>
            {otherImage?.map((item) => {
              return (
                <SwiperSlide>
                  <img
                    className="w-[400px] h-[200px] sm:w-[650px] sm:h-[400px] object-cover"
                    src={item}
                    alt=""
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default DishDetails;
