import React from "react";

import { useTranslation } from "react-i18next";

const ChefCard = ({ hostname, hostsurname, hostimg ,guest}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="container mx-auto mt-[10px]">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-md font-Glacial text-[#ABABAB]">
              {t("dishDetail.hostedBy")} &nbsp;
              <span className="capitalize">
                {/* {dishDetail?.hostname} {dishDetail?.hostsurname} */}
                {hostname} {hostsurname}
              </span>
            </p>
            <div className="flex gap-x-4">
              <div className="flex gap-x-2 text-ellipsis text-lg font-Glacial">
                <img src="../images/Chefcard/host.svg" alt="" />
                <span>{t("dishDetail.superHost")}</span>
              </div>
              <span className="text-[#111] text-lg font-Glacial">
                {guest} {t("dishDetail.guests")}
              </span>
            </div>
          </div>
          <img
            className="h-[60px] w-[60px] rounded-full mr-2"
            src={hostimg}
            alt=""
          />
        </div>
        <div className="h-[1px] w-full bg-[#ABABAB] mt-4"></div>
      </div>
    </>
  );
};

export default ChefCard;
