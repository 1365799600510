import React, { useState } from "react";

const InputField = ({ startIcon, endIcon, type, inputType,   }) => {
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    const enteredValue = e.target.value;

    if (inputType === 'number') {
      
      if (!isNaN(enteredValue)) {
        setValue(enteredValue);
      }
    } else if (inputType === 'text') {
      
      setValue(enteredValue);
    } else if (inputType === 'mobile') {
      
      const formattedValue = enteredValue.replace(/[^0-9]/g, ''); 
      setValue(formattedValue);
    }
  };

  return (
    <div className="container mx-auto">
      <div className="relative flex items-center">
        <div className="absolute text-[18px] text-primary p-5 only: w-[60px] h-[60px] bg-white shadow-[#E6E6E6E5] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] rounded-full">
          {startIcon && <span>{startIcon}</span>}
        </div>

          <input
            className="w-full h-[50px] outline-none rounded-full ps-[72px] pe-[50px] font-Glacial  text-[18px] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5] placeholder:text-[#ABABAB]"
            type={inputType === 'number' ? 'number' : 'text'}
            placeholder={`Enter ${inputType === 'number' ? 'numbers' : (inputType === 'mobile' ? 'mobile number' : 'text')}`}
            onChange={handleChange}
            
            id="InputField"
          />
        <div className="absolute right-5">
          {endIcon && <span>{endIcon}</span>}
        </div>
      </div>
    </div>
  );
};

export default InputField;
