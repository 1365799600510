import React, { useState } from "react";

const RadioGroup = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <label htmlFor="option1" className="text-gray-800 font-Glacial ">
          English
        </label>

        <input
          type="radio"
          id="option1"
          name="options"
          value="option1"
          checked={selectedOption === "option1"}
          onChange={handleOptionChange}
          className="mr-2 ml-[270px] "
        />
      </div>
      <div className="flex items-center">
        <label htmlFor="option2" className="text-gray-800 font-Glacial">
          Spanish
        </label>

        <input
          type="radio"
          id="option2"
          name="options"
          value="option2"
          checked={selectedOption === "option2"}
          onChange={handleOptionChange}
          className="mr-2 ml-[265px]"
        />
      </div>
    </div>
  );
};

export default RadioGroup;
