import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Title from "../components/Title";
import Profile from "../components/Profile";
import DBtn from "../components/DBtn";
import Logout from "../svg/Logout";
import Star from "../svg/Star";
import Location from "../svg/Location";
import PlusBank from "../svg/PlusBank";
import { HiOutlineUser } from "react-icons/hi";
import { formatDateToDDMMMYYYY } from "../utils/CommonFunctions";
import useAuth from "../utils/hooks/useAuth";
import AccNo from "../svg/AccoNo";
import {
  getUserProfile,
  switchRole,
  updatePassword,
  getHostReviews,
  getGuestReviews,
  addBankDetails,
} from "../services/profileService";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../components/Modal";
import { setUser } from "../store/slices/authSlice";
import { toast } from "react-toastify";
// import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import Star3 from "../svg/Star3";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  // const token = useSelector((state) => state?.auth?.token);
  // console.log("token", token);
  const { isAuthenticated, userSignOut } = useAuth();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const [loadingSwitchRole, setLoadingSwitchRole] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isChangePasswordOpen, setChangePasswordOpen] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [bankDetailsOpen, setBankDetailsOpen] = useState(false);
  const [editBankDetailsOpen, setEditBankDetailsOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [invalidError, setInvalidError] = useState("");
  const [errors, setErrors] = useState({});
  const [errorsEs, setErrorsEs] = useState({});
  const [changePasswordErrors, setChangePasswordErrors] = useState();
  const [changePasswordErrorsEs, setChangePasswordErrorsEs] = useState();
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    dob: "",
    email: "",
    userBio: "",
    profilePhoto: "",
    address: {},
    enFirstName: "",
    enLastName: "",
    enUserBio: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });
  const [bankDetails, setBankDetails] = useState({
    accountHolderName: "",
    bankName: "",
    accountNo: "",
    country: "",
    IBAN: "",
    swiftCode: "",
  });
  const [changePassword, setChangePassword] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [hostReviews, setHostReviews] = useState({
    firstName: "",
    lastName: "",
    profileImg: "",
    ratings: "",
    desc: "",
  });
  const [guestReviews, setGuestReviews] = useState({
    firstName: "",
    lastName: "",
    profileImg: "",
    ratings: "",
    desc: "",
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const newErrorsEs = {};
    if (!bankDetails.accountHolderName) {
      newErrors.accountHolderName = "Account Holder Name is required";
      newErrorsEs.accountHolderName =
        "El nombre del titular de la cuenta es obligatorio";
      isValid = false;
    }
    if (!bankDetails.bankName) {
      newErrors.bankName = "Bank Name is required";
      newErrorsEs.bankName = "El nombre del banco es obligatorio";
      isValid = false;
    }
    if (!bankDetails.accountNo) {
      newErrors.accountNo = "Account No is required";
      newErrorsEs.accountNo = "El numero de cuenta es obligatorio";
      isValid = false;
    }
    if (!bankDetails.country) {
      newErrors.country = "Country is required";
      newErrorsEs.country = "El pais es obligatorio";
      isValid = false;
    }
    if (!bankDetails.IBAN) {
      newErrors.IBAN = "IBAN is required";
      newErrorsEs.IBAN = "El IBAN es obligatorio";
      isValid = false;
    }
    if (!bankDetails.swiftCode) {
      newErrors.swiftCode = "SWIFT Code is required";
      newErrorsEs.swiftCode = "SWIFT Code is required";
      isValid = false;
    } else if (
      bankDetails?.swiftCode.length < 8 ||
      bankDetails?.swiftCode.length > 11
    ) {
      newErrors.swiftCode = "8 and 11 characters long";
      newErrorsEs.swiftCode = "8 y 11 caracteres";
      isValid = false;
    }

    setErrorsEs(newErrorsEs);
    setErrors(newErrors);
    return isValid;
  };
  useEffect(() => {
    setFlag(true);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setSignUpOpen(false);
    }
  }, [isAuthenticated]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setBankLoading(true);
      const resp = await getUserProfile();

      // console.log(resp.data)
      if (resp.success) {
        if (resp?.data && resp?.data?.bankAccountDetails) {
          const {
            accountHolderName,
            bankName,
            accountNo,
            country,
            IBAN,
            swiftCode,
          } = resp?.data?.bankAccountDetails;
          setBankDetails({
            accountHolderName,
            bankName,
            accountNo,
            country,
            IBAN,
            swiftCode,
          });
        }
        setProfileDetails(resp?.data);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoading(false);
      setBankLoading(false);
    }
  };

  const fetchHostReviews = async () => {
    try {
      const payload = {
        perPage: 10,
        pageNo: 1,
      };
      const resp = await getHostReviews(payload);
      const mappedReviews = resp?.data.map((review) => ({
        firstName: review?.guestId?.firstName,
        lastName: review?.guestId?.lastName,
        profileImg: review?.guestId?.profileImage,
        ratings: review?.ratings?.total,
        desc: review?.review,
      }));

      // console.log("Host", mappedReviews);
      setHostReviews(mappedReviews);
      // console.log("Host Reviews:- ", hostReviews);
      // console.log(resp?.data);
    } catch (error) {
      console.log("Get Host Reviews Error: ", error);
    }
  };

  const fetchGuestReviews = async () => {
    try {
      const payload = {
        perPage: 10,
        pageNo: 1,
      };

      const resp = await getGuestReviews(payload);
      // console.log("fetchGuestReviews", resp.data);

      if (resp?.success) {
        const mappedReviews = resp?.data?.map((review) => ({
          firstName: review?.hostId?.firstName,
          lastName: review?.hostId?.lastName,
          profileImg: review?.hostId?.profileImage,
          ratings: Math.round(review?.rating),
          desc: review?.review,
        }));
        setGuestReviews(mappedReviews);
      }

      // console.log("Guest", mappedReviews);
    } catch (error) {
      console.log("Get Guest Reviews Error:- ", error);
    }
  };

  useEffect(() => {
    if (flag) {
      setFlag(false);
      fetchData();
      fetchHostReviews();
      fetchGuestReviews();
    }
  }, [flag]);

  // password modal
  const changePasswordValidation = () => {
    let isValid = true;
    const newErrors = {};
    const newErrorEs = {};
    // Validate email
    if (!changePassword.oldPassword) {
      newErrorEs.oldPassword = "Se requiere contraseña actual";
      newErrors.oldPassword = "Current Password is required";
      isValid = false;
    }

    // Validate password
    if (!changePassword.newPassword) {
      newErrorEs.newPassword = "Se requiere contraseña nueva";
      newErrors.newPassword = "New Password is required";
      isValid = false;
    }

    setChangePasswordErrorsEs(newErrorEs);
    setChangePasswordErrors(newErrors);
    return isValid;
  };

  const handleChangePasswordInputChange = (e) => {
    const { name, value } = e.target;

    setChangePassword({
      ...changePassword,
      [name]: value,
    });
  };

  const submitPasswordChange = async (e) => {
    e.preventDefault();
    if (changePasswordValidation()) {
      try {
        const payload = {
          email: user?.email,
          newPassword: changePassword?.newPassword,
          oldPassword: changePassword?.oldPassword,
        };
        const resp = await updatePassword(payload);
        if (resp?.success) {
          setChangePasswordOpen(false);
          userSignOut();
        }

        console.log(resp);
        console.log("resp", resp);
      } catch (err) {
        console.log("err", err);
        setInvalidError(
          lang === "en" || lang === "en-US" ? (
            <div className="text-red-300 ml-4  font-Glacial">
              Invalid Password.
            </div>
          ) : (
            <div className="text-red-300 ml-4  font-Glacial">
              Contraseña no válida
            </div>
          )
        );
        // toast.error(
        //   lang === "en" || lang === "en-US"
        //     ? "Invalid Password."
        //     : "Contraseña no válida"
        // );
      } finally {
      }
    }
  };

  const handleRoleChange = async () => {
    try {
      setLoadingSwitchRole(true);
      const resp = await switchRole();
      if (resp?.success) {
        toast.success(
          lang === "en" || lang === "en-US"
            ? resp?.message?.en
            : resp?.message?.es
        );
        dispatch(
          setUser({
            ...user,
            role: resp?.data?.role,
          })
        );
      }
    } catch (err) {
      toast.success(
        err?.response?.data?.error?.message ||
          err?.response?.data?.error ||
          err?.response?.data?.message ||
          err?.resp?.message?.en
      );
    } finally {
      setLoadingSwitchRole(false);
    }
  };

  const handleAvailability = () => {
    navigate("/availabel-list");
  };

  const handleLogout = () => {
    setShowConfirmationModal(false);
    userSignOut();
  };

  const handleBankDetails = async () => {
    if (validateForm()) {
      setBankLoading(true);
      try {
        let payload = {
          accountHolderName: bankDetails?.accountHolderName,
          bankName: bankDetails?.bankName,
          accountNo: bankDetails?.accountNo,
          country: bankDetails?.country,
          IBAN: bankDetails?.IBAN,
          swiftCode: bankDetails?.swiftCode,
        };
        // console.log("payload bankdetails:- ", payload);
        const resp = await addBankDetails(payload);
        if (resp?.success) {
          toast.success(
            lang === "en" || lang === "en-US"
              ? resp?.message?.en
              : resp?.message?.es
          );
          setBankDetailsOpen(false);
          setEditBankDetailsOpen(false);
          fetchData();
        }
      } catch (error) {
        console.log("err:", error);
      } finally {
        setBankLoading(false);
        setLoading(false);
      }
    }
  };
  // console.log("profileDetails:", profileDetails);

  console.log("errors:", errors);
  // console.log("banksetaiop: ", bankDetails);
  return (
    <div className="mx-auto container w-full ">
      <Header />
      <div className="container mx-auto">
        {loading ? (
          <div className="flex justify-center items-center m-auto h-[50vh]">
            <Loader />
          </div>
        ) : (
          <>
            <div className="main ">
              <div className="flex xl:flex-row xl:gap-10 flex-col 2xl:mx-52 pb-10">
                {/* left start*/}
                <div className="left lg:px-[60px]  lg:mx-0  lg:border-r-2 border-[#FF914D] overflow-y-auto overflow-x-hidden">
                  <div className="m-auto container">
                    <div className="flex justify-between lg:hidden ">
                      <Title title={t("profilePage.profileLabel")} />
                      <DBtn
                        variant="outline"
                        size="small"
                        onClick={handleLogout}
                        startIcon={<Logout />}
                      >
                        {t("profilePage.logOutLabel")}
                      </DBtn>
                    </div>
                    {/* <Title title={`Profile`} /> */}
                    <Profile
                      image={profileDetails?.profileImage}
                      onChange={(val) => {
                        setProfileData({
                          ...profileData,
                          profilePhoto: val,
                        });
                      }}
                    />
                    {user?.role === "host" ? (
                      <button
                        type="button"
                        onClick={handleAvailability}
                        className="block xl:hidden bg-primary text-white px-4 py-2 my-2 w-auto rounded-full mx-auto  font-Comfortaa-Medium text-[14px] "
                      >
                        {t("profilePage.availability")}
                      </button>
                    ) : (
                      <></>
                    )}
                    <div>
                      <div className="xl:block hidden ">
                        {/* Role */}
                        {user?.role === "host" ? (
                          <button
                            type="button"
                            onClick={handleAvailability}
                            className="bg-primary text-white px-4 py-2 my-2 w-auto rounded-full mx-auto  font-Comfortaa-Medium text-[14px] ml-10"
                          >
                            {t("profilePage.availability")}
                          </button>
                        ) : (
                          <></>
                        )}
                        <div className="pt-[24px]">
                          <Title title={t("profilePage.switchProfileLabel")} />
                        </div>

                        <div className="relative flex items-center py-2 my-4">
                          <div className="host absolute z-10 text-2xl text-primary p-3 border-r only: w-[50px] h-[50px] bg-transparent ">
                            {profileData?.role === "guest" ? (
                              <img
                                src="../images/Signupform/guest.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                className="p-1"
                                src="../images/Signupform/host.svg"
                                alt=""
                              />
                            )}
                          </div>
                          <fieldset className="w-full">
                            <div className="guestselection relative bg-white roundedtrabg-transparent ">
                              <div className="SignUpFormearance-none w-full h-[50px] capitalize outline-none rounded-lg border ps-[80px] py-2 cursor-pointer text-primary font-Glacial sm:text-[20px] text-[12px] bg-white">
                                {user?.role}
                              </div>
                            </div>
                          </fieldset>
                        </div>

                        <div className="">
                          {loadingSwitchRole ? (
                            <div className=" border-primary bg-white text-[#FFF]  border-[3px] text-xs rounded-full w-[130px] h-[35px] px-4 py-2 ">
                              <Loader size={17} />
                            </div>
                          ) : (
                            <button
                              onClick={handleRoleChange}
                              type="button"
                              className="bg-primary text-white px-4 py-2  w-[130px] rounded-full mx-auto  font-Comfortaa-Medium text-[12px]"
                            >
                              {t("profilePage.switchNowLabel")}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="lg:pt-[10px] lg:mx-0">
                      <div className="pt-[30px] w-full hidden xl:flex lg:pl-0 pl-7">
                        {/* <DBtn
                        variant="outline"
                        size="small"
                        onClick={handleLogout}
                        startIcon={<Logout />}
                      >
                        {t("profilePage.logOutLabel")}
                      </DBtn> */}
                        <button
                          className="flex gap-2 px-[19px] py-[10px] text-xs rounded-lg bg-[#fff] text-[#FF914D] border-[1px] border-[#FF914D] font-Comfortaa-Medium"
                          onClick={() => setShowConfirmationModal(true)}
                        >
                          <div>
                            <Logout />
                          </div>
                          {t("profilePage.logOutLabel")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {showConfirmationModal && (
                  <Modal
                    title={t("profilePage.logoutTitle")}
                    width={500}
                    onClose={(e) => {
                      e.preventDefault();
                      setShowConfirmationModal(false);
                    }}
                  >
                    <p className="my-5 text-center font-Comfortaa  ">
                      {t("profilePage.logoutSubtitle")}
                    </p>
                    <div className="w-[467px] flex  items-center justify-between border border-primary text-primary rounded-sm mt-2">
                      <button
                        onClick={handleLogout}
                        className="text-center  font-Comfortaa flex items-center justify-center  border-primary w-full px-2 py-2 cursor-pointer "
                      >
                        {t("profilePage.yesLogout")}
                      </button>
                      <button
                        className="text-center font-Comfortaa flex items-center justify-center border-l border-primary w-full px-2 py-2 bg-primary bg-opacity-10 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowConfirmationModal(false);
                        }}
                      >
                        {t("profilePage.discard")}
                      </button>
                    </div>
                  </Modal>
                )}
                {/* left end */}

                <div className=" overflow-y-auto overflow-x-hidden h-[630px] pr-3">
                  {/* center start*/}
                  <div className=" lg:mx-12 md:mx-6 xl:mx-0  ">
                    <div className="flex justify-between items-center">
                      <div>
                        <Title title={t("profilePage.personalInfoLabel")} />
                      </div>

                      <div className="flex gap-3">
                        <button onClick={() => navigate("/edit-profile")}>
                          <button className="bg-primary text-white px-4 py-2 my-2 w-auto rounded-full mx-auto  font-Comfortaa-Medium text-[14px] ">
                            {t("dishDetail.edit")}
                          </button>
                          {/* <Edit variant="contain" size="small"></Edit> */}
                        </button>
                      </div>
                    </div>
                    <div className="pt-3 ">
                      {/* first name */}
                      <div className="lg:flex gap-8">
                        <div className="relative flex items-center py-2">
                          <div className="absolute text-2xl text-primary border-r p-[12px] only: w-[50px] h-[50px] bg-transparent ">
                            <HiOutlineUser />
                          </div>
                          <label className="w-full mx-auto h-[50px] pt-[10px] capitalize text-[#ABABAB] outline-none rounded-lg ps-[67px] pe-5 font-Glacial  sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB] ">
                            {lang === "en" || lang === "en-US" ? (
                              <div className="line-clamp-1 ">
                                {profileDetails?.translations?.en?.firstName
                                  ?.length > 0 ? (
                                  <>
                                    {
                                      profileDetails?.translations?.en
                                        ?.firstName
                                    }
                                  </>
                                ) : (
                                  <>{profileDetails?.firstName}</>
                                )}
                              </div>
                            ) : (
                              <div className="line-clamp-1 ">
                                {profileDetails?.firstName}
                              </div>
                            )}
                          </label>
                        </div>
                        <div>
                          <div className="relative flex items-center py-2">
                            <div className="absolute text-2xl text-primary p-[12px] only: w-[50px] h-[50px] border-r bg-transparent ">
                              <HiOutlineUser />
                            </div>
                            <label className="w-full mx-auto h-[50px] pt-[10px] capitalize text-[#ABABAB] outline-none rounded-lg ps-[67px] pe-5    font-Glacial  sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB] ">
                              {/* {profileDetails?.lastName} */}
                              {lang === "en" || lang === "en-US" ? (
                                <div className=" line-clamp-1">
                                  {profileDetails?.translations?.en?.lastName
                                    ?.length > 0 ? (
                                    <>
                                      {
                                        profileDetails?.translations?.en
                                          ?.lastName
                                      }
                                    </>
                                  ) : (
                                    <>{profileDetails?.lastName}</>
                                  )}
                                </div>
                              ) : (
                                <div className=" line-clamp-1">
                                  {profileDetails?.lastName}
                                </div>
                              )}
                            </label>
                          </div>
                        </div>
                        <div className=" relative flex items-center py-2">
                          <div className="absolute text-2xl text-primary p-[12px] only: w-[50px] h-[50px] border-r bg-transparent ">
                            <img src="../images/Signupform/call.svg" alt="" />
                          </div>
                          <label className="w-full mx-auto h-[50px] pt-[10px] text-[#ABABAB] outline-none rounded-lg ps-[80px] pe-4 font-Glacial  sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB]">
                            {profileDetails?.mobile}
                          </label>
                        </div>
                      </div>

                      {/* mobile */}
                      <div className="lg:flex gap-8">
                        {/* email */}
                        <div className="lg:flex gap-8">
                          <div className="relative flex items-center py-2  lg:w-[374px]">
                            <div className="absolute text-2xl text-primary p-[12px]  border-r only: w-[50px] h-[50px] bg-transparent ">
                              <img src="../images/Loginform/maile.svg" alt="" />
                            </div>
                            <label className="w-full mx-auto h-[50px] pt-[10px] text-[#ABABAB] outline-none rounded-lg ps-[80px] pe-4 font-Glacial  sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB] ">
                              {profileDetails?.email}
                            </label>
                          </div>
                          <div>
                            <div className="relative flex items-center py-2">
                              <div className="absolute text-2xl text-primary p-[12px] border-r  only: w-[50px] h-[50px] bg-transparent ">
                                <img
                                  src="../images/Signupform/cake.svg"
                                  alt=""
                                />
                              </div>
                              <label className="w-full mx-auto h-[50px] pt-[10px] text-[#ABABAB] outline-none rounded-lg ps-[80px] pe-2 font-Glacial sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB] ">
                                {formatDateToDDMMMYYYY(profileDetails?.dob)}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex items-center py-2">
                        <div className="absolute text-2xl text-primary p-[12px] border-r only: w-[50px] h-[50px] bg-transparent ">
                          <Location />
                        </div>
                        <label className="w-full mx-auto h-[50px] pt-[10px] text-[#ABABAB] capitalize outline-none rounded-lg   ps-[80px]  font-Glacial  sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB] ">
                          {/* {profileDetails?.address?.addressLine1} */}

                          {lang === "en" || lang === "en-US" ? (
                            <div className=" ">
                              {profileDetails?.translations?.en?.address
                                ?.addressLine1?.length > 0 ? (
                                <>
                                  {
                                    profileDetails?.translations?.en?.address
                                      ?.addressLine1
                                  }
                                </>
                              ) : (
                                <>{profileDetails?.address?.addressLine1}</>
                              )}
                            </div>
                          ) : (
                            <div className=" ">
                              {profileDetails?.address?.addressLine1}
                            </div>
                          )}
                        </label>
                      </div>

                      {/* dob */}

                      <div>
                        <div className="relative flex items-center py-2">
                          <div className="absolute text-2xl text-primary p-[12px] border-r only: w-[50px] h-[50px] bg-transparent">
                            <HiOutlineUser />
                          </div>
                          <label
                            className={`w-full mx-auto ${
                              profileDetails?.userBio
                                ? "h-full rounded-lg p-2"
                                : "  h-[50px] rounded-lg"
                            } pt-[10px] text-[#ABABAB] outline-none  ps-[80px] pe-2 font-Glacial  sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB] `}
                          >
                            {lang === "en" || lang === "en-US" ? (
                              <div className=" xl:w-[600px]">
                                {profileDetails?.translations?.en?.userBio
                                  ?.length > 0 ? (
                                  <>
                                    {profileDetails?.translations?.en?.userBio}
                                  </>
                                ) : (
                                  <>{profileDetails?.userBio}</>
                                )}
                              </div>
                            ) : (
                              <div className=" xl:w-[600px]">
                                {profileDetails?.userBio}
                              </div>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* center end */}

                  {/* Bank  details of host */}
                  {user?.role === "host" && (
                    <>
                      <div className="mt-10 flex items-center justify-between">
                        <Title title={t("profilePage.bankDetails")} />{" "}
                        <div
                          onClick={() => setEditBankDetailsOpen(true)}
                          className={` cursor-pointer  text-primary ${
                            profileDetails?.bankAccountDetails
                              ? "block"
                              : "hidden"
                          }   bg-transparent `}
                        >
                          <button className="bg-primary text-white px-4 py-2 my-2 w-auto rounded-full mx-auto  font-Comfortaa-Medium text-[14px] ">
                            {t("dishDetail.edit")}
                          </button>
                        </div>
                      </div>
                      {profileDetails?.bankAccountDetails ? (
                        <div className=" lg:mx-12 md:mx-6 xl:mx-0 mt-3 ">
                          <div className="">
                            <div className="relative flex items-center py-2">
                              <div className="absolute text-2xl text-primary p-[12px] border-r only: w-[50px] h-[50px] bg-transparent ">
                                <HiOutlineUser />
                              </div>
                              <label className="w-full mx-auto h-[50px] pt-[10px] text-[#ABABAB] outline-none rounded-lg   ps-[80px]  font-Glacial  sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB] ">
                                {
                                  profileDetails?.bankAccountDetails
                                    ?.accountHolderName
                                }
                              </label>
                            </div>
                            <div className="relative flex items-center py-2">
                              <div className="absolute text-2xl text-primary p-[12px] border-r only: w-[50px] h-[50px] bg-transparent ">
                                <AccNo />
                              </div>
                              <label className="w-full mx-auto h-[50px] pt-[10px] text-[#ABABAB] outline-none rounded-lg   ps-[80px]  font-Glacial  sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB] ">
                                {profileDetails?.bankAccountDetails?.accountNo}
                              </label>
                            </div>
                            <div className="relative flex items-center py-2">
                              <div className="absolute text-2xl text-primary p-[12px] border-r only: w-[50px] h-[50px] bg-transparent ">
                                <Location />
                              </div>
                              <label className="w-full mx-auto h-[50px] pt-[10px] text-[#ABABAB] outline-none rounded-lg   ps-[80px]  font-Glacial  sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB] ">
                                {profileDetails?.bankAccountDetails?.bankName}
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-5">
                          <div className="relative flex items-center py-2">
                            <div className="absolute text-2xl text-primary p-[12px] border-r only: w-[50px] h-[50px] bg-transparent ">
                              <HiOutlineUser />
                            </div>
                            <label className="w-full mx-auto h-[50px] pt-[10px] text-[#ABABAB] outline-none rounded-lg   ps-[80px]  font-Glacial  sm:text-[20px] text-[18px] border border-gray-300 placeholder:text-[#ABABAB] ">
                              {t("profilePage.addBankDetails")}
                            </label>
                            <div
                              onClick={() => setBankDetailsOpen(true)}
                              className="absolute cursor-pointer right-0  p-[12px] text-primary  border-l only: w-[50px] h-[50px] bg-transparent "
                            >
                              <PlusBank />
                            </div>
                          </div>
                        </div>
                      )}

                      {bankDetailsOpen && (
                        <Modal
                          title={t("profilePage.addBankTitle")}
                          // width={800}
                          maxHeight={800}
                          onClose={() => setBankDetailsOpen(false)}
                        >
                          <div className="lg:w-full w-[300px] h-[400px] lg:h-[530px] mx-auto container font-Glacial">
                            <div className="lg:flex gap-x-5 ">
                              {/* Account holder name */}
                              <div className="lg:w-1/2">
                                <div className="border-[1px] p-[10px] rounded-[16px]  border-gray-300">
                                  <div>
                                    <p className="text-[18px] font-Glacial Indifference text-[#111]">
                                      {t("profilePage.accHolderName")}
                                      <span className="text-red-300">*</span>
                                    </p>
                                    <p className="flex">
                                      <span
                                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                      ></span>
                                    </p>
                                  </div>

                                  <div className="relative  items-center py-2">
                                    <input
                                      name="firstName"
                                      className="w-full border-0 mx-auto h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20 font-Glacial sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                      type="text"
                                      placeholder={t(
                                        "profilePage.accHolderName"
                                      )}
                                      value={bankDetails?.accountHolderName}
                                      onChange={(e) => {
                                        setBankDetails({
                                          ...bankDetails,
                                          accountHolderName: e.target.value,
                                        });
                                      }}
                                    />
                                    {lang === "en" || lang === "en-US" ? (
                                      <>
                                        {errors?.accountHolderName &&
                                          bankDetails?.accountHolderName ===
                                            "" && (
                                            <div className="text-red-300 ">
                                              {errors.accountHolderName}
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {errorsEs?.accountHolderName &&
                                          bankDetails?.accountHolderName ===
                                            "" && (
                                            <div className="text-red-300 ">
                                              {errorsEs.accountHolderName}
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* Account number */}
                              <div className="lg:w-1/2">
                                <div className="gap-x-5 ">
                                  <div>
                                    <div className="border-[1px] p-[10px] rounded-[16px]  border-gray-300">
                                      <div>
                                        <p
                                          className="text-[18px] font-Glacial Indifference
                                           text-[#111]"
                                        >
                                          {t("profilePage.accNo")}
                                          <span className="text-red-300">
                                            *
                                          </span>
                                        </p>
                                        <p className="flex">
                                          <span
                                            className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                          ></span>
                                        </p>
                                      </div>

                                      <div className="relative  items-center py-2">
                                        <input
                                          name="lastName"
                                          className="w-full mx-auto border-0 h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20    font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                          type="number"
                                          placeholder={t("profilePage.accNo")}
                                          value={bankDetails?.accountNo}
                                          onChange={(e) => {
                                            setBankDetails({
                                              ...bankDetails,
                                              accountNo: e.target.value,
                                            });
                                          }}
                                        />
                                        {lang === "en" || lang === "en-US" ? (
                                          <>
                                            {errors?.accountNo &&
                                              bankDetails?.accountNo === "" && (
                                                <div className="text-red-300 ">
                                                  {errors.accountNo}
                                                </div>
                                              )}
                                          </>
                                        ) : (
                                          <>
                                            {errorsEs?.accountNo &&
                                              bankDetails?.accountNo === "" && (
                                                <div className="text-red-300 ">
                                                  {errorsEs.accountNo}
                                                </div>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="lg:flex gap-x-5 mt-5">
                              {/*  Country */}
                              <div className="lg:w-1/2">
                                <div className="border-[1px] p-[10px] rounded-[16px] border-gray-300 ">
                                  <div>
                                    <p
                                      className="text-[18px] font-Glacial Indifference
                             text-[#111]"
                                    >
                                      {t("profilePage.country")}
                                      <span className="text-red-300">*</span>
                                    </p>
                                    <p className="flex">
                                      <span
                                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                      ></span>
                                    </p>
                                  </div>

                                  <div className="relative  items-center py-2">
                                    <input
                                      name="userBio"
                                      className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                      type="text"
                                      placeholder={t("profilePage.country")}
                                      value={bankDetails?.country}
                                      onChange={(e) => {
                                        setBankDetails({
                                          ...bankDetails,
                                          country: e.target.value,
                                        });
                                      }}
                                    />
                                    {lang === "en" || lang === "en-US" ? (
                                      <>
                                        {errors?.country &&
                                          bankDetails?.country === "" && (
                                            <div className="text-red-300 ">
                                              {errors.country}
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {errorsEs?.country &&
                                          bankDetails?.country === "" && (
                                            <div className="text-red-300 ">
                                              {errorsEs.country}
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/*  SWIFT code */}
                              <div className="lg:w-1/2">
                                <div className="border-[1px] p-[10px] rounded-[16px]  border-gray-300">
                                  <div>
                                    <p className="text-[18px] font-Glacial Indifference text-[#111]">
                                      {t("profilePage.swiftCode")}
                                      <span className="text-red-300">*</span>
                                    </p>
                                    <p className="flex">
                                      <span
                                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                      ></span>
                                    </p>
                                  </div>

                                  <div className="relative  items-center py-2">
                                    <input
                                      name="firstName"
                                      className="w-full border-0 mx-auto h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20 font-Glacial sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                      type="text"
                                      placeholder={t("profilePage.swiftCode")}
                                      value={bankDetails?.swiftCode}
                                      onChange={(e) => {
                                        setBankDetails({
                                          ...bankDetails,
                                          swiftCode: e.target.value,
                                        });
                                      }}
                                    />
                                    {lang === "en" || lang === "en-US" ? (
                                      <>
                                        {(errors?.swiftCode ||
                                          bankDetails?.swiftCode === "") && (
                                          <div className="text-red-300 ">
                                            {errors?.swiftCode}
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {(errorsEs?.swiftCode ||
                                          bankDetails?.swiftCode === "") && (
                                          <div className="text-red-300 ">
                                            {errorsEs?.swiftCode}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="lg:flex gap-x-5 mt-5 ">
                              {/* bank name */}
                              <div className="lg:w-1/2">
                                <div className="gap-x-5 ">
                                  <div>
                                    <div className="border-[1px] p-[10px] rounded-[16px]  border-gray-300">
                                      <div>
                                        <p
                                          className="text-[18px] font-Glacial Indifference
                                           text-[#111]"
                                        >
                                          {t("profilePage.bankName")}
                                          <span className="text-red-300">
                                            *
                                          </span>
                                        </p>
                                        <p className="flex">
                                          <span
                                            className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                          ></span>
                                        </p>
                                      </div>

                                      <div className="relative  items-center py-2">
                                        <input
                                          name="lastName"
                                          className="w-full mx-auto border-0 h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20    font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                          type="text"
                                          placeholder={t(
                                            "profilePage.bankName"
                                          )}
                                          value={bankDetails?.bankName}
                                          onChange={(e) => {
                                            setBankDetails({
                                              ...bankDetails,
                                              bankName: e.target.value,
                                            });
                                          }}
                                        />
                                        {lang === "en" || lang === "en-US" ? (
                                          <>
                                            {errors?.bankName &&
                                              bankDetails?.bankName === "" && (
                                                <div className="text-red-300 ">
                                                  {errors.bankName}
                                                </div>
                                              )}
                                          </>
                                        ) : (
                                          <>
                                            {errorsEs?.bankName &&
                                              bankDetails?.bankName === "" && (
                                                <div className="text-red-300 ">
                                                  {errorsEs.bankName}
                                                </div>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* IBAN */}
                              <div className="lg:w-1/2">
                                <div className="border-[1px] p-[10px] rounded-[16px] border-gray-300 ">
                                  <div>
                                    <p
                                      className="text-[18px] font-Glacial Indifference
                             text-[#111]"
                                    >
                                      IBAN
                                      <span className="text-red-300">*</span>
                                    </p>
                                    <p className="flex">
                                      <span
                                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                      ></span>
                                    </p>
                                  </div>

                                  <div className="relative  items-center py-2">
                                    <input
                                      name="userBio"
                                      className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                      type="text"
                                      placeholder="IBAN"
                                      value={bankDetails?.IBAN}
                                      onChange={(e) => {
                                        setBankDetails({
                                          ...bankDetails,
                                          IBAN: e.target.value,
                                        });
                                      }}
                                    />
                                    {lang === "en" || lang === "en-US" ? (
                                      <>
                                        {errors?.IBAN &&
                                          bankDetails?.IBAN === "" && (
                                            <div className="text-red-300 ">
                                              {errors?.IBAN}
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {errorsEs?.IBAN &&
                                          bankDetails?.IBAN === "" && (
                                            <div className="text-red-300 ">
                                              {errorsEs?.IBAN}
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {bankLoading ? (
                              <>
                                <div className=" border-primary bg-white text-[#FFF]  border-[3px] text-xs rounded-full w-[110px] h-[60px] px-4 py-2 flex mx-auto items-center justify-center mt-5">
                                  <Loader size={20} />
                                </div>
                              </>
                            ) : (
                              <button
                                type="submit"
                                onClick={handleBankDetails}
                                className="bg-primary mt-5 flex items-center justify-center text-white py-[18px] w-[120px] rounded-full mx-auto  font-Comfortaa-Medium text-[16px] capitalize"
                              >
                                {t("addNewDish.save")}
                              </button>
                            )}
                          </div>
                        </Modal>
                      )}
                      {editBankDetailsOpen && (
                        <Modal
                          title={t("profilePage.editBankTitle")}
                          // width={800}
                          scrollbarCheck={true}
                          maxHeight={800}
                          onClose={() => setEditBankDetailsOpen(false)}
                        >
                          <div className="lg:w-full w-[300px] h-[400px] lg:h-[530px] mx-auto container font-Glacial py-4">
                            <div className="lg:flex gap-x-5 ">
                              {/* Account holder name */}
                              <div className="lg:w-1/2">
                                <div className="border-[1px] p-[10px] rounded-[16px]  border-gray-300">
                                  <div>
                                    <p className="text-[18px] font-Glacial Indifference text-[#111]">
                                      {t("profilePage.accHolderName")}
                                      <span className="text-red-300">*</span>
                                    </p>
                                    <p className="flex">
                                      <span
                                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                      ></span>
                                    </p>
                                  </div>

                                  <div className="relative  items-center py-2">
                                    <input
                                      name="firstName"
                                      className="w-full border-0 mx-auto h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20 font-Glacial sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                      type="text"
                                      placeholder={t(
                                        "profilePage.accHolderName"
                                      )}
                                      value={bankDetails?.accountHolderName}
                                      onChange={(e) => {
                                        setBankDetails({
                                          ...bankDetails,
                                          accountHolderName: e.target.value,
                                        });
                                      }}
                                    />
                                    {lang === "en" || lang === "en-US" ? (
                                      <>
                                        {errors?.accountHolderName &&
                                          bankDetails?.accountHolderName ===
                                            "" && (
                                            <div className="text-red-300 ">
                                              {errors.accountHolderName}
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {errorsEs?.accountHolderName &&
                                          bankDetails?.accountHolderName ===
                                            "" && (
                                            <div className="text-red-300 ">
                                              {errorsEs.accountHolderName}
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* Account number */}
                              <div className="lg:w-1/2">
                                <div className="gap-x-5 ">
                                  <div>
                                    <div className="border-[1px] p-[10px] rounded-[16px]  border-gray-300">
                                      <div>
                                        <p
                                          className="text-[18px] font-Glacial Indifference
                                           text-[#111]"
                                        >
                                          {t("profilePage.accNo")}
                                          <span className="text-red-300">
                                            *
                                          </span>
                                        </p>
                                        <p className="flex">
                                          <span
                                            className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                          ></span>
                                        </p>
                                      </div>

                                      <div className="relative  items-center py-2">
                                        <input
                                          name="lastName"
                                          className="w-full mx-auto border-0 h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20    font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                          type="number"
                                          placeholder={t("profilePage.accNo")}
                                          value={bankDetails?.accountNo}
                                          onChange={(e) => {
                                            setBankDetails({
                                              ...bankDetails,
                                              accountNo: e.target.value,
                                            });
                                          }}
                                        />
                                        {lang === "en" || lang === "en-US" ? (
                                          <>
                                            {errors?.accountNo &&
                                              bankDetails?.accountNo === "" && (
                                                <div className="text-red-300 ">
                                                  {errors.accountNo}
                                                </div>
                                              )}
                                          </>
                                        ) : (
                                          <>
                                            {errorsEs?.accountNo &&
                                              bankDetails?.accountNo === "" && (
                                                <div className="text-red-300 ">
                                                  {errorsEs.accountNo}
                                                </div>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="lg:flex gap-x-5 mt-5">
                              {/*  Country */}
                              <div className="lg:w-1/2">
                                <div className="border-[1px] p-[10px] rounded-[16px] border-gray-300 ">
                                  <div>
                                    <p
                                      className="text-[18px] font-Glacial Indifference
                             text-[#111]"
                                    >
                                      {t("profilePage.country")}
                                      <span className="text-red-300">*</span>
                                    </p>
                                    <p className="flex">
                                      <span
                                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                      ></span>
                                    </p>
                                  </div>

                                  <div className="relative  items-center py-2">
                                    <input
                                      name="userBio"
                                      className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                      type="text"
                                      placeholder={t("profilePage.country")}
                                      value={bankDetails?.country}
                                      onChange={(e) => {
                                        setBankDetails({
                                          ...bankDetails,
                                          country: e.target.value,
                                        });
                                      }}
                                    />
                                    {lang === "en" || lang === "en-US" ? (
                                      <>
                                        {errors?.country &&
                                          bankDetails?.country === "" && (
                                            <div className="text-red-300 ">
                                              {errors.country}
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {errorsEs?.country &&
                                          bankDetails?.country === "" && (
                                            <div className="text-red-300 ">
                                              {errorsEs.country}
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/*  SWIFT code */}
                              <div className="lg:w-1/2">
                                <div className="border-[1px] p-[10px] rounded-[16px]  border-gray-300">
                                  <div>
                                    <p className="text-[18px] font-Glacial Indifference text-[#111]">
                                      {t("profilePage.swiftCode")}
                                      <span className="text-red-300">*</span>
                                    </p>
                                    <p className="flex">
                                      <span
                                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                      ></span>
                                    </p>
                                  </div>

                                  <div className="relative  items-center py-2">
                                    <input
                                      name="firstName"
                                      className="w-full border-0 mx-auto h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20 font-Glacial sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                      type="text"
                                      placeholder={t("profilePage.swiftCode")}
                                      value={bankDetails?.swiftCode}
                                      onChange={(e) => {
                                        setBankDetails({
                                          ...bankDetails,
                                          swiftCode: e.target.value,
                                        });
                                      }}
                                    />
                                    {lang === "en" || lang === "en-US" ? (
                                      <>
                                        {(errors?.swiftCode ||
                                          bankDetails?.swiftCode === "") && (
                                          <div className="text-red-300 ">
                                            {errors?.swiftCode}
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {(errorsEs?.swiftCode ||
                                          bankDetails?.swiftCode === "") && (
                                          <div className="text-red-300 ">
                                            {errorsEs?.swiftCode}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="lg:flex gap-x-5 mt-5 ">
                              {/* bank name */}
                              <div className="lg:w-1/2">
                                <div className="gap-x-5 ">
                                  <div>
                                    <div className="border-[1px] p-[10px] rounded-[16px]  border-gray-300">
                                      <div>
                                        <p
                                          className="text-[18px] font-Glacial Indifference
                                           text-[#111]"
                                        >
                                          {t("profilePage.bankName")}
                                          <span className="text-red-300">
                                            *
                                          </span>
                                        </p>
                                        <p className="flex">
                                          <span
                                            className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                          ></span>
                                        </p>
                                      </div>

                                      <div className="relative  items-center py-2">
                                        <input
                                          name="lastName"
                                          className="w-full mx-auto border-0 h-[50px] transition duration-200 outline-none rounded-lg ps-[20px] pe-20    font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                          type="text"
                                          placeholder={t(
                                            "profilePage.bankName"
                                          )}
                                          value={bankDetails?.bankName}
                                          onChange={(e) => {
                                            setBankDetails({
                                              ...bankDetails,
                                              bankName: e.target.value,
                                            });
                                          }}
                                        />
                                        {lang === "en" || lang === "en-US" ? (
                                          <>
                                            {errors?.bankName &&
                                              bankDetails?.bankName === "" && (
                                                <div className="text-red-300 ">
                                                  {errors.bankName}
                                                </div>
                                              )}
                                          </>
                                        ) : (
                                          <>
                                            {errorsEs?.bankName &&
                                              bankDetails?.bankName === "" && (
                                                <div className="text-red-300 ">
                                                  {errorsEs.bankName}
                                                </div>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* IBAN */}
                              <div className="lg:w-1/2">
                                <div className="border-[1px] p-[10px] rounded-[16px] border-gray-300 ">
                                  <div>
                                    <p
                                      className="text-[18px] font-Glacial Indifference
                             text-[#111]"
                                    >
                                      IBAN
                                      <span className="text-red-300">*</span>
                                    </p>
                                    <p className="flex">
                                      <span
                                        className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                                      ></span>
                                    </p>
                                  </div>

                                  <div className="relative  items-center py-2">
                                    <input
                                      name="userBio"
                                      className="w-full mx-auto h-[50px] border-0 outline-none rounded-lg ps-[20px] pe-20  transition duration-200  font-Glacial  sm:text-[20px] text-[12px] focus:ring-primary ring-1 focus:ring-2 ring-gray-300 focus:border-primary placeholder:text-[#ABABAB] "
                                      type="text"
                                      placeholder="IBAN"
                                      value={bankDetails?.IBAN}
                                      onChange={(e) => {
                                        setBankDetails({
                                          ...bankDetails,
                                          IBAN: e.target.value,
                                        });
                                      }}
                                    />
                                    {lang === "en" || lang === "en-US" ? (
                                      <>
                                        {errors?.IBAN &&
                                          bankDetails?.IBAN === "" && (
                                            <div className="text-red-300 ">
                                              {errors?.IBAN}
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {errorsEs?.IBAN &&
                                          bankDetails?.IBAN === "" && (
                                            <div className="text-red-300 ">
                                              {errorsEs?.IBAN}
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {bankLoading ? (
                              <>
                                <div className=" border-primary bg-white text-[#FFF]  border-[3px] text-xs rounded-full w-[110px] h-[60px] px-4 py-2 flex mx-auto items-center justify-center mt-5">
                                  <Loader size={20} />
                                </div>
                              </>
                            ) : (
                              <button
                                type="submit"
                                onClick={handleBankDetails}
                                className="bg-primary mt-5 flex items-center justify-center text-white py-[18px] w-[120px] rounded-full mx-auto  font-Comfortaa-Medium text-[16px] capitalize"
                              >
                                {t("addNewDish.save")}
                              </button>
                            )}
                          </div>
                        </Modal>
                      )}
                    </>
                  )}

                  {/* right start*/}
                  <div className="right pt-12 lg:mx-12 xl:mx-0">
                    <div className="flex justify-between items-center">
                      <div>
                        <Title title={t("profilePage.loginSecurityLabel")} />
                      </div>
                      {/* {user.role === "host" && ( */}
                      <div>
                        <DBtn
                          variant="outline"
                          size="small"
                          onClick={() => setReviewOpen(true)}
                          startIcon={<Star />}
                        >
                          {/* <span className="pt-[0px]">0</span> */}
                        </DBtn>
                      </div>
                      {/* )} */}
                    </div>
                    <div className="px-20">
                      {reviewOpen && (
                        <Modal
                          title=""
                          // scrollbarCheck={true}
                          width={1250}
                          maxHeight={800}
                          responsiveWidth="w-[700px] lg:w-[1250px] "
                          onClose={() => setReviewOpen(false)}
                        >
                          <div className="mx-20">
                            <Title title={t("profilePage.showReviews")} />
                          </div>
                          {user?.role === "host" ? (
                            <div className="grid grid-cols-1 w-[700px] lg:w-full lg:grid-cols-2 mt-5 gap-7 font-Glacial px-20  pb-10">
                              {hostReviews?.firstName === "" ? (
                                <>
                                  <p className="text-[24px] font-normal text-[#ABABAB] ">
                                    {t("profilePage.noReviews")}
                                  </p>
                                </>
                              ) : (
                                <>
                                  {hostReviews?.map((review, index) => (
                                    <div
                                      key={index}
                                      className="border w-[400px] border-primary rounded-lg px-10 py-4"
                                    >
                                      <div className="flex  space-x-4">
                                        <img
                                          src={review.profileImg}
                                          alt="Profile"
                                          className="w-[50px] h-[50px] rounded-full"
                                        />
                                        <div>
                                          <p className="text-[24px] font-normal">{`${review.firstName} ${review.lastName}`}</p>
                                          <p className="flex gap-1">
                                            {[...Array(review.ratings)].map(
                                              (_, index) => (
                                                <Star3 key={index} />
                                              )
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <p className="text-[18px] text-wrap font-normal text-[#ABABAB] ml-5 mt-2">{`${review.desc}`}</p>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          ) : (
                            <div>
                              <div className="grid grid-cols-1 w-[700px] lg:w-full lg:grid-cols-2 mt-5 gap-7 font-Glacial px-20 pb-10">
                                {guestReviews?.firstName === "" ? (
                                  <>
                                    <p className="text-[24px] font-normal text-[#ABABAB] ">
                                      {t("profilePage.noReviews")}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    {guestReviews &&
                                      Array.isArray(guestReviews) &&
                                      guestReviews.map((review, index) => (
                                        <div
                                          className="border border-primary rounded-lg px-10 py-4"
                                          key={index}
                                        >
                                          <div className="flex space-x-4">
                                            <img
                                              src={review.profileImg}
                                              alt="Profile"
                                              className="w-[50px] h-[50px] rounded-full"
                                            />
                                            <div>
                                              <p className="text-[24px] font-normal">{`${review.firstName} ${review.lastName}`}</p>
                                              <p className="flex gap-1">
                                                {[
                                                  ...Array(review?.ratings),
                                                ].map((_, index) => (
                                                  <Star3 key={index} />
                                                ))}
                                              </p>
                                            </div>
                                          </div>
                                          <p className="text-[18px] text-wrap font-normal text-[#ABABAB] ml-5 mt-2">
                                            {`${review.desc}`}
                                          </p>
                                        </div>
                                      ))}
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </Modal>
                      )}
                    </div>
                    <div className="mt-2">
                      <DBtn
                        variant="contain"
                        size="small"
                        onClick={() => setChangePasswordOpen(true)}
                        startIcon=""
                      >
                        <span className="pt-[3px]">
                          {t("profilePage.changePasswordLabel")}
                        </span>
                      </DBtn>

                      {isChangePasswordOpen && (
                        <Modal
                          title={t("profilePage.changePasswordLabel")}
                          scrollbarCheck={false}
                          width={800}
                          maxHeight={800}
                          onClose={() => setChangePasswordOpen(false)}
                        >
                          <form>
                            <div className="container mx-auto w-full rounded-[20px] px-6 py-10 overflow-y-auto h-[500px] md:h-auto">
                              <div className=" gap-x-5">
                                {/* old password */}
                                <div>
                                  <div className="relative  py-2">
                                    <div className="mb-2">
                                      <div className="absolute text-2xl text-primary p-7 border-r only: w-[80px] h-[70px] bg-transparent ">
                                        <HiOutlineUser />
                                      </div>
                                      <input
                                        name="oldPassword"
                                        className="w-full mx-auto h-[70px]  outline-none rounded-lg ps-[90px] pe-20    font-Glacial  sm:text-[20px] text-[12px] border border-gray-300 placeholder:text-[#ABABAB] "
                                        type="text"
                                        placeholder={t(
                                          "profilePage.enterOldPass"
                                        )}
                                        onChange={
                                          handleChangePasswordInputChange
                                        }
                                      />
                                    </div>
                                    {invalidError ? invalidError : ""}
                                    {lang === "en" || lang === "en-US" ? (
                                      <>
                                        {changePasswordErrors?.oldPassword &&
                                          changePassword?.oldPassword ===
                                            "" && (
                                            <div className="text-red-300 ml-4  font-Glacial">
                                              {changePasswordErrors.oldPassword}
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {changePasswordErrorsEs?.oldPassword &&
                                          changePassword?.oldPassword ===
                                            "" && (
                                            <div className="text-red-300 ml-4  font-Glacial">
                                              {
                                                changePasswordErrorsEs.oldPassword
                                              }
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>

                                {/* new password */}
                                <div>
                                  <div className="relative  py-2">
                                    <div className="mb-2">
                                      <div className="absolute text-2xl border-r text-primary p-7 only: w-[80px] h-[70px] bg-transparent ">
                                        <HiOutlineUser />
                                      </div>
                                      <input
                                        name="newPassword"
                                        className="w-full  mx-auto h-[70px]  outline-none rounded-lg ps-[90px] pe-20    font-Glacial  sm:text-[20px] text-[12px] border border-gray-300 placeholder:text-[#ABABAB] "
                                        type="text"
                                        placeholder={t(
                                          "profilePage.enterNewPass"
                                        )}
                                        onChange={
                                          handleChangePasswordInputChange
                                        }
                                      />
                                    </div>
                                    {lang === "en" || lang === "en-US" ? (
                                      <>
                                        {changePasswordErrors?.newPassword &&
                                          changePassword?.newPassword ===
                                            "" && (
                                            <div className="text-red-300 ml-4  font-Glacial">
                                              {changePasswordErrors.newPassword}
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        {changePasswordErrorsEs?.newPassword &&
                                          changePassword?.newPassword ===
                                            "" && (
                                            <div className="text-red-300 ml-4 font-Glacial">
                                              {
                                                changePasswordErrorsEs.newPassword
                                              }
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-center pt-[44px] ">
                                <button
                                  type="submit"
                                  className="bg-primary text-white py-[18px] w-[396px] rounded-lg mx-auto  font-Comfortaa-Medium text-[16px]"
                                  onClick={submitPasswordChange}
                                >
                                  {t("profilePage.changePasswordLabel")}
                                </button>
                              </div>
                            </div>
                          </form>
                        </Modal>
                      )}
                    </div>

                    <div className="pt-[24px]">
                      <Title title={t("profilePage.dataPrivacyLabel")} />
                    </div>
                    <div className="w-[300px] md:w-[586px] lg:w-[700px] font-Glacial pt-[16px]">
                      {lang === "en" || lang === "en-US" ? (
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using Lorem Ipsum
                          is that it has a more-or-less normal distribution of
                          letters, as opposed to using.
                        </p>
                      ) : (
                        <p>
                          Es un hecho establecido desde hace mucho tiempo que un
                          lector se distraerá con el contenido legible de una
                          página cuando mire su diseño. El objetivo de utilizar
                          Lorem Ipsum es que tiene una distribución de letras
                          más o menos normal, a diferencia del uso.
                        </p>
                      )}
                    </div>

                    <div className="xl:hidden block">
                      {/* Role */}
                      <div className="pt-[24px]">
                        <Title title={t("profilePage.switchProfileLabel")} />
                      </div>
                      <div className="relative flex items-center py-2 ">
                        <div className="host absolute z-10 text-2xl text-primary p-3 border-r only: w-[50px] h-[50px] bg-transparent ">
                          {profileData?.role === "guest" ? (
                            <img src="../images/Signupform/guest.svg" alt="" />
                          ) : (
                            <img
                              className="p-1"
                              src="../images/Signupform/host.svg"
                              alt=""
                            />
                          )}
                        </div>
                        <fieldset className="w-full">
                          <div className="guestselection relative  rounded border bg-transparent ">
                            <div className="SignUpFormearance-none w-44 h-[50px]  outline-none rounded-full ps-[80px] py-2 cursor-pointer text-primary font-Glacial sm:text-[20px] text-[18px] bg-white">
                              {user?.role}
                            </div>
                          </div>
                        </fieldset>
                      </div>

                      {loadingSwitchRole ? (
                        <div className=" border-primary bg-white text-[#FFF]  border-[3px] text-xs rounded-full w-[100px] h-[35px] px-4 py-2 my-2">
                          <Loader size={17} />
                        </div>
                      ) : (
                        <button
                          onClick={handleRoleChange}
                          type="button"
                          className="bg-primary text-white px-4 py-2 my-2 w-auto rounded-full mx-auto  font-Comfortaa-Medium text-[12px]"
                        >
                          {t("profilePage.switchNowLabel")}
                        </button>
                      )}
                    </div>
                  </div>
                  {/* right end */}
                </div>
                {/* right end */}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
