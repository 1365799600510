import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Title from "../components/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import DishCategory from "../components/Dishcategory";
import Dish1 from "../components/DishHome";
import DBtn from "../components/DBtn";
import FilterSVG from "../svg/FilterSVG";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Filter from "../components/Filter";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  getBanners,
  getDishList,
  getFoodCategories,
  getHostDishList,
} from "../services/dishService";
import { useSelector } from "react-redux";
import { PER_PAGE } from "../utils/constants/commonConstant";
import FAQAccordion from "../components/FAQAccordion";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShowCookies from "../components/ShowCookies";
import CardBecomeHost from "../components/CardBecomeHost";
import Become1 from "../svg/Become1";
import Become2 from "../svg/Become2";
import Become3 from "../svg/Become3";
import CardHowItWorks from "../components/CardHowItWorks";
import HowIt1 from "../svg/HowIt1";
import HowIt2 from "../svg/HowIt2";
import HowIt3 from "../svg/HowIt3";
import HowIt4 from "../svg/HowIt4";
import StepsCard from "../components/StepsCard";
import SignUpForm from "../components/SignUpForm";
const Home = () => {
  const { user } = useSelector((state) => state.auth);
  // console.log("user",user)
  const navigate = useNavigate();
  // console.log("USER: ",useSelector((state)=>state?.auth?.user?.id))
  const currentLocation = JSON.parse(localStorage.getItem("current_location"));
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const [banners, setBanners] = useState([]);
  const [buttonVisible, setButtonVisible] = useState(true);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [foodCategories, setFoodCategories] = useState([]);
  const [dishList, setDishList] = useState([]);
  const [flag, setFlag] = useState(false);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filters, setFilters] = useState({
    pageNo: 1,
    search: "",
    lowerPriceRange: "",
    higherPriceRange: "",
    superHostFilter: false,
    sort: "",
    foodTypeFilter: [],
    mealTimingsFilter: [],
    dateFilter: "",
    foodClass: "",
    ratingsFilter: [],
    longitude: "",
    latitude: "",
  });
  const [signupOpen, setSignUpOpen] = useState(false);

  // console.log("selectedCategory", selectedCategory);
  useEffect(() => {
    setFlag(true);
  }, []);

  // useEffect(() => {
  //   setFilters({
  //     ...filters,
  //     latitude: currentLocation?.lati,
  //     longitude: currentLocation?.long,
  //   });
  //   // eslint-disable-next-line
  // }, []);

  const getBannerImage = async () => {
    try {
      setLoading(true);
      const resp = await getBanners();
      if (resp?.success) {
        setBanners(resp.data || []);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBannerImage();
  }, []);

  const getFoodCategoriesData = async () => {
    try {
      setLoading(true);
      const resp = await getFoodCategories();
      // console.log("getFoodCategoriesData",resp);

      if (resp.success) {
        setFoodCategories(resp.data || []);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFoodCategoriesData();
  }, []);

  const getDishListData = async () => {
    let guestPayload = {
      pageNo: filters?.pageNo,
      perPage: PER_PAGE,
      // userId: user?.id,
    };
    if (filters?.search) {
      guestPayload.search = filters.search;
    }
    if (filters?.lowerPriceRange) {
      guestPayload.lowerPriceRange = filters.lowerPriceRange;
    }

    if (filters?.higherPriceRange) {
      guestPayload.higherPriceRange = filters.higherPriceRange;
    }
    if (filters?.superHostFilter) {
      guestPayload.superHostFilter = filters.superHostFilter;
    }
    if (filters?.sort) {
      guestPayload.sort = filters.sort;
    }
    if (filters?.foodTypeFilter?.length > 0) {
      guestPayload.foodTypeFilter = JSON.stringify(
        filters.foodTypeFilter?.map((val) => val?.value)
      );
    }

    if (filters?.mealTimingsFilter?.length > 0) {
      guestPayload.mealTimingsFilter = JSON.stringify(
        filters.mealTimingsFilter?.map((val) => val?.value)
      );
    }
    if (filters?.dateFilter?.length > 0) {
      guestPayload.dateFilter = JSON.stringify(filters.dateFilter);
    }
    if (filters?.foodClass?.length > 0) {
      guestPayload.foodClass = JSON.stringify(
        filters.foodClass?.map((val) => val?.value)
      );
    }
    if (filters?.ratingsFilter) {
      guestPayload.ratingsFilter = filters.ratingsFilter[0]?.value;
    }
    if (filters?.latitude) {
      guestPayload.latitude = filters.latitude;
    }
    if (filters?.longitude) {
      guestPayload.longitude = filters.longitude;
    }

    const hostPayload = {
      pageNo: filters?.pageNo,
      perPage: 100,
    };
    if (filters?.foodTypeFilter?.length > 0) {
      hostPayload.foodTypeCategoryFilter = JSON.stringify(
        filters.foodTypeFilter?.map((val) => val?.value)
      );
    }
    if (filters?.foodClass?.length > 0) {
      hostPayload.foodClassFilter = JSON.stringify(
        filters.foodClass?.map((val) => val?.value)
      );
    }

    try {
      if (!dishList) {
        setLoading(true);
      }

      const resp =
        user?.role === "host"
          ? await getHostDishList(hostPayload)
          : await getDishList(guestPayload);

      if (resp.success) {
        const totalData = dishList?.length
          ? user?.role === "host"
            ? dishList.concat(resp.data)
            : dishList.concat(resp.data?.data?.listData)
          : user?.role === "host"
          ? resp.data
          : resp.data?.data?.listData;
        setDishList(
          // totalData?.length > 0
          //   ? totalData?.filter(
          //       (arr, index, self) =>
          //         index === self.findIndex((t) => t._id === arr._id)
          //     )
          //   : []
          totalData
        );
        setTotal(resp?.data?.pagination?.totalCount);
      } else {
        setDishList([]);
      }
    } catch (err) {
      console.log("err", err);
      setButtonVisible(false);
    } finally {
      setLoading(false);
      setLoadingLoadMore(false);
      setLoadingCategory(false);
    }
  };

  useEffect(() => {
    if (flag) {
      setFlag(false);
      getDishListData();
    }
    // eslint-disable-next-lines
  }, [flag]);

  const handleFilterClick = () => {
    setPopupOpen(!isPopupOpen);
    setDishList([]);
  };

  const res = {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true,
    effect: "fade",
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    loop: true,
    modules: [Autoplay, EffectFade],
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNearMe = () => {
    setFilters({
      ...filters,
      longitude: currentLocation?.long,
      latitude: currentLocation?.lati,
    });
    setDishList([]);
    setFlag(true);
    window.scrollTo({ top: 1170, behavior: "smooth" });
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    setLoadingCategory(true);
    if (searchQuery?.length > 2) {
      setFilters({
        ...filters,
        search: searchQuery,
      });
      setDishList([]);
      setFlag(true);
      window.scrollTo({ top: 1170, behavior: "smooth" });
    }
  };

  const handlechangeCategory = (categoryId, label) => {
    setLoadingCategory(true);
    setFilters({
      ...filters,
      foodTypeFilter: [{ value: categoryId, label: label }],
    });
    setDishList([]);
    setFlag(true);
    setSelectedCategory(categoryId);
  };

  const handleClearFilters = () => {
    setSelectedCategory(null);
    setFlag(true);
    setFilters({
      ...filters,
      search: "",
      lowerPriceRange: "",
      higherPriceRange: "",
      superHostFilter: false,
      sort: "",
      foodTypeFilter: [],
      mealTimingsFilter: [],
      dateFilter: [],
      foodClass: [],
      ratingsFilter: [],
      latitude: "",
      longitude: "",
    });
  };
  // console.log("handleClearFilters",filters)

  // useEffect(() => {
  //   console.log("Filters :", filters);
  // }, [filters]);

  useEffect(() => {
    // reset scroll position when component updates (page changes)
    window.scrollTo(0, 0);
  }, [loading]);

  console.log("user", user);

  return (
    <>
      <div className="">
        <div className="container mx-auto w-full">
          <ShowCookies />
          <Header
            onNearMeClick={handleNearMe}
            searchQuery={searchQuery}
            onInputChange={handleInputChange}
            onKeyPress={handleKeyPress}
            onSearch={handleSearch}
          />
          {/* <div className="border-b-[1px] border-[#FF914D]"></div> */}
        </div>

        {loading ? (
          <div className="flex justify-center items-center m-auto h-[50vh] ">
            <Loader />
          </div>
        ) : (
          <div>
            {/* banner slider */}
            {/* <div className="banner-container">
              <Swiper {...res}>
                {banners.map((banner, index) => (
                  <SwiperSlide key={index}>
                    <div key={banner?._id}>
                      <div className="relative ">
                        <img
                          className="w-full h-[60vh] box-border object-cover"
                          src={banner?.bannerImage}
                          // alt={banner?.bannerName}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div> */}

            <div className="relative flex items-center justify-center banner-container  lg:h-[800px]">
              <img
                src="../images/header/banner_image.png"
                className="w-full h-[500px] lg:h-full object-cover"
              />
              <div className="absolute bottom-[1px] h-60 lg:h-96 w-full container mx-auto backdrop-blur-sm bg-white/5 rounded-tl-3xl rounded-tr-3xl">
                {lang === "en" || lang === "en-US" ? (
                  <div className="p-2 lg:p-10">
                    <div className="font-Comfortaa font-bold text-white text-lg md:text-3xl lg:text-4xl 2xl:text-6xl 2xl:leading-[66px] ">
                      Share Your Passion for Cooking and Earn Additional Income
                    </div>
                    <div className="text-white font-Glacial text-base md:text-xl lg:text-2xl 2xl:text-[40px] font-normal lg:leading-[47.96px] mt-3">
                      Join our community of hosts and offer unique culinary
                      experiences from the comfort of your home
                    </div>
                    {user === null && (
                      <div className="flex items-center justify-center ">
                        <button
                          className="w-[200px] h-[60px] rounded-full mt-3 bg-primary text-white font-Comfortaa text-base"
                          onClick={() => setSignUpOpen(true)}
                        >
                          Sign up for free
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="p-2 lg:p-10">
                    <div className="font-Comfortaa font-bold text-white text-lg md:text-3xl lg:text-4xl 2xl:text-6xl 2xl:leading-[66px] ">
                      Comparte tu pasión por la cocina y gana ingresos
                      adicionales
                    </div>
                    <div className="text-white font-Glacial text-base md:text-xl lg:text-2xl 2xl:text-[40px] font-normal lg:leading-[47.96px] mt-3">
                      Únete a nuestra comunidad de anfitriones y ofrece
                      experiencias culinarias únicas desde la comodidad de tu
                      hogar.
                    </div>
                    {user === null && (
                      <div className="flex items-center justify-center ">
                        <button
                          className="w-[200px] h-[60px] rounded-full mt-3 bg-primary text-white font-Comfortaa text-base"
                          onClick={() => setSignUpOpen(true)}
                        >
                          Registrate gratis
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="container mx-auto my-[100px]">
              {lang === "en" || lang === "en-US" ? (
                <Title title="Why become a host on MyDish4U?" />
              ) : (
                <Title title="¿Por qué convertirse en un anfitrión en MyDish4U?" />
              )}
              <div className="grid gap-5 lg:grid-cols-3 mt-10">
                {lang === "en" || lang === "en-US" ? (
                  <CardBecomeHost
                    svgIcon={<Become1 />}
                    title="Earn additional Income"
                    detail="Offer culinary experiences from home."
                  />
                ) : (
                  <CardBecomeHost
                    svgIcon={<Become1 />}
                    title="Obtenga ingresos adicionales"
                    detail="Ofrecer experiencias culinarias desde casa."
                  />
                )}
                {lang === "en" || lang === "en-US" ? (
                  <CardBecomeHost
                    svgIcon={<Become2 />}
                    title="Total Flexibility"
                    detail="You decide when and how to host guests."
                  />
                ) : (
                  <CardBecomeHost
                    svgIcon={<Become2 />}
                    title="Flexibilidad total"
                    detail="Tú decides cuándo y cómo recibir invitados."
                  />
                )}
                {lang === "en" || lang === "en-US" ? (
                  <CardBecomeHost
                    svgIcon={<Become3 />}
                    title="Share your passion"
                    detail="Showcase your cooking skills and meet new people."
                  />
                ) : (
                  <CardBecomeHost
                    svgIcon={<Become3 />}
                    title="Comparte tu pasión"
                    detail="Demuestra tus habilidades culinarias y conoce gente nueva."
                  />
                )}
              </div>
            </div>

            <div className="container mx-auto my-[80px]">
              {lang === "en" || lang === "en-US" ? (
                <Title title="How it works" />
              ) : (
                <Title title="Cómo funciona" />
              )}
              <div className="mt-10">
                {lang === "en" || lang === "en-US" ? (
                  <CardHowItWorks
                    imageSrc="../images/Homepage/h1pic.png"
                    svgIcon={<HowIt1 />}
                    title="1. Sign Up"
                    desc="Create your account in minutes."
                  />
                ) : (
                  <CardHowItWorks
                    imageSrc="../images/Homepage/h1pic.png"
                    svgIcon={<HowIt1 />}
                    title="1. Inscribete"
                    desc="Crea tu cuenta en minutos."
                  />
                )}
                {lang === "en" || lang === "en-US" ? (
                  <CardHowItWorks
                    imageSrc="../images/Homepage/h2pic.png"
                    svgIcon={<HowIt2 />}
                    title="2. Set up"
                    desc="Add your specialties and availability."
                  />
                ) : (
                  <CardHowItWorks
                    imageSrc="../images/Homepage/h2pic.png"
                    svgIcon={<HowIt2 />}
                    title="2. Configura"
                    desc="Añade tus especialidades y disponibilidad."
                  />
                )}
                {lang === "en" || lang === "en-US" ? (
                  <CardHowItWorks
                    imageSrc="../images/Homepage/h3pic.png"
                    svgIcon={<HowIt3 />}
                    title="3. Publish"
                    desc="Add your specialties and availability."
                  />
                ) : (
                  <CardHowItWorks
                    imageSrc="../images/Homepage/h3pic.png"
                    svgIcon={<HowIt3 />}
                    title="3. Publica"
                    desc="Añade tus especialidades y disponibilidad."
                  />
                )}
                {lang === "en" || lang === "en-US" ? (
                  <CardHowItWorks
                    imageSrc="../images/Homepage/h4pic.png"
                    svgIcon={<HowIt4 />}
                    title="4. Receive bookings"
                    desc="Manage your bookings easily."
                  />
                ) : (
                  <CardHowItWorks
                    imageSrc="../images/Homepage/h4pic.png"
                    svgIcon={<HowIt4 />}
                    title="4. Recibe reservas"
                    desc="Gestiona tus reservas fácilmente."
                  />
                )}
              </div>
            </div>

            <div className="container mx-auto my-[100px]">
              {lang === "en" || lang === "en-US" ? (
                <Title title="Easy 4 steps" />
              ) : (
                <Title title="Fáciles 4 pasos" />
              )}
              <div className="relative h-[1px] bg-primary mt-10">
                <button className="font-Comfortaa text-[18px] font-semibold absolute left-0 top-1/2 transform -translate-y-1/2 w-10 h-10 bg-white border border-primary rounded-full">
                  1
                </button>
                <button className="font-Comfortaa text-[18px] font-semibold absolute left-1/4 top-1/2 transform -translate-y-1/2 w-10 h-10 bg-white border border-primary rounded-full">
                  2
                </button>
                <button className="font-Comfortaa text-[18px] font-semibold absolute left-2/4 top-1/2 transform -translate-y-1/2 w-10 h-10 bg-white border border-primary rounded-full">
                  3
                </button>
                <button className="font-Comfortaa text-[18px] font-semibold absolute left-3/4 top-1/2 transform -translate-y-1/2 w-10 h-10 bg-white border border-primary rounded-full">
                  4
                </button>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mt-9 gap-5">
                {lang === "en" || lang === "en-US" ? (
                  <>
                    <StepsCard
                      title="Sign Up"
                      svgIcon="../images/Homepage/st1.png"
                    />
                    <StepsCard
                      title="Set Up"
                      svgIcon="../images/Homepage/st2.png"
                    />
                    <StepsCard
                      title="Publish"
                      svgIcon="../images/Homepage/st3.png"
                    />
                    <StepsCard
                      title="Receive bookings"
                      svgIcon="../images/Homepage/st4.png"
                    />
                  </>
                ) : (
                  <>
                    <StepsCard
                      title="Inscribete"
                      svgIcon="../images/Homepage/st1.png"
                    />
                    <StepsCard
                      title="Configura"
                      svgIcon="../images/Homepage/st2.png"
                    />
                    <StepsCard
                      title="Publica"
                      svgIcon="../images/Homepage/st3.png"
                    />
                    <StepsCard
                      title="Recibe reservas"
                      svgIcon="../images/Homepage/st4.png"
                    />
                  </>
                )}
              </div>
            </div>
            {/* food category */}
            <div className="container mx-auto ">
              <div className="my-[44px] ">
                <div className="flex justify-between items-start">
                  <Title title={t("homePage.foodCategory")} />
                  <div>
                    {user?.role !== "host" && (
                      <>
                        <div className=" ">
                          {(filters?.foodTypeFilter?.length > 0 ||
                            filters?.mealTimingsFilter?.length > 0 ||
                            filters?.foodClass?.length > 0 ||
                            filters?.ratingsFilter?.length > 0 ||
                            filters?.superHostFilter === true) && (
                            <div className="relative">
                              {/* Display the count */}
                              <span className="absolute top-0 right-0 text-xs w-5 h-5 text-white bg-primary rounded-full p-[1px] text-center">
                                {filters?.foodTypeFilter?.length +
                                  filters?.mealTimingsFilter?.length +
                                  filters?.foodClass?.length +
                                  filters?.ratingsFilter?.length +
                                  (filters?.superHostFilter ? 1 : 0)}
                              </span>
                            </div>
                          )}
                        </div>

                        <DBtn
                          variant="default"
                          size="large"
                          onClick={handleFilterClick}
                          startIcon={<FilterSVG />}
                        >
                          {t("homePage.filters")}
                        </DBtn>
                      </>
                    )}

                    {isPopupOpen && (
                      <div className="z-50 fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white w-[604px] px-[40px] py-[40px]  rounded-[20px] relative">
                          <div className="flex justify-between">
                            <Title title={t("homePage.filters")} />
                            {(filters?.foodTypeFilter?.length > 0 ||
                              filters?.mealTimingsFilter?.length > 0 ||
                              filters?.foodClass?.length > 0 ||
                              filters?.ratingsFilter?.length > 0 ||
                              filters?.superHostFilter === true) && (
                              <DBtn
                                variant="default"
                                size="small"
                                onClick={handleClearFilters}
                              >
                                {t("homePage.clearFilter")}
                              </DBtn>
                            )}
                          </div>
                          <button
                            className="absolute right-3 top-2 z-50"
                            onClick={() => {
                              setFlag(true);
                              setPopupOpen(false);
                            }}
                          >
                            <div className="w-[40px] h-[40px] bg-[#FF914D1A] p-[13px] text-primary rounded-full">
                              <AiOutlineClose />
                            </div>
                          </button>
                          <p>
                            <Filter
                              filters={filters}
                              setFilters={setFilters}
                              setFlag={setFlag}
                              onClose={() => setPopupOpen(false)}
                            />
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-2  container mx-auto">
                {/*}
                {user?.role === "host" ? (
                  <>
                    <Slider {...settings}>
                      {foodCategories.map((food, index) => {
                        return (
                          <div key={index}>
                            <DishCategory
                              food={food}
                              handleClick={() => console.log("clicked")}
                            />
                          </div>
                        );
                      })}
                      {foodCategories.map((food, index) => {
                        return (
                          <div key={index}>
                            <DishCategory
                              food={food}
                              handleClick={() => console.log("clicked")}
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  </>
                ) : (
                */}
                <>
                  {lang === "en" || lang === "en-US" ? (
                    <>
                      <Slider
                        {...settings}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        {foodCategories.map((food, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                handlechangeCategory(
                                  food._id,
                                  food?.translations?.en?.foodType
                                )
                              }
                            >
                              <DishCategory
                                food={food}
                                isSelected={
                                  food?._id ? selectedCategory === food._id : ""
                                }
                                handleClick={handlechangeCategory}
                              />
                            </div>
                          );
                        })}
                        {foodCategories.map((food, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                handlechangeCategory(
                                  food._id,
                                  food?.translations?.en?.foodType
                                )
                              }
                            >
                              <DishCategory
                                food={food}
                                isSelected={
                                  food?._id ? selectedCategory === food._id : ""
                                }
                                handleClick={handlechangeCategory}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </>
                  ) : (
                    <>
                      <Slider
                        {...settings}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        {foodCategories.map((food, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                handlechangeCategory(food._id, food?.foodType)
                              }
                            >
                              <DishCategory
                                food={food}
                                isSelected={
                                  food?._id ? selectedCategory === food._id : ""
                                }
                                handleClick={handlechangeCategory}
                              />
                            </div>
                          );
                        })}
                        {foodCategories.map((food, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                handlechangeCategory(food._id, food?.foodType)
                              }
                            >
                              <DishCategory
                                food={food}
                                isSelected={
                                  food?._id ? selectedCategory === food._id : ""
                                }
                                handleClick={handlechangeCategory}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </>
                  )}
                </>
                {/* )} */}
              </div>
            </div>

            {/* dishes for you */}
            <div className="container mx-auto w-[] mt-[40px] mb-[220px]">
              <Title
                title={
                  user?.role === "host"
                    ? t("homePage.myDishes")
                    : t("homePage.dishForYou")
                }
              />
              {loadingCategory ? (
                <div className="flex justify-center items-center m-auto h-[50vh] ">
                  <Loader />
                </div>
              ) : (
                <>
                  {dishList?.length === 0 ? (
                    <div className="flex justify-center w-full font-Comfortaa">
                      <div>
                        <div>
                          <img
                            src="../images/Data-Not-Found.jpg"
                            alt=""
                            className="w-32 flex mx-auto"
                          />
                        </div>
                        <div className=" w-full">
                          <p className="text-center pt-[30px] mb-[15px] text-red-500">
                            No dishes available.
                          </p>
                          {user?.role === "host" ? (
                            <span
                              className="text-center pt-[40px] text-red-300  cursor-pointer"
                              onClick={() => navigate("/add-dish")}
                            >
                              <DBtn variant="contain" size="small">
                                Click here to add.
                              </DBtn>
                            </span>
                          ) : (
                            " "
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="my-[40px] grid grid-cols-1 mx-0 sm:mx-20 lg:mx-0 lg:grid-cols-2  2xl:grid-cols-3  justify-between gap-y-10 gap-x-0">
                      {dishList?.map((item, index) => {
                        return (
                          <Dish1
                            dish={item}
                            handleEditClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              navigate(`/edit-dish/${item._id}`);
                              return false;
                            }}
                            handleCardClick={(e) => {
                              e.preventDefault();
                              navigate(`/dish-details/${item._id}`);
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </>
              )}

              {dishList?.length !== total &&
                dishList?.length > 0 &&
                total > 5 &&
                buttonVisible && (
                  <button
                    block
                    type="button"
                    onClick={() => {
                      setLoadingLoadMore(true);
                      setLoadingCategory(false);
                      setFilters({
                        ...filters,
                        pageNo: filters.pageNo + 1,
                      });
                      setFlag(true);
                    }}
                    className={`flex mx-auto`}
                  >
                    {loadingLoadMore ? (
                      <>
                        <div className="border-primary bg-white text-[#FFF] flex items-center justify-center  border-[3px] text-xs rounded-full w-[200px] h-[50px] px-4 py-2 ">
                          <Loader size={28} />
                        </div>
                      </>
                    ) : (
                      <button className="font-Glacial px-[26px] py-[10px] text-xl rounded-full bg-[#FF914D] border-[3px] border-[#FF914D] text-[#FFF]  ">
                        {t("homePage.loadMoreDishes")}
                      </button>
                    )}
                  </button>
                )}

              <div className="mt-20">
                <div className="container mx-auto">
                  <p className="text-2xl font-Glacial text-[#111]">
                    {t("homePage.faqs")}
                  </p>
                  <p className="flex">
                    <span
                      className={`inline-block h-[2px] w-[24px] bg-[#FF914D] `}
                    ></span>
                  </p>
                </div>
                <div>
                  <FAQAccordion />
                </div>
              </div>
            </div>

            {signupOpen && (
              <div className="z-50 fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
                <div className="bg-white  rounded-[20px] relative">
                  <button
                    className="absolute right-6 top-1 z-50"
                    onClick={() => setSignUpOpen(false)}
                  >
                    <div className="w-[40px] h-[40px] bg-[#FF914D1A] p-[13px] text-primary rounded-full">
                      <AiOutlineClose />
                    </div>
                  </button>
                  <p>
                    <SignUpForm
                      handleSignupModalOpen={() => {
                        setSignUpOpen(false);
                        // setLoginOpen(true);
                      }}
                    />
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default Home;
