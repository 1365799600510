import React from "react";
import Select from "react-select";

const SideNavSelect = ({
  value,
  options,
  icon,
  onLocationChange,
  placeholder,
}) => {
  return (
    <>
      <div className=" font-Glacial bg-white flex justify-start items-center w-full  top-[120px] cursor-pointer">
        <div
          className={`flex relative  items-center w-full h-[50px] text-base rounded-lg outline-none  px-2   active:border-accessiblePurple focus:border-accessiblePurple border border-dark`}
        >
          {icon && <div className="mr-[13px] absolute z-30">{icon}</div>}

          <Select
            value={value}
            onChange={onLocationChange}
            options={options}
            placeholder={placeholder}
            // isMulti={false}
            className={`font-Glacial w-full text-[18px] text-start ml-8   rounded-lg cursor-pointer`}
            components={{
              IndicatorSeparator: () => null,
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#FF914D",
                primary: "#FF914D",
                border: 0,
              },
            })}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: "none",
                borderColor: "#0E0D0D",
                borderRadius: "8px",
                cursor: "pointer",
                boxShadow: "none",
              }),
              menu: (styles) => ({
                ...styles,
                padding: "10px",
                color: "#0E0D0D",
                cursor: "pointer",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }),
              menuList: (styles) => ({
                ...styles,
                border: 1,
                borderColor: "#0E0D0D",
                width: "100%",
                cursor: "pointer",
                maxHeight: "300px",
                borderRadius: "8px",
              }),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SideNavSelect;
