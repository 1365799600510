import React from "react";
import { useTranslation } from "react-i18next";

const OffersCard = ({ offers, offersEn }) => {
  const lang = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  return (
    <>
      <div className="container mx-auto mt-[10px]">
        <p className="font-Glacial text-md text-[#ABABAB]">
          {t("dishDetail.whatHostOffer")}
        </p>
        <div className="lg:flex gap-x-6 mt-1 sm:grid sm:grid-cols-3 ">
          {lang === "en" || lang === "en-US" ? (
            <div className=" lg:flex gap-x-6  sm:grid sm:grid-cols-3 ">
              {offersEn?.length === 0 ? (
                <div className="lg:flex gap-x-6  sm:grid sm:grid-cols-3  ">
                  {offers?.map((offer, index) => {
                    return (
                      <div key={index} className=" flex items-center gap-x-2">
                        <div className="h-[12px] w-[12px] rounded-[0px] border-none bg-[#D9D9D9]"></div>
                        <span className="font-Glacial text-lg text-[#111]">
                          {offer}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <>
                  {offersEn?.map((offer, index) => {
                    return (
                      <div key={index} className=" flex items-center gap-x-2">
                        <div className="h-[12px] w-[12px] rounded-[0px] border-none bg-[#D9D9D9]"></div>
                        <span className="font-Glacial text-lg text-[#111]">
                          {offer}
                        </span>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          ) : (
            <div className="lg:flex gap-x-6 mt-4 sm:grid sm:grid-cols-3  ">
              {offers?.map((offer, index) => {
                return (
                  <div key={index} className=" flex items-center gap-x-2">
                    <div className="h-[12px] w-[12px] rounded-[0px] border-none bg-[#D9D9D9]"></div>
                    <span className="font-Glacial text-lg text-[#111]">
                      {offer}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className="h-[1px] w-full bg-[#ABABAB] mt-4 mb-6 "></div>
      </div>
    </>
  );
};

export default OffersCard;
