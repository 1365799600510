import React from 'react'

const CalendarBtn2 = ({ title = ""}) => {
    return (
      <div className="container mx-auto">
        <div className="container mx-auto">
          <button className="mt-5 border-[1px] text-black bg-[#F2F4F6] py-[11px] px-[24px] rounded-[6px] flex items-center gap-x-2 text-[16px] font-Glacial">
            <p>{title}</p>
          </button>
        </div>
      </div>
    );
  };

export default CalendarBtn2