import React from "react";
import { MdStarRate } from "react-icons/md";

const Review = () => {
  return (
    <div className="mt-[24px] p-3  border-[1px] border-[#FF914D] rounded-[6px] w-full ">
      <div className="flex gap-x-[10px]">
        <div className="w-[32px]">
          <img src="../images/review/man.png" alt="" />
        </div>
        <div>
          <p className="text-[16px] font-Glacial">Tarsariya Darshan</p>
          <div className="flex gap-x-1 text-primary">
            <MdStarRate />
            <MdStarRate />
            <MdStarRate />
            <MdStarRate />
            <MdStarRate />
          </div>
        </div>
      </div>
      <p className="font-Glacial w-full text-[12px] text-[#ABABAB] pt-3">
        Contrary to popular belief, Lorem Ipsum is not simply random text. It
        has roots in a piece of classical Latin literature from making it over
        2000 years old.
      </p>
    </div>
  );
};

export default Review;
