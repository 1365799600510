import React from "react";

const CompleteBookings = () => {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.73256 5.35927L7.16616 8.43738L6.26773 7.36023C6.18373 7.25943 6.0807 7.17616 5.96452 7.11518C5.84833 7.0542 5.72128 7.0167 5.59061 7.00482C5.45993 6.99294 5.3282 7.00691 5.20293 7.04594C5.07765 7.08498 4.96129 7.1483 4.86049 7.2323C4.75969 7.3163 4.67643 7.41933 4.61544 7.53551C4.55446 7.65169 4.51696 7.77875 4.50508 7.90942C4.4932 8.04009 4.50718 8.17183 4.54621 8.2971C4.58524 8.42237 4.64856 8.53873 4.73256 8.63953L6.39858 10.6395C6.49222 10.7522 6.6095 10.8429 6.74212 10.9052C6.87473 10.9675 7.01942 10.9998 7.16593 10.9999C7.31244 11 7.45717 10.9678 7.58987 10.9057C7.72256 10.8436 7.83996 10.7531 7.93374 10.6405L11.2677 6.64052C11.3525 6.53979 11.4165 6.42333 11.4561 6.29781C11.4957 6.17228 11.5102 6.04017 11.4986 5.90906C11.487 5.77794 11.4497 5.65039 11.3887 5.53374C11.3278 5.41709 11.2443 5.31362 11.1433 5.22928C11.0422 5.14494 10.9255 5.08138 10.7998 5.04226C10.6742 5.00313 10.542 4.98921 10.4109 5.00129C10.2799 5.01336 10.1525 5.0512 10.0361 5.11263C9.91964 5.17407 9.8165 5.25788 9.73256 5.35927Z"
          fill="#0F9E46"
        />
        <path
          d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87896 15.1542 3.84544 13.6544 2.34564C12.1546 0.845839 10.121 0.00225883 8 0ZM8 14C6.81331 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19974 1.88378 7.99334 2.11529 6.82946C2.3468 5.66557 2.91825 4.59647 3.75736 3.75736C4.59648 2.91824 5.66558 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45672C11.3925 2.91085 12.3295 3.67988 12.9888 4.66658C13.6481 5.65327 14 6.81331 14 8C13.9983 9.59076 13.3656 11.1159 12.2407 12.2407C11.1159 13.3656 9.59077 13.9983 8 14Z"
          fill="#0F9E46"
        />
      </svg>
    </div>
  );
};

export default CompleteBookings;
