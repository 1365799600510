import api from "./Api";

// guest upcoming booking
export const upcomingGuestBooking = (data) => {
  return api.post("/guest/get-upcoming-bookings", data);
};

// guest cancel booking
export const cancelGuestBooking = (data) => {
  return api.post("/guest/get-cancelled-bookings", data);
};

export const cancelGuestBookingById = (id) => {
  return api.put(`/guest/cancel-booking?id=${id}`);
};

// guest comlpete booking
export const completeGuestBooking = (data) => {
  return api.post("/guest/get-booking-history", data);
};

// host upcoming booking
export const upcomingHostBooking = (data) => {
  return api.post("/host/get-upcoming-bookings", data);
};

// host  cancel booking
export const cancelHostBooking = (data) => {
  return api.post("/host/get-cancelled-bookings", data);
};

// host comlpete booking
export const completeHostBooking = (data) => {
  return api.post("/host/get-booking-history", data);
};
