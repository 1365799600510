import React, { useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdStarRate } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { useSelector } from "react-redux";
import TruncateWords from "./TruncateWords";
import { toast } from "react-toastify";
import { addToFavorite, removeFromFavorite } from "../services/commonService";

const Dish1 = ({ dish, handleEditClick, handleCardClick }) => {
  const { user } = useSelector((state) => state?.auth);
  const lang = localStorage.getItem("i18nextLng");
  const [isFilled, setIsFilled] = useState(dish?.favoriteStatus === 1);
  const [isLoading, setIsLoading] = useState(false);
  let address = (dish?.hostDetails?.address?.addressLine1 || "").concat(
    " ",
    dish?.hostDetails?.address?.addressLine2 || ""
  );
  let addressEn = (
    dish?.hostDetails?.translations?.en?.address?.addressLine1 || ""
  ).concat(
    " ",
    dish?.hostDetails?.translations?.en?.address?.addressLine2 || ""
  );
  let addressHost = (dish?.address?.addressLine1 || "").concat(
    " ",
    dish?.address?.addressLine2 || ""
  );
  let addressHostEn = (
    dish?.translations?.en?.userId?.address?.addressLine1 || ""
  ).concat(" ", dish?.translations?.en?.userId?.address?.addressLine2 || "");
  console.log("dish::::", dish);
  const handleLikeClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsLoading(true);

      let resp;
      if (isFilled) {
        // remove from favorite
        resp = await removeFromFavorite(dish?._id);
      } else {
        // add to favorite
        resp = await addToFavorite(dish?._id);
      }

      if (resp?.success) {
        toast.success(
          lang === "en" || lang === "en-US"
            ? resp?.message?.en
            : resp?.message?.es
        );
        setIsFilled(!isFilled);
      } else {
        toast.error(resp?.message?.en);
      }

      if (resp?.isAuth === false) {
        toast.error("Please Login");
      }
    } catch (error) {
      console.error("error :", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div
        onClick={handleCardClick}
        className="cursor-pointer  sm:w-[470px] h-[250px] p-8 relative shadow-[0_3px_10px_rgb(0,0,0,0.1)] first-letter rounded-[24px] shadow-[#FF914D33]"
      >
        <div className="flex justify-between">
          <div>
            <p className="font-Glacial capitalize text-2xl text-[#111111] sm:w-[350px] h-[60px] ">
              <TruncateWords
                maxWidth="350px"
                maxLines={2}
                text={
                  lang === "en" || lang === "en-US"
                    ? dish?.translations?.en?.dishTitle
                      ? dish?.translations?.en?.dishTitle
                      : dish?.dishTitle
                    : dish?.dishTitle
                }
              />
            </p>
          </div>
          <div className="flex  gap-2">
            {user?.role === "host" ? (
              <div className="w-[32px] h-[32px] bg-[#FF914D1A] p-[9px] text-primary rounded-full">
                <CiEdit onClick={handleEditClick} />
              </div>
            ) : (
              <></>
            )}
            {user?.role === "host" ? (
              <></>
            ) : (
              <>
                <div
                  className="w-[32px] h-[32px] bg-[#FF914D1A] p-[9px] text-primary rounded-full "
                  onClick={handleLikeClick}
                >
                  <button className="heart-button" disabled={isLoading}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill={isFilled ? "#FF914D" : "transparent"}
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-[23px] w-[24px] pb-[10px] pr-[10px] transition delay-150"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C15.09 3.81 16.76 3 18.5 3 21.58 3 24 5.42 24 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                      />
                    </svg>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            {/* <span className="text-[#ABABAB] text-sm font-Comfortaa">
              {dish?.location?.coordinates}
            </span> */}
            <div className="flex justify-between w-[150px] xl:w-[250px]">
              <div className="flex items-center gap-x-1 ">
                {/* <p className="font-Glacial text-lg text-[#474747] ">
                  {user?.role === "host" ? (
                    <div className="flex gap-1 items-center">
                      <HiOutlineLocationMarker className="text-primary" />
                      {lang === "en" || lang === "en-US" ? (
                        <>
                          {dish?.translations?.en?.userId.address?.city},
                          {dish?.translations?.en?.userId.address?.country}
                        </>
                      ) : (
                        <>
                          {dish?.address?.city},{dish?.address?.country}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="flex gap-1 items-center">
                      {dish?.addressDetails ? (
                        <>
                          <HiOutlineLocationMarker className="text-primary" />
                          {dish?.addressDetails?.city},
                          {dish?.addressDetails?.country}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </p> */}
                <p className=" text-base font-Glacial text-[#ABABAB] py-[10px] ">
                  {user?.role === "host" ? (
                    <div className="flex gap-1 items-center ">
                      <HiOutlineLocationMarker
                        size={20}
                        className=" text-primary "
                      />
                      {/* <p className="line-clamp-2 w-[200px]">
                        {lang === "en" || lang === "en-US"
                          ? addressHostEn
                          : addressHost}
                      </p> */}
                      {/* {dish?.translations?.en?.userId?.address?.addressLine1 ===
                      "" ? (
                        <p className="line-clamp-2 w-[200px]">{addressHost}</p>
                      ) : (
                        <p className="line-clamp-2 w-[200px]">
                          {addressHostEn}
                        </p>
                      )} */}
                      <p className="line-clamp-2">
                        {lang === "en" || lang === "en-US"
                          ? dish?.translations?.en?.userId?.address
                              ?.addressLine1 === ""
                            ? addressHost
                            : addressHostEn
                          : addressHost}
                      </p>
                      {/* <TruncateWords
                        maxWidth="250px"
                        maxLines={2}
                        text={
                          lang === "en" || lang === "en-US"
                            ? addressHostEn
                            : addressHost
                        }
                      /> */}
                    </div>
                  ) : (
                    <div className="flex gap-1 items-center ">
                      {dish?.addressDetails && (
                        <HiOutlineLocationMarker
                          size={20}
                          className=" text-primary "
                        />
                      )}
                      {lang === "en" || lang === "en-US" ? (
                        <p className="line-clamp-2 w-[100px] xl:w-[200px]">
                          {addressEn}
                        </p>
                      ) : (
                        <p className="line-clamp-2 w-[100px] xl:w-[200px]">
                          {address}
                        </p>
                      )}

                      {/* <TruncateWords
                        maxWidth="250px"
                        maxLines={2}
                        text={address}
                      /> */}
                    </div>
                  )}
                </p>
              </div>
              <div className="">
                <span className="text-primary mt-10 text-[18px] font-Comfortaa-SemiBold">
                  ${dish?.dishPrice}
                </span>
              </div>
            </div>

            <div className="w-full    flex justify-between pe-6">
              {user?.role === "host" ? (
                <></>
              ) : (
                <div className="flex items-center gap-x-1">
                  <MdStarRate className="text-primary" />
                  <p className="text-[16px] text-[#757575] font-Glacial">
                    {dish?.hostDetails?.avgHostRatings}
                  </p>
                </div>
              )}
            </div>
            {/* <p className=" text-base font-Glacial text-[#ABABAB] py-[10px] ">
              {user?.role === "host" ? (
                <TruncateWords
                  maxWidth="250px"
                  maxLines={2}
                  text={
                    lang === "en" || lang === "en-US"
                      ? addressHostEn
                      : addressHost
                  }
                />
              ) : (
                <TruncateWords maxWidth="250px" maxLines={2} text={address} />
              )}
            </p> */}
            <p className="font-Glacial text-lg text-[#474747] ">
              {user?.role === "host" ? (
                <div className="flex gap-1 items-center w-[120px] xl:w-[230px] line-clamp-2">
                  {/* {lang === "en" || lang === "en-US" ? (
                    <p className="line-clamp-2"> */}

                  {lang === "en" || lang === "en-US" ? (
                    dish?.translations?.en?.userId.address?.city === "" ? (
                      <div className="line-clamp-2">
                        {dish?.address?.city},{dish?.address?.country}
                      </div>
                    ) : (
                      <div className="line-clamp-2">
                        {dish?.translations?.en?.userId.address?.city},
                        {dish?.translations?.en?.userId.address?.country}{" "}
                      </div>
                    )
                  ) : (
                    <div className="line-clamp-2">
                      {dish?.address?.city},{dish?.address?.country}
                    </div>
                  )}
                  {/* {dish?.translations?.en?.userId.address?.city},
                      {dish?.translations?.en?.userId.address?.country} */}
                  {/* </p> */}
                  {/* ) : (
                    <p>
                      {dish?.address?.city},{dish?.address?.country}
                    </p>
                  )} */}
                </div>
              ) : (
                <div className="flex gap-1 items-center w-[150px] xl:w-[230px]">
                  {dish?.hostDetails?.address?.city ? (
                    <>
                      {lang === "en" || lang === "en-US" ? (
                        <>
                          <p className="line-clamp-2">
                            {/* <HiOutlineLocationMarker className="text-primary" /> */}
                            {dish?.hostDetails?.translations?.en?.address?.city}
                            ,
                            {dish?.hostDetails?.translations?.en?.address?.city}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="line-clamp-2">
                            {/* <HiOutlineLocationMarker className="text-primary" /> */}
                            {dish?.hostDetails?.address?.city},
                            {dish?.hostDetails?.address?.country}
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </p>
          </div>

          <div>
            <div className=" flex items-center ">
              <img
                className="w-[80px] xl:w-[120px] h-[120px] xl:h-[120px] object-cover rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.1)] first-letter  shadow-[#FF914D33]"
                src={dish?.mainImage}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dish1;
