import React from "react";

const Email = ({ size = 20 }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 18"
        fill="none"
      >
        <path
          d="M14.5 16.3H5.5C2.8 16.3 1 14.95 1 11.8V5.5C1 2.35 2.8 1 5.5 1H14.5C17.2 1 19 2.35 19 5.5V11.8C19 14.95 17.2 16.3 14.5 16.3Z"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.0921 4.84204L11.0998 8.69971C10.4515 9.20056 9.54684 9.20056 8.89857 8.69971L3.90625 4.84204"
          stroke="#FF914D"
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};

export default Email;
