import React from "react";

const TruncateWords = ({ text, maxWidth, maxLines }) => {
  const style = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    maxWidth: maxWidth,
    maxHeight: maxLines && `calc(${maxLines} * 1.8em)`,
  };

  return <div style={style}>{text}</div>;
};

export default TruncateWords;
