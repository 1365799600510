import React from "react";
import CalendarBtn from "../components/CalendarBtn";
import CalendarBtn2 from "../components/CalendarBtn2";

const Calendar = () => {
  return (
    <div className="w-[380px] h-[550px] bg-white overflow-hidden rounded-lg mx-auto mt-8 text-gray-900 font-semibold text-center border-[1px] border-[#FF914D]">
      <div className="flex mx-10 px-9 py-3">
        <div>
          <CalendarBtn2 title="Today" />
        </div>
        <div className="pl-[20px]">
          <CalendarBtn2 title="Tomorrow" />
        </div>
      </div>
      <div className="flex items-center justify-around px-4">
        <button className="p-2 rounded-md bg-white shadow-xl">
          <svg
            className="w-4 h-4 stroke-current"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M15 19l-7-7 7-7"></path>
          </svg>
        </button>
        <div className="text-lg">May, 2020</div>
        <button className="p-2 rounded-md bg-white shadow-xl">
          <svg
            className="w-4 h-4 stroke-current"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M9 5l7 7-7 7"></path>
          </svg>
        </button>
      </div>
      <div className="grid grid-cols-7 grid-col-dense grid-rows-6 p-6 gap-1">
        {[...Array(7).keys()]?.map((item, index) => {
          return (
            <>
              <div className="text-gray-500">Mon</div>
            </>
          );
        })}

        {[...Array(35).keys()]?.map((item, index) => {
          return (
            <>
              <a
                href="javascript:void(0)"
                className="hover:bg-indigo-100 rounded-md p-2 text-gray-500"
              >
                1
              </a>
            </>
          );
        })}
      </div>
      <div>
        <div className="relative">
          <div className="absolute text-[40px] text-black p-3 left-11 top-0">
            <a href="javascript:void(0)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.89152 14C6.56677 13.8872 6.44712 13.6582 6.45054 13.3197C6.45738 11.443 6.45396 9.56632 6.45738 7.68961C6.45738 7.56997 6.43003 7.54262 6.31039 7.54262C4.43368 7.54604 2.55697 7.54262 0.680264 7.54946C0.341841 7.54946 0.112808 7.43323 0 7.10848C0 7.0367 0 6.96149 0 6.8897C0.112808 6.56496 0.341841 6.44531 0.680264 6.44873C2.55355 6.45557 4.42684 6.45215 6.30013 6.45557C6.43687 6.45557 6.45738 6.41796 6.45738 6.29148C6.45396 4.39426 6.45396 2.50046 6.45396 0.603247C6.45396 0.312682 6.5907 0.104159 6.82315 0.0289535C7.16499 -0.0838541 7.51367 0.145179 7.54443 0.504113C7.54785 0.555389 7.54785 0.603247 7.54785 0.654523C7.54785 2.54149 7.54785 4.42845 7.54443 6.31541C7.54443 6.42822 7.57178 6.45557 7.68459 6.45557C9.58864 6.45215 11.4927 6.45215 13.4002 6.45215C13.6805 6.45215 13.889 6.58547 13.971 6.8145C14.0907 7.15292 13.8651 7.50844 13.5061 7.54262C13.4514 7.54946 13.3968 7.54604 13.3421 7.54604C11.4654 7.54604 9.58864 7.54604 7.70852 7.54262C7.58204 7.54262 7.54443 7.56313 7.54443 7.69987C7.55127 9.57316 7.54443 11.4464 7.55127 13.3197C7.55127 13.6582 7.43504 13.8872 7.11029 14C7.03851 14 6.9633 14 6.89152 14Z"
                  fill="black"
                />
              </svg>
            </a>
          </div>
          <input
            className="w-[300px] h-[35px] outline-none rounded-full ps-[45px] pe-[45px] font-Glacial  text-[18px] shadow-md shadow-[#E6E6E6E5] placeholder:text-[#ABABAB]"
            type="text"
            placeholder="Add reminder"
          />
        </div>
      </div>
      <div className="flex justify-evenly">
        <div>
          <CalendarBtn2 title="Cancle" />
        </div>
        <div>
          <CalendarBtn title="Done" />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
