import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({
  title,
  children,
  onClose,
  width,
  height,
  maxHeight,
  scrollbarCheck = false,
  responsiveWidth = width,
}) => {
  return (
    <div className="z-50 fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className={`bg-white rounded-[20px] relative ${responsiveWidth}`}>
        <div className="flex justify-between items-center pt-6 px-6">
          <label className="text-[20px] font-bold font-Comfortaa">
            {title}
          </label>
          <button className="absolute right-5 top-3 z-50" onClick={onClose}>
            <div className="w-[40px] h-[40px] bg-[#FF914D1A] p-[13px] text-primary rounded-full">
              <AiOutlineClose />
            </div>
          </button>
        </div>
        <div
          style={{
            width: width ? width : "auto",
            height: height ? height : "auto",
            maxHeight: maxHeight ? maxHeight : "auto",
          }}
          className={`p-4 ${
            scrollbarCheck ? "overflow-y-scroll" : "overflow-y-hidden"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
