import {
  onSignInSuccess,
  onSignOutSuccess,
  setUser,
} from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { signIn } from "../../services/authService";
import { Navigate, useNavigate } from "react-router-dom";

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, signedIn } = useSelector((state) => state.auth);

  const userSignIn = async (data) => {
    try {
      const loginResp = await signIn(data);

      if (loginResp?.success) {
        const userData = loginResp?.data;

        dispatch(setUser(userData));
        dispatch(onSignInSuccess(loginResp?.token));

        return {
          success: loginResp?.success,
          message: loginResp?.message?.es,
          user: userData,
          token: loginResp?.token,
        };
      } else {
        toast.error(loginResp?.message);
      }
    } catch (err) {
      console.log(err);
      return {
        success: false,
        message:
          err?.response?.data?.error?.message ||
          err?.response?.data?.error ||
          err?.response?.data?.message ||
          err.toString(),
      };
    } finally {
    }
  };

  const userSignOut = async () => {
    dispatch(onSignOutSuccess());
    navigate("/");
  };

  return {
    isAuthenticated: token && signedIn,
    userSignIn,
    userSignOut,
  };
};

export default useAuth;
