import React, { useState } from "react";
import logoimg from "../image/signup-logo.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Password from "../svg/Password";
import ForgotInput from "../components/ForgotInput";
import { resetPassword } from "../services/authService";
import ShowCookies from "../components/ShowCookies";

const ResetPassword = ({ type }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  
  const inputType = type === "password" && passwordVisible ? "text" : type;
  const navigate = useNavigate();
  const lang = localStorage.getItem("i18nextLng");

  const userEmail = localStorage.getItem("forgotEmail");

  // password validation end //

  const [userData, setUserData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [errorsEs, setErrorsEs] = useState({});

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    const newErrorsEs = {};

    if (!userData.newPassword) {
      newErrorsEs.newPassword = "Se requiere nueva contraseña";
      newErrors.newPassword = "New password is required";
      isValid = false;
    }

    // Validate password
    if (!userData.confirmPassword) {
      newErrorsEs.confirmPassword = "Se requiere confirmar contraseña";
      newErrors.confirmPassword = "Confirm password is required";
      isValid = false;
    }

    // Validate password match
    if (userData.newPassword !== userData.confirmPassword) {
      newErrorsEs.confirmPassword = "Las contraseñas no coinciden";
      newErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }
    setErrorsEs(newErrorsEs);
    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "confirmPassword") {
      if (userData?.newPassword !== value) {
        setErrors({
          ...errors,
          confirmPassword: "password does not match",
        });
      } else if (userData?.newPassword === value) {
        setErrors({
          ...errors,
          confirmPassword: "",
        });
      }
    }

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const payload = {
        email: userEmail,
        password: userData?.confirmPassword,
      };

      try {
        const resp = await resetPassword(payload);

        if (resp?.success) {
          toast.success(resp?.message);
          navigate("/");
        }
      } catch (err) {
        localStorage.removeItem("forgotEmail");
        console.log("err", err);
        toast.error(err);
      }
    } else {
      // Form is not valid, display error messages
    }
  };

  return (
    <>
      <div className="pt-[40px]">
      <div className="container mx-auto">
        <ShowCookies />
      </div>
        <div className="container w-[524px] border-[1px] border-[#FF914D]  text-center pt-[30px] rounded-md h-[674px] mx-auto bg-[#FFF]  px-[24px] mt-[80px]">
          <div className="pt-[40px]">
            <div className="w-[100px] h-[100px] mx-auto">
              <img src={logoimg} alt="" />
            </div>
            <div>
              {lang === "en" || lang === "en-US" ? (
                <h3 className="font-Comfortaa text-[24px] font-bold text-[#111] text-center pt-[16px]">
                  Reset password
                </h3>
              ) : (
                <h3 className="font-Comfortaa text-[24px] font-bold text-[#111] text-center pt-[16px]">
                  Restablecer la contraseña
                </h3>
              )}

              <div className="flex justify-center">
                {lang === "en" || lang === "en-US" ? (
                  <h5 className="text-[#757575] font-Glacial Indifference text-[18px] text-center w-[286px] pt-[4px]">
                    Your new password must be different from previously used
                    password
                  </h5>
                ) : (
                  <h5 className="text-[#757575] font-Glacial Indifference text-[18px] text-center w-[286px] pt-[4px]">
                    Su nueva contraseña debe ser diferente de la contraseña
                    utilizada anteriormente.
                  </h5>
                )}
              </div>
            </div>
          </div>
          <form>
            {/* new password */}

            <div className="pt-[45px] pb-2">
              <div className="absolute z-50 text-2xl text-primary p-7 only: w-[80px] h-[80px] bg-white shadow-[#E6E6E6E5] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] rounded-full">
                <Password />
              </div>
              <ForgotInput
                id="newPassword"
                name="newPassword"
                className="w-full ring-0 border-0 mx-auto h-[70px]  outline-none rounded-full ps-[104px] pe-[60px] font-Glacial  sm:text-[20px] text-[12px] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5] placeholder:text-[#ABABAB]"
                type="password"
                placeholder={
                  lang === "en" || lang === "en-US"
                    ? "Enter new password *"
                    : "Ingrese nueva clave *"
                }
                onChange={handleInputChange}
                required
              />
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors.newPassword && userData?.newPassword === "" && (
                    <div className="text-red-300">{errors.newPassword}</div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs.newPassword && userData?.newPassword === "" && (
                    <div className="text-red-300">{errorsEs.newPassword}</div>
                  )}
                </>
              )}
            </div>

            {/* confirm password */}

            <div className="pt-4 pb-2">
              <div className="absolute z-50 text-2xl text-primary p-7 only: w-[80px] h-[80px] bg-white shadow-[#E6E6E6E5] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] rounded-full">
                <Password />
              </div>
              <input
                id="confirmPassword"
                name="confirmPassword"
                className="w-full ring-0 border-0 mx-auto h-[70px]  outline-none rounded-full ps-[104px] pe-[60px] font-Glacial  sm:text-[20px] text-[12px] shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] shadow-[#E6E6E6E5] placeholder:text-[#ABABAB]"
                type="password"
                // placeholder="Enter confirm password *"
                placeholder={
                  lang === "en" || lang === "en-US"
                    ? "Enter confirm password *"
                    : "Ingrese confirmar contraseña *"
                }
                onChange={handleInputChange}
              />
              {lang === "en" || lang === "en-US" ? (
                <>
                  {errors?.confirmPassword && (
                    <div className="text-red-300">
                      {errors?.confirmPassword}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {errorsEs?.confirmPassword && (
                    <div className="text-red-300">
                      {errorsEs?.confirmPassword}
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="flex justify-center pt-[6px] ">
              <button
                variant="contain"
                type="submit"
                onClick={handleResetPasswordSubmit}
                className="bg-primary text-white py-[18px] w-[396px] rounded-full mx-auto  font-Comfortaa-Medium text-[16px] "
              >
                {lang === "en" || lang === "en-US" ? <>Save</> : <>Guardar</>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
